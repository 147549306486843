import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const useReactRouterLink = ({ to, onClick }) => {
  const history = useHistory();

  const onLinkClick = useCallback(
    e => {
      e.preventDefault();

      if (onClick) onClick();

      if (window.swUpdate) {
        window.location = to;
      } else {
        history.push(to);
      }
    },
    [to, onClick],
  );

  return { onLinkClick };
};

export default useReactRouterLink;
