import { INQUIRY_MESSAGE_FRAGMENT } from 'core/queries/inquiry';

export default (obj, { input: { msg } }, { cache, getCacheKey }) => {
  const readAt = Date.now();

  msg.replyIds &&
    msg.replyIds.forEach(replyId => {
      const id = getCacheKey({ __typename: 'DialogueItemDto', id: replyId });
      const cachedDialogueItem = cache.readFragment({
        id,
        fragment: INQUIRY_MESSAGE_FRAGMENT,
      });

      if (!cachedDialogueItem) {
        return;
      }

      const nextCachedDialogueItem = {
        ...cachedDialogueItem,
        readAt,
      };

      cache.writeFragment({
        id,
        fragment: INQUIRY_MESSAGE_FRAGMENT,
        data: nextCachedDialogueItem,
      });
    });

  return null;
};
