import lodash from 'lodash';

const maskEmail = email =>
  email && email.replace(/^(..)(.*)(.@.*)$/, (_, a, b, c) => `${a}${b.replace(/./g, '*')}${c}`);
const maskPhone = phone => phone && phone.replace(/^(.)(.*)(..)/, (_, a, b, c) => `${a}${b.replace(/./g, '*')}${c}`);
const normalizeEol = string => string && string.replace(/\r\n/g, '\n').replace(/\r/g, '\n');
const replaceSpace = (string, to) => string && string.replace(/ /g, to);

const truncate = (string, maxLength) => {
  if (!string || !lodash.isString(string)) {
    return [string];
  }

  if (string.trim().length <= maxLength) {
    return [string.trim()];
  }

  return [string.trim().substring(0, maxLength), string.trim().substring(maxLength)];
};

const equalsIgnoreCase = (str1, str2) => {
  if (!str1 || !str2) {
    return str1 === str2;
  }
  return str1.toLowerCase() === str2.toLowerCase();
};

export default {
  maskEmail,
  maskPhone,
  normalizeEol,
  replaceSpace,
  truncate,
  equalsIgnoreCase,
};
