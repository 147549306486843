import { INQUIRY_MESSAGE_FRAGMENT } from 'core/queries/inquiry';
import { TYPENAME } from 'core/constants/typename';

import { vehicleLookupService } from 'core/vehicle-lookup';

import messageConverter from './graphql-message-converter';
import { updateMessageInDialogue } from './utils';

export default (obj, { input: { inquiryId, messageId, vehicleDto, user } }, { cache, getCacheKey }) => {
  if (!inquiryId || !messageId || !vehicleDto) {
    // some log can be captured here
    return null;
  }

  const id = getCacheKey({ __typename: TYPENAME.INQUIRY_MESSAGE, id: messageId });
  const cachedMessage = cache.readFragment({
    id,
    fragment: INQUIRY_MESSAGE_FRAGMENT,
  });

  // save to storage and later merge with message once it come
  if (!cachedMessage) {
    vehicleLookupService.saveVehicle({
      messageId,
      vehicle: vehicleDto,
    });

    return null;
  }

  const nextMessage = messageConverter(vehicleLookupService.extendMessageWithVehicle(cachedMessage, vehicleDto));

  updateMessageInDialogue({
    message: nextMessage,
    inquiryId,
    user,
    updateBy: messageId,
    cache,
  });

  return null;
};
