export const PAGES = {
  INQUIRIES: '/inquiries',
  USERS: '/dealers/users',
  EXISTING_USERS: '/dealers/users/existing',
  NEW_USER: '/dealers/users/new',
  REPORTS: '/dealers/reports',
  ERROR: '/error',
  LOGIN: '/login',
  LOGOUT: '/logout',
  SESSION_EXPIRED: '/session-expired',
  WIDGET_SETTINGS: '/dealers/settings/widget',
  NEW_WIDGET: '/dealers/settings/widget/new',
};

export const NOT_ALLOWED_TO_REDIRECT_AFTER_LOGIN = [PAGES.LOGIN, PAGES.LOGOUT, PAGES.SESSION_EXPIRED];
