import React from 'react';
import { ApolloProvider } from '@apollo/client';

import { ErrorBoundary } from 'shared-components/error-boundary';
import { SessionLogProvider } from 'shared-components/session-log-provider';

import { Router } from 'core/router';
import { apolloClient } from 'core/apollo-client';

import { NOT_SUPPORTED_REASON } from 'core/constants/browser';

import { browserService } from 'core/browser';
import { WebSocketManager } from 'core/websocket';
import { WebSocketsContext } from 'core/context/web-sockets';

import { CookiesDisabledPage } from 'pages/cookies-disabled-page';
import { newRelicService } from 'core/new-relic';

class App extends React.Component {
  constructor() {
    super();
    this.browserDetails = browserService.isSupported();

    if (this.browserDetails.supported) {
      this.ws = new WebSocketManager();
      this.state = {
        client: apolloClient(),
      };
    }
  }

  renderApp() {
    return (
      <ErrorBoundary>
        <ApolloProvider client={this.state.client}>
          <WebSocketsContext.Provider value={this.ws}>
            <SessionLogProvider>
              <Router />
            </SessionLogProvider>
          </WebSocketsContext.Provider>
        </ApolloProvider>
      </ErrorBoundary>
    );
  }

  renderNotSupported() {
    switch (this.browserDetails.reason) {
      case NOT_SUPPORTED_REASON.COOKIES_DISABLED: {
        return (
          <ErrorBoundary>
            <CookiesDisabledPage />
          </ErrorBoundary>
        );
      }

      default: {
        newRelicService.logError('Unknown or unsupported reason');
        throw new Error('Unknown unsupported reason');
      }
    }
  }

  render() {
    if (!this.browserDetails.supported) {
      return this.renderNotSupported();
    }

    return this.renderApp();
  }
}

export default App;
