import React from 'react';

import { SessionLogContext } from 'core/context/session-log';

const SessionLogConsumer = Component => props =>
  (
    <SessionLogContext.Consumer>
      {context => <Component sessionLogStore={context} {...props} />}
    </SessionLogContext.Consumer>
  );

export default SessionLogConsumer;
