export const NOT_SUPPORTED_REASON = {
  COOKIES_DISABLED: 'COOKIES_DISABLED',
};

export const BROWSER = {
  CHROME: {
    browserName: 'Chrome',
    cookieSupportLink: 'https://support.google.com/accounts/answer/61416?hl=en',
  },
  MOZILA: {
    browserName: 'Mozila Firefox',
    cookieSupportLink: 'https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences',
  },
  OPERA: {
    browserName: 'Opera',
    cookieSupportLink: null,
  },
  IE: {
    browserName: 'Internet Explorer',
    cookieSupportLink: null,
  },
  SAFARI: {
    browserName: 'Safari',
    cookieSupportLink: 'https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac',
  },
  UNKNOWN: {
    browserName: 'Unknown',
    cookieSupportLink: null,
  },
};
