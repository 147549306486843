import { GET_BACKGROUND_INQUIRIES } from 'core/queries/inquiries-background';

import { inquiriesBackgroundService } from 'core/inquiries-background';

export default (obj, { input: { id } }, { cache }) => {
  const cachedBackgroundInquiries = cache.readQuery({
    query: GET_BACKGROUND_INQUIRIES,
  });

  const nextBackgroundInquiries = inquiriesBackgroundService.remove(cachedBackgroundInquiries.backgroundInquiries, {
    id,
  });

  cache.writeQuery({
    query: GET_BACKGROUND_INQUIRIES,
    data: {
      backgroundInquiries: nextBackgroundInquiries || [],
    },
  });

  return null;
};
