import React from 'react';

import { TIMER_TO_LOGOUT_SECONDS, DOCUMENT_TITLE } from 'core/constants/auto-logout';
import { PAGES } from 'core/constants/pages';

import { AutoLogoutService, EVENTS, getMaxInactiveTimeInMins } from 'core/auto-logout';

import { RouterRedirect } from 'shared-components/router-redirect';

import AutoLogout from './auto-logout.presentation';

import './auto-logout.styles.scss';

const LOGOUT_TO_PARAMS = {
  pathname: PAGES.LOGOUT,
  state: {
    afterLogoutRedirectPath: PAGES.SESSION_EXPIRED,
  },
};

class AutoLogoutContainer extends React.Component {
  constructor(props) {
    super(props);

    this.initialTitle = document.title;

    this.autoLogoutService = new AutoLogoutService({
      maxInactiveTimeInMins: this.maxInactiveTimeInMins,
    });

    // if session became inactive while application was closed
    // then auto-logout immediately
    if (this.autoLogoutService.isInactive()) {
      this.autoLogoutService.destroy();

      this.state = {
        isConfirmingModal: true,
        redirectToLogout: true,
      };
    } else {
      // else keep track of activities
      // and auto-logout if no activities for more than specified time
      this.autoLogoutService.on(EVENTS.INACTIVE, this.onLogoutConfirm);

      this.state = {
        isConfirmingModal: false,
        redirectToLogout: false,
      };
    }
  }

  componentWillUnmount() {
    this.destroy();
  }

  onLogoutConfirm = () => {
    this.startTimer();

    this.setState({
      isConfirmingModal: true,
    });
  };

  destroy = () => {
    this.autoLogoutService.destroy();
    this.stopTimer();

    this.autoLogoutService = null;

    document.title = this.initialTitle;
  };

  tick = () => {
    // if user became active between ticks
    // (it may happen if several tabs with app is opened and use confirmed status in an another tab)
    // then hide modal, reset timer and allow continue working with the app
    if (!this.autoLogoutService.isInactive()) {
      this.handleModalSubmit();

      return;
    }

    let { timer } = this.state;

    timer -= 1;

    if (timer === 0) {
      this.stopTimer();
      this.logout();
    } else {
      document.title = document.title === this.initialTitle ? DOCUMENT_TITLE : this.initialTitle;

      this.setState({
        timer,
      });
    }
  };

  startTimer = () => {
    // if we already have timer then skip
    if (this.timerId) {
      return;
    }

    this.setState({
      timer: TIMER_TO_LOGOUT_SECONDS,
    });

    this.timerId = setInterval(this.tick, 1000);
  };

  stopTimer = () => {
    if (this.timerId) {
      clearInterval(this.timerId);

      this.timerId = null;
    }

    this.setState({
      timer: TIMER_TO_LOGOUT_SECONDS,
    });

    document.title = this.initialTitle;
  };

  logout = () => {
    this.setState({
      redirectToLogout: true,
      isConfirmingModal: true, // if logout called directly no need to show a modal
    });
  };

  handleModalSubmit = () => {
    // keep user online
    this.autoLogoutService.reset();
    this.stopTimer();

    this.setState({
      isConfirmingModal: false,
    });
  };

  handleModalCancel = () => {};

  handleModalToggle = () => {};

  maxInactiveTimeInMins = getMaxInactiveTimeInMins();

  render() {
    const { isConfirmingModal, redirectToLogout, timer } = this.state;

    if (!isConfirmingModal) {
      return null;
    }

    if (redirectToLogout) {
      return <RouterRedirect to={LOGOUT_TO_PARAMS} />;
    }

    return (
      <AutoLogout
        isConfirmingModal={isConfirmingModal}
        maxInactiveTimeInMins={this.maxInactiveTimeInMins}
        timer={timer}
        handleModalSubmit={this.handleModalSubmit}
        handleModalCancel={this.handleModalCancel}
        handleModalToggle={this.handleModalToggle}
      />
    );
  }
}

export default AutoLogoutContainer;
