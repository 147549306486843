import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { RouterNavLink } from 'shared-components/router-nav-link';
import { Text } from 'shared-components/text';

const HeaderLinkItem = ({ tag: Tag, text, iconProps, className, children, ...props }) => (
  <Tag className={classNames(className, 'text-white no-decoration btn overflow-hide')} {...props}>
    {children}
    <Text {...iconProps}>{text}</Text>
  </Tag>
);

HeaderLinkItem.defaultProps = {
  iconProps: {},
  className: '',
  tag: RouterNavLink,
};

HeaderLinkItem.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  text: PropTypes.string.isRequired,
  iconProps: PropTypes.shape({}),
  className: PropTypes.string,
};

export default HeaderLinkItem;
