import PropTypes from 'prop-types';

import SessionLogObject from './session-log-object';

export default PropTypes.shape({
  reset: PropTypes.func.isRequired,
  updateSessionUniqueInquiriesIds: PropTypes.func.isRequired,
  shouldUpdateUniqueInquiriesIds: PropTypes.func.isRequired,
  incrementHandoffsCount: PropTypes.func.isRequired,
  incrementLeadsCount: PropTypes.func.isRequired,
  sessionLogObject: SessionLogObject,
});
