import { IS_INVENTORY_IMAGES_SHOW_CLIENT_QUERY } from 'core/queries/inventory-search';

import decorator from 'core/resolvers/graphql-type-decorator';

export default (obj, { input: { inquiryId, isShow } }, { cache, getCacheKey }) => {
  cache.writeQuery({
    query: IS_INVENTORY_IMAGES_SHOW_CLIENT_QUERY,
    variables: {
      inquiryId,
    },
    data: {
      isInventoryImagesShow: decorator.isInventoryImagesShow({
        isShow,
      }),
    },
  });

  return null;
};
