import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

import history from 'core/router/history';
import { pwaService } from 'core/pwa';

let isActive = false;
let v4IsActive = false;

/**
 * Initializes Google Analytics if not empty "gaTrackingID" or ga4TrackingID is passed.
 */
const init = ({ gaTrackingID, ga4TrackingID }) => {
  if (gaTrackingID) {
    ReactGA.initialize(gaTrackingID);
    // Automatically track each route
    history.listen(location => ReactGA.pageview(location.pathname + location.search));

    if (pwaService.isPwaOpened()) {
      ReactGA.set({
        dataSource: 'pwa',
      });
    }

    isActive = true;
  }

  if (ga4TrackingID) {
    ReactGA4.initialize(ga4TrackingID);
    // Automatically track each route
    history.listen(location => ReactGA4.send({ hitType: 'pageview', page: location.pathname + location.search }));

    if (pwaService.isPwaOpened()) {
      ReactGA4.set({
        dataSource: 'pwa',
      });
    }

    v4IsActive = true;
  }
};

const event = options => {
  if (isActive) {
    ReactGA.event(options);
  }
  if (v4IsActive) {
    ReactGA4.event(options);
  }
};

export default {
  init,
  event,
};
