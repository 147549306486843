export const POPUP_DELAY_OPTIONS = [
  { id: 1, label: '1 second' },
  { id: 5, label: '5 seconds' },
  { id: 15, label: '15 seconds' },
  { id: 30, label: '30 seconds' },
  { id: 60, label: '60 seconds' },
  { id: 120, label: '120 seconds' },
];

export const SUPPRESS_POPUP_CODE = -1;

export const CUSTOM_POPUP_DELAY_OPTIONS = [{ id: SUPPRESS_POPUP_CODE, label: 'Never Display' }, ...POPUP_DELAY_OPTIONS];

export const CALL_OUT_AGENT_NAMES_OPTIONS = [
  {
    id: 'DEALER_AVATARS',
    label: 'Dealer Avatars',
  },
  {
    id: 'DEALER_AGENT_NAME_AND_AVATAR',
    label: 'Dealer Names & Avatars',
  },
  {
    id: 'DEALER_AGENT',
    label: 'Dealer Representatives',
  },
  {
    id: 'DISABLED',
    label: 'Disabled',
  },
  {
    id: 'STANDARD',
    label: 'Standard',
  },
];

export const CALL_OUT_AGENT_LIVE_STATUS_OPTIONS = [
  {
    id: 'NONE',
    label: 'None',
  },
  {
    id: 'ONLINE',
    label: 'Online status',
  },
  {
    id: 'ONLINE_AND_TIME',
    label: 'Online status & response time',
  },
  {
    id: 'TIME',
    label: 'Response time',
  },
];

export const FLOATING_BUTTON_TYPE = {
  INPUT: 'input',
  ICONS: 'icons',
  ANCHORED: 'anchored',
  LABEL: 'label',
};

export const FLOATING_BUTTON_TYPES_OPTIONS = [
  {
    id: 'input',
    label: 'Standard',
  },
  {
    id: 'icons',
    label: 'Icons Only',
  },
  {
    id: 'anchored',
    label: 'Toolbar',
  },
  {
    id: 'label',
    label: 'Label',
  },
];

export const WIDGET_POSITIONS_OPTIONS = [
  { id: 'bottom right', label: 'Bottom Right' },
  { id: 'bottom center', label: 'Bottom Center' },
  { id: 'bottom left', label: 'Bottom Left' },
  { id: 'side left center', label: 'Side Left Center' },
  { id: 'side lower left', label: 'Side Lower Left' },
  { id: 'side right center', label: 'Side Right Center' },
  { id: 'side lower right', label: 'Side Lower Right' },
];

export const PRIMARY_BUTTON_CHANNELS_OPTIONS = [
  { label: 'SMS (Text)', id: 'sms_first' },
  { label: 'Chat', id: 'chat_first' },
  { label: 'Facebook Messenger', id: 'fb_first' },
];

export const PARTNER_PROGRAMS = {
  BMW: 'BMW',
  AUDI: 'AUDI',
  VOLVO: 'VOLVO',
  INFINITI: 'INFINITI',
  DEFAULT: 'default',
  NO_PROGRAM: 'NO_PROGRAM',
  MINI: 'MINI',
};

export const DEFAULT_WIDGET_THEME_OPTIONS = [
  {
    label: 'Default',
    id: 'default',
  },
  {
    label: 'Dark',
    id: 'dark',
  },
  {
    label: 'Edmunds',
    id: 'edmunds',
  },
  {
    label: 'Audi-black',
    id: 'audi',
  },
  {
    label: 'Audi-red',
    id: 'audi-red',
  },
  {
    label: 'BMW-blue',
    id: 'bmw',
  },
  {
    label: 'Volvo-blue',
    id: 'volvo',
  },
  {
    label: 'Infiniti-black',
    id: 'infiniti',
  },
  {
    label: 'Lexus-black',
    id: 'lexus',
  },
  {
    label: 'Porsche-red',
    id: 'porsche',
  },
  {
    label: 'MINI-black',
    id: 'mini-black',
  },
  {
    label: 'MINI-red',
    id: 'MINI-red',
  },
  {
    label: 'Genesis-black',
    id: 'genesis',
  },
  {
    label: 'Jaguar',
    id: 'jaguar',
  },
  {
    label: 'Land Rover',
    id: 'land_rover',
  },
  {
    label: 'FCA-Gray',
    id: 'FCA-Gray',
  },
  {
    label: 'Maserati',
    id: 'maserati',
  },
  {
    label: 'Nissan',
    id: 'nissan',
  },
  {
    label: 'Mazda',
    id: 'mazda-black',
  },
  {
    label: 'Mazda-white',
    id: 'mazda-white',
  },
  {
    label: 'Eve',
    id: 'EVE',
  },
];

export const MINI_WIDGET_THEME_OPTIONS = [
  {
    label: 'MINI-black',
    id: 'mini-black',
  },
  {
    label: 'MINI-red',
    id: 'mini-red',
  },
];

export const AUDI_WIDGET_THEME_OPTIONS = [
  {
    label: 'Audi-black',
    id: 'audi',
  },
  {
    label: 'Audi-red',
    id: 'audi-red',
  },
];

export const CANNED_MESSAGES_TYPE = {
  CHECKBOX: {
    label: 'Checkbox',
    id: 'CHECKBOX',
  },
  BUBBLE: {
    label: 'Bubble',
    id: 'BUBBLE',
  },
  BUBBLE_FIRST: {
    label: 'Bubble First',
    id: 'BUBBLE_FIRST',
  },
  BUBBLES_WITHOUT_MESSAGE_BOX: {
    label: 'Bubbles, no message box',
    id: 'BUBBLES_WITHOUT_MESSAGE_BOX',
  },
  DROPDOWN: {
    label: 'Dropdown',
    id: 'DROPDOWN',
  },
  MESSAGE_ONLY: {
    label: 'Message Only',
    id: 'MESSAGE_ONLY',
  },
};

export const CANNED_MESSAGES_TYPES_OPTIONS = [
  { label: 'Bubble', id: 'BUBBLE' },
  { label: 'Bubble First', id: 'BUBBLE_FIRST' },
  { label: 'Bubbles, no message box', id: 'BUBBLES_WITHOUT_MESSAGE_BOX' },
  { label: 'Checkbox', id: 'CHECKBOX' },
  { label: 'Dropdown', id: 'DROPDOWN' },
  { label: 'Message Only', id: 'MESSAGE_ONLY' },
];

export const CALLOUT_VEHICLE_OPTIONS = [
  {
    label: 'VIN',
    id: 'vin',
  },
  {
    label: 'Make',
    id: 'vehicle_make',
  },
  {
    label: 'Model',
    id: 'vehicle_model',
  },
  {
    label: 'Year',
    id: 'vehicle_year',
  },
];

export const CC_WIDGET_DEFAULT_VALUES = {
  text: 'Text Us',
  offsetX: '0',
  offsetY: '0',
  font: "'Open Sans', 'Arial', 'Helvetica', 'sans-serif'",
  webFormFont: '"Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif',
  fontSize: '16',
  closedHoursChatFormMessage:
    'Sorry, the dealership is closed right now. Let us know where we can contact you and we will get back to you as soon as we are open.',
  callOutDisplayText: 'We’re available and happy to help with any questions.',
  introText: 'Just ask',
  enableCallOut: true,
  callOutDelay: 30,
  callOutAgentNamesOption: 'STANDARD',
  floatingButtonType: FLOATING_BUTTON_TYPE.INPUT,
  primaryButtonChannel: 'chat_first',
  primaryButtonChannelMobile: 'sms_first',
  cannedMessagesType: CANNED_MESSAGES_TYPE.BUBBLE.id,
};

export const ERROR_MESSAGE = {
  phoneNumbers: 'At least one phone number must be selected',
  widgetDesktopChannels: 'At least one channel for the Desktop widget must be selected',
  widgetMobileChannels: 'At least one channel for the Mobile widget must be selected',
  widgetChannels: 'At least one channel for the Mobile widget and one channel for the Desktop widget must be selected',
};

export const TOOLTIPS = {
  facebook: {
    id: 'facebookTooltip',
    text: 'To activate the Facebook Messenger channel, you need to first connect your Facebook account to CarCode.  Please reach out to our account manager team via <a href="mailto: dealersupport@edmunds.com">dealersupport@edmunds.com</a> to initiate the procedure.',
  },
  clickToCall: {
    id: 'clickToCallTooltip',
    text: 'The call button will not show if a voice call redirect number is not set for the dealer.',
  },
  cashOffer: {
    id: 'cashOfferTooltip',
    text: 'In order to link your Edmunds trade-in and/or instant cash offer tool with CarCode, you will need to be enrolled in these products through Edmunds and have them active on the same page as CarCode. Please reach out to our account manager team via <a href="mailto: dealersupport@edmunds.com">dealersupport@edmunds.com</a> for support with setup.',
  },
};

export const WIDGET_CHANNELS_DEVICE = {
  desktop: 'desktopWidgetChannels',
  mobile: 'mobileWidgetChannels',
};
