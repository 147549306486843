import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

const Icon = ({ name, size, className, ...props }) => (
  <i {...props} className={classNames(className, `icon icon-${name}`)} style={{ fontSize: `${size}` }} />
);

Icon.defaultProps = {
  className: '',
  size: '1rem',
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
};

export default Icon;
