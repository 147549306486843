import React from 'react';

import { Query } from '@apollo/client/react/components';
import { withApolloLoading } from 'core/hocs/apollo-loading';

const Loading = withApolloLoading()(({ children, ...rest }) => <React.Fragment>{children(rest)}</React.Fragment>);

const LoadingQuery = ({ children, ...props }) => (
  <Query {...props}>{queryProps => <Loading {...queryProps}>{children}</Loading>}</Query>
);

export default LoadingQuery;
