import { MEDIA_QUERY_KEY } from 'core/constants/media-query';
import { DEVICE_TYPE } from 'core/constants/device';

import { mediaQueryService } from 'core/media-query';

const isDesktop = () => mediaQueryService.matchMedia(MEDIA_QUERY_KEY.DESKTOP).matches;

const isDesktopLarge = () => mediaQueryService.matchMedia(MEDIA_QUERY_KEY.DESKTOP_LARGE).matches;

const isMobile = () => mediaQueryService.matchMedia(MEDIA_QUERY_KEY.MOBILE).matches;

const isTablet = () => mediaQueryService.matchMedia(MEDIA_QUERY_KEY.TABLET).matches;

const isMobileOrTablet = () => mediaQueryService.matchMedia(MEDIA_QUERY_KEY.MOBILE_OR_TABLET).matches;

const isTabletOrDesktop = () => mediaQueryService.matchMedia(MEDIA_QUERY_KEY.TABLET_OR_DESKTOP).matches;

const getDeviceType = () => {
  if (isMobile()) {
    return DEVICE_TYPE.MOBILE;
  }

  if (isTablet()) {
    return DEVICE_TYPE.TABLET;
  }

  return DEVICE_TYPE.DESKTOP;
};

export default {
  isDesktop,
  isDesktopLarge,
  isMobile,
  isMobileOrTablet,
  isTabletOrDesktop,
  getDeviceType,
};
