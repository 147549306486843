import { MEDIA_QUERY } from 'core/constants/media-query';

const matchMedia = query => {
  if (!window.matchMedia) {
    return false;
  }

  if (!MEDIA_QUERY[query]) {
    return false;
  }

  return window.matchMedia(MEDIA_QUERY[query]);
};

export default {
  matchMedia,
};
