import { CUSTOMER_TEXTING_QUERY } from 'core/queries/inquiry';

import decorator from 'core/resolvers/graphql-type-decorator';

export default (obj, { input: { inquiryId, body } }, { cache, getCacheKey }) => {
  cache.writeQuery({
    query: CUSTOMER_TEXTING_QUERY,
    variables: {
      id: inquiryId,
    },
    data: {
      customerTexting: decorator.customerTexting({
        body,
      }),
    },
  });

  return null;
};
