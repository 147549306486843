import _ from 'lodash';
import moment from 'moment';

import { orderDirectionService } from 'core/order-direction';

const getStringComparator = field => (obj1, obj2) => {
  if (obj1[field] && !obj2[field]) {
    return 1;
  }

  if (!obj1[field] && obj2[field]) {
    return -1;
  }

  if (!obj1[field] && !obj2[field]) {
    return 0;
  }

  if (typeof obj1[field] === 'number' && typeof obj2[field] === 'number') {
    return obj1[field] - obj2[field];
  }

  return `${obj1[field]}`.trim().localeCompare(`${obj2[field]}`.trim(), undefined, { sensitivity: 'base' });
};

const sortByString = (collection, field, direction) => {
  const comparator = getStringComparator(field);

  if (orderDirectionService.isAsc(direction)) {
    return [...collection].sort(comparator);
  }

  return [...collection].sort(comparator).reverse();
};

const getSortType = (collectionItem, dateFormat) => {
  if (dateFormat && moment(collectionItem, dateFormat, true).isValid()) return 'date';

  if (!isNaN(collectionItem)) return 'number';

  return 'string';
};

const sortByValue = (collection, field, direction, dateFormat = '', emptyLast = false) => {
  if (!collection.length) {
    return collection;
  }

  const sortType = getSortType(collection[0][field], dateFormat);
  const result = [...collection];

  switch (sortType) {
    case 'string': {
      result.sort(getStringComparator(field));
      break;
    }
    case 'number': {
      result.sort((obj1, obj2) => {
        if (!emptyLast) {
          return _.toNumber(obj1[field]) - _.toNumber(obj2[field]);
        }

        if (!obj1[field] && !obj2[field]) {
          return 0;
        }

        if (!obj1[field]) {
          return orderDirectionService.isAsc(direction) ? 1 : -1;
        }

        if (!obj2[field]) {
          return orderDirectionService.isAsc(direction) ? -1 : 1;
        }

        return _.toNumber(obj1[field]) - _.toNumber(obj2[field]);
      });
      break;
    }
    case 'date': {
      result.sort(
        (obj1, obj2) => moment(obj1[field], dateFormat).valueOf() - moment(obj2[field], dateFormat).valueOf(),
      );
      break;
    }
    default:
      return collection;
  }

  return orderDirectionService.isAsc(direction) ? result : result.reverse();
};

const filter = ({ collection, searchText, searchProperties }) => {
  if (!searchText || !collection) {
    return collection;
  }

  const regExPattern = /[^\w!?&]/g;
  const escapedSearchText = searchText.replace(regExPattern, '').trim().toLowerCase();

  return collection.filter(item => {
    let itemObj = item;

    if (searchProperties && searchProperties.length) {
      itemObj = Object.keys(item)
        .filter(key => searchProperties.includes(key))
        .reduce(
          (acc, key) => ({
            ...acc,
            [key]: item[key],
          }),
          {},
        );
    }

    return Object.values(itemObj).some(value =>
      String(value).replace(regExPattern, '').toLowerCase().includes(escapedSearchText),
    );
  });
};

export default {
  sortByString,
  sortByValue,
  getStringComparator,
  filter,
};
