import PropTypes from 'prop-types';

import PhoneNumber from './phone-number';
import Style from './style';
import ConfiguredWidgetMobileSettings from './configured-widget-mobile-settings';
import ConfiguredWidgetCustomPopup from './configured-widget-custom-popup';

export default PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  enableCallOut: PropTypes.bool,
  callOutDelay: PropTypes.number,
  callOutAgentNamesOption: PropTypes.string,
  callOutDisplayText: PropTypes.string,
  introText: PropTypes.string,
  isDefault: PropTypes.bool,
  isEnableAnalytics: PropTypes.bool,
  showChatCustomerStarters: PropTypes.bool,
  floatingButtonType: PropTypes.string,
  floatingButtonPosition: PropTypes.string,
  style: Style,
  phoneNumbers: PropTypes.arrayOf(PhoneNumber),
  enableMobileWidgetSettings: PropTypes.bool,
  configuredWidgetMobileSettingsDto: ConfiguredWidgetMobileSettings,
  configuredWidgetCustomPopups: PropTypes.arrayOf(ConfiguredWidgetCustomPopup),
  version: PropTypes.number,
  themeCode: PropTypes.string,
  includeChat: PropTypes.bool,
  chatPiiRequired: PropTypes.bool,
  isAlwaysOpenForm: PropTypes.bool,
  closedHoursChatFormMessage: PropTypes.string,
});
