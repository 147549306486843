import { usePrevious } from 'core/hooks/usePrevious';
import { useEffect, useRef } from 'react';

/**
 * useEffectAllDepsChange hook.
 *
 * Useful when need to make sure that all dependencies are loaded.
 * This hook accepts  callback function and array of deps.
 * It is watching them via the built-in usePrevious hook and call the callback function
 * when all dependencies are changed.
 *
 * E.g.
 *
 * const MyComponent = ({ count }) => {
 *
 * useEffectAllDepsChange(() => {
 *  doSomething(firstDependency, secondDependency)
 *}, [firstDependency, secondDependency]);
 *
 *
 *  return (
 *    <div>My Component</div>
 *  )
 * }
 */

const useEffectAllDepsChange = (fn, deps) => {
  const prevDeps = usePrevious(deps);
  const changeTarget = useRef();

  useEffect(() => {
    // nothing to compare to yet
    if (changeTarget.current === undefined) {
      changeTarget.current = prevDeps;
    }
    // we're mounting, so call the callback
    if (changeTarget.current === undefined) {
      return fn();
    }
    // make sure every dependency has changed
    if (changeTarget.current.every((dep, i) => dep !== deps[i])) {
      changeTarget.current = deps;
      return fn();
    }
    return false;
  }, [fn, prevDeps, deps]);
};

export default useEffectAllDepsChange;
