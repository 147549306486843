import moment from 'moment';

import { localStorage } from 'core/storages/local';
import { timeDuration } from 'core/formatters/time-duration';

import { SESSION_LOG_KEYS } from 'core/constants/session-log';

const getDefault = () => ({
  [SESSION_LOG_KEYS.START_DATE]: new Date(),
  [SESSION_LOG_KEYS.PICKUPS]: 0,
  [SESSION_LOG_KEYS.HANDOFFS]: 0,
  [SESSION_LOG_KEYS.LEADS]: 0,
});

const get = () => localStorage.get(SESSION_LOG_KEYS.STORAGE) || getDefault();

const set = value => localStorage.set(SESSION_LOG_KEYS.STORAGE, value);

const getSessionTime = startDate => {
  const sessionStartDateTimeStamp = moment(startDate);
  const currentTimeStamp = moment().utc();
  return timeDuration.asSeconds(currentTimeStamp, sessionStartDateTimeStamp);
};

const setUniqueIdsForPickups = value => localStorage.set(SESSION_LOG_KEYS.UNIQUE_INQUIRIES_IDS, value);

const getUniqueIdsForPickups = () => localStorage.get(SESSION_LOG_KEYS.UNIQUE_INQUIRIES_IDS) || [];

export default {
  get,
  set,
  getDefault,
  getSessionTime,
  setUniqueIdsForPickups,
  getUniqueIdsForPickups,
};
