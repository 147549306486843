import { edwTracker } from 'core/tracking/edw';
import { LABELS } from 'core/constants/tracking';
import { websocketUtils } from 'core/utils/websocket';

import { API } from 'core/api';

const extendWithLoggedUser = (eventData, loggedUser) => {
  if (!loggedUser) {
    return eventData;
  }

  return {
    userId: loggedUser.id,
    dealerId: loggedUser.currentDealer.id,
    ...eventData,
  };
};

const extendWithInquiry = (eventData, message) => {
  if (!message || !message.data) {
    return eventData;
  }

  const inquiry = message.data.inquiry;
  const inquiryId = message.data.inquiryId || (inquiry && inquiry.id);

  if (!inquiryId) {
    return eventData;
  }

  return {
    inquiryId,
    ...eventData,
  };
};

const extendWithContext = (eventData, wsUrl) => ({
  env: API.ENV,
  channel: websocketUtils.getChannelFromUrl(wsUrl),
  ...eventData,
});

// track confirm event if message contains uuid
const trackMessage = ({ message, wsUrl, loggedUser }) => {
  // no tracking
  if (!message || !message.uuid) {
    return;
  }

  const eventData = {
    uuid: message.uuid,
  };

  const eventDataWithUser = extendWithLoggedUser(eventData, loggedUser);

  const eventDataWithInquiry = extendWithInquiry(eventDataWithUser, message);

  const eventDataWithContext = extendWithContext(eventDataWithInquiry, wsUrl);

  edwTracker.track(LABELS.CLIENT_WEBSOCKET_CONFIRM, eventDataWithContext);
};

export default {
  trackMessage,
};
