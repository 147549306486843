import React from 'react';
import PropTypes from 'prop-types';

import qs from 'qs';
import _ from 'lodash';

import { alertService } from 'core/alert';
import { newRelicService } from 'core/new-relic';

import { Route } from 'react-router-dom';

import { RouterRedirect } from 'shared-components/router-redirect';

import { ALERT_COLOR } from 'core/constants/alert';

class QueryRoute extends React.Component {
  static propTypes = {
    component: PropTypes.func.isRequired,
    params: PropTypes.arrayOf(PropTypes.string).isRequired,
    redirectWhenWrong: PropTypes.string.isRequired,
  };

  renderRoute = props => {
    const { component: Component, redirectWhenWrong, params } = this.props;
    const queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });

    const haveQueryParams = _.every(params, param => queryParams[param]);

    if (haveQueryParams) {
      newRelicService.setRouteName(props.location.pathname, props.location.search);
      return <Component {...props} />;
    }

    const message = 'Wrong query params';

    newRelicService.logError(message);
    alertService.show({
      text: message,
      color: ALERT_COLOR.DANGER,
    });

    return (
      <RouterRedirect
        to={{
          pathname: redirectWhenWrong,
          state: { from: props.location },
        }}
      />
    );
  };

  render() {
    const { component, ...rest } = this.props;
    return <Route {...rest} component={this.renderRoute} />;
  }
}

export default QueryRoute;
