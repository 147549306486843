import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { LoggedUserContext } from 'core/context/logged-user';

import { API } from 'core/api';

import { useWebsockets } from 'core/hooks/useWebsockets';
import { usePrevious } from 'core/hooks/usePrevious';

import { pageSocketService } from 'core/page-socket';

const PageSocketContainer = () => {
  const {
    id: userId,
    currentDealer: { id: dealerId, hideCustomerPhone },
  } = useContext(LoggedUserContext);

  const prevDealerId = usePrevious(dealerId);
  const history = useHistory();
  const location = useLocation();

  const { reconnect } = useWebsockets({
    wsUrl: API[API.WS_URL_KEY_MAPPER.USER].replace(':dealerId', dealerId).replace(':userId', userId),
    onMessage: pageSocketService.onMessage({
      dealerId,
      location,
      history,
      hideCustomerPhone,
    }),
  });

  useEffect(() => {
    if (prevDealerId && prevDealerId !== dealerId) {
      reconnect();
    }
  }, [dealerId, prevDealerId]);

  return null;
};

export default PageSocketContainer;
