import PropTypes from 'prop-types';

import { DealerPropTypes } from 'core/prop-types';

export default PropTypes.shape({
  dealerId: PropTypes.number,
  dealerVdp: PropTypes.string,
  edmundsVdpUrl: PropTypes.string,
  edmundsVehicleDealer: DealerPropTypes.Dealer,
  make: PropTypes.string,
  model: PropTypes.string,
  outdated: PropTypes.bool,
  status: PropTypes.string,
  stockNo: PropTypes.string,
  vin: PropTypes.string,
  year: PropTypes.number,
});
