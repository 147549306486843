import PropTypes from 'prop-types';

import Address from './address';

export default PropTypes.shape({
  address: Address,
  creditApplicationLink: PropTypes.string,
  dealerId: PropTypes.number,
  edmundsDealerReviewLink: PropTypes.string,
  googleAnalytics4Ids: PropTypes.string,
  mediaFacebookLink: PropTypes.string,
  mediaInstagramLink: PropTypes.string,
  mediaTwitterLink: PropTypes.string,
  mediaYoutubeLink: PropTypes.string,
  serviceApplicationLink: PropTypes.string,
  testDriveLink: PropTypes.string,
  timeZone: PropTypes.string,
  vytLink: PropTypes.string,
  hasDoubleOptIn: PropTypes.bool,
  useGtagForGoogleAnalytic: PropTypes.bool,
});
