import { vehicleLookupService } from 'core/vehicle-lookup';

import messageConverter from './graphql-message-converter';
import { addNewMessageToDialogue } from './utils';

export default (obj, { input: { inquiryId, msg: message, user } }, { cache, getCacheKey }) => {
  const convertedMessage = messageConverter(vehicleLookupService.extendMessageWithVehicle(message));

  addNewMessageToDialogue({
    message: convertedMessage,
    rawMessage: message,
    inquiryId,
    user,
    cache,
  });

  return null;
};
