import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { withLoggedUser } from 'core/hocs/logged-user';

import { Logo } from 'shared-components/logo';

import './navigation-footer.styles.scss';

export const NavigationFooter = props => {
  const { className } = props;
  return (
    <div className={classNames('navigation-footer', className)}>
      <div className="d-lg-none">
        <Logo className="logo--blue mb-1 py-0_5 mx-1 px-1" />
      </div>
    </div>
  );
};

NavigationFooter.defaultProps = {
  className: null,
};

NavigationFooter.propTypes = {
  className: PropTypes.string,
};

export default withLoggedUser(NavigationFooter);
