import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Text } from 'shared-components/text';
import { SwitchDealerPhoneNumber } from 'components/header/switch-dealer/switch-dealer-phone-number';

function SwitchDealerItem({ dealer: { phoneNumber, servicePhoneNumber, name }, active }) {
  return (
    <div className="d-flex flex-column align-items-start justify-content-between text-truncate text-left">
      <Text className="switch-dealer__name m-0 max-w-100 font-weight-bold" truncate>
        {name}
      </Text>
      <div
        className={cn({
          'd-none d-lg-block': active,
        })}
      >
        {phoneNumber && (
          <SwitchDealerPhoneNumber
            phone={phoneNumber}
            label="Sales"
            labelColor={active ? '' : 'green'}
            labelDisplay={active ? 'd-inline-block' : ''}
          />
        )}
        {phoneNumber && servicePhoneNumber && active && <Text className="d-inline-block">&nbsp;|&nbsp;</Text>}
        {servicePhoneNumber && (
          <SwitchDealerPhoneNumber
            phone={servicePhoneNumber}
            label="Service"
            labelColor={active ? '' : 'primary'}
            labelDisplay={active ? 'd-inline-block' : ''}
          />
        )}
      </div>
    </div>
  );
}

SwitchDealerItem.propTypes = {
  dealer: PropTypes.shape({
    phoneNumber: PropTypes.string,
    servicePhoneNumber: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  active: PropTypes.bool,
};

SwitchDealerItem.defaultProps = {
  active: false,
};

export default SwitchDealerItem;
