import React from 'react';
import PropTypes from 'prop-types';

import { Loading } from 'shared-components/loading';
import { Error, ERROR_TYPE } from 'shared-components/error';

const LoadingChunk = ({ error, handleRefresh, ...props }) => (
  <>{error ? <Error type={ERROR_TYPE.CHUNK} autoRefetch={false} refetch={handleRefresh} /> : <Loading {...props} />}</>
);

LoadingChunk.defaultProps = {
  error: null,
};

LoadingChunk.propTypes = {
  error: PropTypes.shape({}),
  handleRefresh: PropTypes.func.isRequired,
};

export default LoadingChunk;
