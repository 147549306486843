import PropTypes from 'prop-types';

export default PropTypes.shape({
  avgResponseTime: PropTypes.number,
  firstUnansweredMessageTime: PropTypes.number,
  lastMsgText: PropTypes.string,
  lastMsgTimeStamp: PropTypes.number,
  timerPeriod: PropTypes.number,
  idleCustomerTimeStamp: PropTypes.number,
  timerType: PropTypes.string,
  unanswered: PropTypes.number,
  unread: PropTypes.number,
});
