import PropTypes from 'prop-types';

import { DealerPropTypes } from 'core/prop-types';

export default PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  currentDealer: DealerPropTypes.Dealer,
  photoLink: PropTypes.string,
  hasFbSubscription: PropTypes.bool,
});
