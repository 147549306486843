import { WS_EVENT } from 'core/constants/web-socket';

import { websocketTrackingService } from 'core/websocket-tracking';

export default WebSocketImpl =>
  class UuidTrackingWebSocket extends WebSocketImpl {
    constructor(...args) {
      super(...args);

      this.registerEvent(WS_EVENT.OPEN, this.registerTracking);
      this.registerEvent(WS_EVENT.CLOSE, this.unregisterTracking);
    }

    isEmpty = () => {
      const onMessageSubscribtions = this.events[WS_EVENT.MESSAGE];
      return super.isEmpty() || (onMessageSubscribtions.length === 2 && onMessageSubscribtions[1] === this.onMessage);
    };

    onMessage = message => {
      // track that we received the message
      websocketTrackingService.trackMessage({
        message,
        wsUrl: this.wsUrl,
        loggedUser: this.user,
      });
    };

    registerTracking = () => {
      this.registerEvent(WS_EVENT.MESSAGE, this.onMessage);
    };

    unregisterTracking = () => {
      this.unregisterEvent(WS_EVENT.MESSAGE, this.onMessage);
    };
  };
