import React from 'react';
import PropTypes from 'prop-types';

import { deviceService } from 'core/device';

import { PAGES } from 'core/constants/pages';

import { CategoriesList } from 'shared-components/categories-list';

class InquiriesNavigationCategoriesContainer extends React.Component {
  static propTypes = {
    updateActiveCategoryId: PropTypes.func.isRequired,
    onCategoryClick: PropTypes.func,
    refetch: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    onCategoryClick: null,
  };

  handleCategoryChange = id => {
    const { onCategoryClick, updateActiveCategoryId, history, refetch } = this.props;
    if (onCategoryClick) onCategoryClick();

    if (deviceService.isMobile()) {
      history.push(PAGES.INQUIRIES);
    }

    refetch();

    updateActiveCategoryId({ variables: { input: { id } } });
  };

  render() {
    return <CategoriesList {...this.props} handleCategoryClick={this.handleCategoryChange} />;
  }
}

export default InquiriesNavigationCategoriesContainer;
