import appendMessage from './inquiry/agent-appending-message-resolver';
import customerTexting from './inquiry/customer-texting-resolver';
import inventoryLastCheckedMessage from './inventory-search/last-checked-message-resolver';
import activeInventory from './inventory-search/active-inventory-resolver';
import inventoryIdentifiers from './inventory-search/inventory-identifiers-resolver';
import isInventoryImagesShow from './inventory-search/is-inventory-images-show-resolver';
import isInventorySearchClosed from './inventory-search/is-inventory-search-closed-resolver';

export default {
  // inquiry
  customerTexting,
  appendMessage,
  // inventory search
  inventoryLastCheckedMessage,
  activeInventory,
  inventoryIdentifiers,
  isInventoryImagesShow,
  isInventorySearchClosed,
};
