import PropTypes from 'prop-types';

export default PropTypes.shape({
  messagesCount: PropTypes.number,
  repliesCount: PropTypes.number,
  avgResponseTime: PropTypes.number,
  conversationStartedAt: PropTypes.number,
  conversationEndedAt: PropTypes.number,
  managedByUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      userType: PropTypes.string,
    }),
  ),
});
