import PropTypes from 'prop-types';

export default PropTypes.shape({
  title: PropTypes.string,
  type: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
  datasets: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.string),
      label: PropTypes.shape({}),
    }),
  ),
});
