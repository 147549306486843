import React from 'react';
import PropTypes from 'prop-types';

import { pure } from 'recompose';
import classNames from 'classnames';

import { Icon } from 'shared-components/icon';

const renderIcon = (icon, iconClassName, iconSize) =>
  icon && <Icon name={icon} size={iconSize} className={classNames(iconClassName)} />;

const Text = ({
  tag: Tag,
  color,
  id,
  bold,
  children,
  truncate,
  className,
  icon,
  iconSize,
  iconAppend,
  iconClassName,
  textBreak,
  onClick,
}) => (
  <>
    {!iconAppend && renderIcon(icon, iconClassName, iconSize)}
    {(children || children === 0) && (
      <Tag
        onClick={onClick}
        className={classNames(className, 'm-0 p-0', {
          [`text-${color}`]: color,
          'break-text': textBreak,
          'font-weight-bold': bold,
          'text-truncate': truncate,
          'mr-0_5': icon && iconAppend,
          'ml-0_5': icon && !iconAppend,
        })}
        {...(id ? { id } : {})}
      >
        {children}
      </Tag>
    )}
    {iconAppend && renderIcon(icon, iconClassName, iconSize)}
  </>
);

Text.defaultProps = {
  color: '',
  id: '',
  bold: false,
  iconAppend: false,
  truncate: false,
  tag: 'span',
  className: null,
  iconClassName: null,
  icon: null,
  iconSize: '1rem',
  children: null,
  textBreak: false,
  onClick: null,
};

Text.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  id: PropTypes.string,
  bold: PropTypes.bool,
  truncate: PropTypes.bool,
  className: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  iconClassName: PropTypes.string,
  iconAppend: PropTypes.bool,
  iconSize: PropTypes.string,
  textBreak: PropTypes.bool,
  onClick: PropTypes.func,
};

export default pure(Text);
