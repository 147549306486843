import conversationSummaryConverter from './graphql-conversation-summary-converter';
import { updateDialogueConversationSummary } from './utils';

export default (obj, { input: { user, inquiryId, conversationSummary } }, { cache }) => {
  updateDialogueConversationSummary({
    user,
    inquiryId,
    conversationSummary: conversationSummaryConverter(conversationSummary),
    cache,
  });

  return null;
};
