import React, { useCallback, useState } from 'react';

import KeyboardShortcutsHelpModal from './keyboard-shortcuts-help-modal.presentation';

import './keyboard-shortcuts-help-modal.styles.scss';

const KeyboardShortcutsHelpModalContainer = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const toggleModal = useCallback(() => {
    setModalOpen(isOpen => !isOpen);
  }, [isModalOpen]);

  return (
    <KeyboardShortcutsHelpModal isOpen={isModalOpen} handleOpenModal={openModal} handleToggleModal={toggleModal} />
  );
};

export default KeyboardShortcutsHelpModalContainer;
