/* eslint-disable react/prop-types */
import React from 'react';

// This container contains workaround for the a11y issue in reactstrap:
// https://github.com/reactstrap/reactstrap/issues/1679
// elements outside the dialog can be reached by tab key when modal dialog is open.
// Implementation is based on w3 Modal Dialog Example
// https://www.w3.org/TR/wai-aria-practices/examples/dialog-modal/dialog.html
class AccessibleModalContainer extends React.Component {
  state = {
    ignoreFocusChanges: false,
  };

  componentDidMount() {
    document.addEventListener('focus', this.trapFocus, true);
  }

  componentWillUnmount() {
    document.removeEventListener('focus', this.trapFocus, true);
  }

  focusFirstDescendant = element => {
    for (let i = 0; i < element.childNodes.length; i++) {
      const child = element.childNodes[i];
      if (this.attemptFocus(child) || this.focusFirstDescendant(child)) {
        return true;
      }
    }
    return false;
  };

  attemptFocus = element => {
    this.setState({
      ignoreFocusChanges: true,
    });
    try {
      element.focus();
    } catch (e) {
      // do nothing
    } finally {
      this.setState({
        ignoreFocusChanges: false,
      });
    }

    return document.activeElement === element;
  };

  trapFocus = event => {
    // do not trap focus from not focusable elements
    // this is necessary to allow text selection inside the modal dialog
    if (event.target.tabIndex < 0) {
      return;
    }

    if (this.state.ignoreFocusChanges) {
      return;
    }

    const currentRef = this.props.innerRef && this.props.innerRef.current;
    try {
      const dialogNode = currentRef.querySelector('.modal-dialog');
      if (!dialogNode.contains(event.target)) {
        this.focusFirstDescendant(dialogNode);
      }
    } catch (error) {
      // do nothing
    }
  };

  render() {
    return <>{this.props.children}</>;
  }
}

export default AccessibleModalContainer;
