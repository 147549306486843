import PropTypes from 'prop-types';

import { ATTACHMENT_PREVIEW_STATES } from 'core/constants/attachment-preview';

export default PropTypes.shape({
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  state: PropTypes.oneOf(ATTACHMENT_PREVIEW_STATES),
});
