export const PWA_FEATURES = {
  SERVICE_WORKER: 'serviceWorker',
  NOTIFICATIONS: 'notifications',
  SET_APP_BADGE: 'setAppBadge',
  CLEAR_APP_BADGE: 'clearAppBadge',
  STORAGE: 'storage',
};

export const NOTIFICATION_FEATURES = {
  REQUEST_PERMISSION: 'requestPermission',
};
