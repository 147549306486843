import React from 'react';
import PropTypes from 'prop-types';

const DarkLaunchPresentation = ({ name, dlKeys, when, children }) => {
  const isMatchPositive = when && dlKeys.includes(name);
  const isMatchNegative = !when && !dlKeys.includes(name);

  if (!children || (!isMatchPositive && !isMatchNegative)) {
    return null;
  }

  return <>{children}</>;
};

DarkLaunchPresentation.propTypes = {
  when: PropTypes.bool,
  name: PropTypes.string.isRequired,
  dlKeys: PropTypes.arrayOf(PropTypes.string),
};

DarkLaunchPresentation.defaultProps = {
  when: true,
  dlKeys: [],
};

export default DarkLaunchPresentation;
