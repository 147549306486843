import { httpUtils } from 'core/utils/http';
import { EDW_TRACKING_URL } from 'core/constants/tracking';

const extendEventWithCommonData = eventData => ({
  edwtimestamp: Date.now(),
  ts: Date.now(),
  edwUrl: window.document.location.href,
  ua: navigator && navigator.userAgent,
  ...eventData,
});

const parseEventDataToString = eventData =>
  Object.keys(eventData)
    .map(key => `${key}|${eventData[key]}`)
    .join(';');

const buildEventUrl = (label, eventData) => {
  const extendedEvent = extendEventWithCommonData({
    eventtype: label,
    eventdata: parseEventDataToString(eventData),
  });

  return `${EDW_TRACKING_URL}?${httpUtils.serializeParams(extendedEvent)}`;
};

export const track = (label, eventData) => {
  if (!eventData) {
    return;
  }

  const img = new Image(1, 1);
  img.src = buildEventUrl(label, eventData);
};

export default {
  track,
};
