export const ORIGIN_SOURCE = {
  EDMUNDS: 'edmunds',
  DEALER: 'dealer_and_fbmp_direct',
  MARKETPLACE: 'marketplace',
  MARKETPLACE_DIRECT: 'marketplace_direct',
  SERVICE_LANE: 'service_lane',
  NONE: 'none',
};

export const ORIGIN_SOURCE_FILTER_MAPPING = {
  EDMUNDS: {
    source: 'NONE',
    phoneLabels: ['edmunds.com'],
  },
  DEALER: {
    source: 'NONE',
    phoneLabels: ['sales', 'service'],
  },
  SERVICE_LANE: {
    source: 'SERVICE_LANE',
  },
};
