import { useEffect, useContext, useRef } from 'react';

import { WebSocketsContext } from 'core/context/web-sockets';
import { LoggedUserContext } from 'core/context/logged-user';

/**
 * useWebsockets hook.
 *
 * Accepts an object with the following properties:
 * - wsUrl: The websocket url to establish the connection with.
 * - onMessage: The callback that gets invoked anytime a new message is received.
 *
 * When used, this Hook will establish a websocket connection with the provided url and
 * invoke the provided callback when a message is received. The connection is automatically
 * closed when the component unmounts.
 *
 * The hook returns a reconnect method that will close the existing connection and open a new
 * connection when invoked.
 *
 * E.g.
 *
 * const MyComponent = () => {
 *  const { reconnect } = useWebsockets({
 *    wsUrl: 'ws/url',
 *    onMessage: message => { console.log('received message:', message) },
 *  });
 *
 *  return (
 *    <div>
 *      <button onClick={reconnect}>Reconnect Websocket</button>
 *    </div>
 *  )
 * }
 */

const useWebsockets = ({ wsUrl, onMessage = () => {} }) => {
  const webSockets = useContext(WebSocketsContext);
  const loggedUser = useContext(LoggedUserContext);

  const unsubscribeWs = useRef(null);

  useEffect(() => {
    openWsConnection();
    return () => closeWsConnection();
  }, []);

  const openWsConnection = () => {
    unsubscribeWs.current = webSockets.subscribe({
      wsUrl,
      user: loggedUser,
      onMessage,
    });
  };

  const closeWsConnection = () => {
    unsubscribeWs && unsubscribeWs.current && unsubscribeWs.current();
  };

  const reconnect = () => {
    // close previous connection if exist
    closeWsConnection();
    // reopen connection
    openWsConnection();
  };

  return { reconnect };
};

export default useWebsockets;
