import PropTypes from 'prop-types';

import CannedMessageType from './canned-message-type';

export default PropTypes.shape({
  primaryButtonChannel: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  formButtonColor: PropTypes.string,
  formBackgroundColor: PropTypes.string,
  formHeaderBackgroundColor: PropTypes.string,
  fontFamily: PropTypes.string,
  buttonOnlyTransition: PropTypes.bool,
  offsetX: PropTypes.string,
  offsetY: PropTypes.string,
  formEnableInputName: PropTypes.bool,
  formInputNameRequired: PropTypes.bool,
  cannedMessagesType: CannedMessageType,
});
