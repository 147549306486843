import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

const Loadable = ({ LazyComponent, LoadingComponent, error, ...props }) =>
  error ? (
    <LoadingComponent error={error} />
  ) : (
    <Suspense fallback={<LoadingComponent />}>
      <LazyComponent {...props} />
    </Suspense>
  );

Loadable.defaultProps = {
  error: null,
};

Loadable.propTypes = {
  // no workaround for lazy instance, so added ignore
  // eslint-disable-next-line react/forbid-prop-types
  LazyComponent: PropTypes.object.isRequired,
  LoadingComponent: PropTypes.func.isRequired,
  error: PropTypes.shape({}),
};

export default Loadable;
