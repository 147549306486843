export const AVATAR_URLS = [
  '/images/avatars/avatar_1.png',
  '/images/avatars/avatar_2.png',
  '/images/avatars/avatar_3.png',
  '/images/avatars/avatar_4.png',
  '/images/avatars/avatar_5.png',
  '/images/avatars/avatar_6.png',
];

export const DEFAULT_AVATAR = '/images/avatars/avatar_1.png';
