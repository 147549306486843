import React from 'react';
import PropTypes from 'prop-types';

import { pure } from 'recompose';
import classNames from 'classnames';

import { Text } from 'shared-components/text';
import { Icon } from 'shared-components/icon';
import { Tooltip } from 'shared-components/tooltip';

const CategoryContent = ({ id, className, active, name, description, count, loading }) => (
  <div className={classNames(className, 'w-100 justify-content-start text-gray-darker')}>
    <Text className="d-flex text-nowrap py-0_25">
      {name}
      {!!description && (
        <>
          <Icon
            id={`category-${id}-description`}
            className="text-gray-darkest p-0_25"
            name="question3"
            size="0.75rem"
          />
          <Tooltip id={`category-${id}-description`}>{description}</Tooltip>
        </>
      )}
      {(count || count === 0) && (
        <Text
          className={classNames('text-primary font-weight-semibold pl-0_25 transition-color', {
            'text-gray': loading,
          })}
        >
          {count}
        </Text>
      )}
    </Text>
  </div>
);

CategoryContent.defaultProps = {
  count: null,
  className: '',
  active: false,
  loading: false,
  description: '',
};

CategoryContent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  active: PropTypes.bool,
  loading: PropTypes.bool,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export { CategoryContent };

export default pure(CategoryContent);
