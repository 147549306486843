import React from 'react';

import { Router, Route, Switch } from 'react-router-dom';

import { ROUTES } from 'core/constants/routes';

import { RouterRedirect } from 'shared-components/router-redirect';

import { PrivateRoute } from './components/PrivateRoute';
import { QueryRoute } from './components/QueryRoute';
import { ProtectedRoute } from './components/ProtectedRoute';

import routes from './routes';
import history from './history';

const Main = () => (
  <Router history={history}>
    <main className="h-100 w-100">
      <Switch>
        <RouterRedirect exact from={ROUTES.DEFAULT} to={ROUTES.INQUIRIES} />
        <Route exact path={[ROUTES.LOGIN, ROUTES.USER_PASSWORD]} component={routes.LoginPage} />
        <Route exact path={ROUTES.ERROR} component={routes.ShutDownPage} />
        <Route exact path={ROUTES.SESSION_EXPIRED} component={routes.SessionExpiredPage} />
        <Route exact path={ROUTES.SITEMAP} />
        <Route exact path={ROUTES.FAVICON} />
        <QueryRoute
          exact
          path={ROUTES.USER_NEW_PASSWORD}
          redirectWhenWrong={ROUTES.LOGIN}
          params={['token']}
          component={routes.LoginPage}
        />
        <PrivateRoute exact path={ROUTES.LOGOUT} component={routes.LogoutPage} />
        <PrivateRoute exact path={ROUTES.INQUIRIES} component={routes.InquiriesPage} showNavigation />
        <PrivateRoute exact path={ROUTES.ALL_USERS} component={routes.UsersPage} showNavigation />
        <PrivateRoute exact path={ROUTES.EXISTING_USERS} component={routes.ExistingUsersPage} showNavigation />
        <PrivateRoute exact path={ROUTES.USER_NEW} component={routes.UserPage} showNavigation />
        <PrivateRoute exact path={ROUTES.USER_BY_ID} component={routes.UserPage} showNavigation />
        <PrivateRoute exact path={ROUTES.WIDGET_SETTINGS} component={routes.WidgetSettingsPage} showNavigation />
        <PrivateRoute exact path={ROUTES.WIDGET_NEW} component={routes.WidgetEditPage} showNavigation />
        <PrivateRoute exact path={ROUTES.WIDGET_BY_ID} component={routes.WidgetEditPage} showNavigation />
        <PrivateRoute
          exact
          path={ROUTES.SETTINGS}
          component={routes.GeneralSettingsPage}
          showNavigation
          redirectWhenWrong={ROUTES.PRECONFIGURED_RESPONSES}
        />
        <PrivateRoute exact path={ROUTES.MESSAGING_SETTINGS} component={routes.MessagingSettingsPage} showNavigation />
        <PrivateRoute exact path={ROUTES.SALES_SETTINGS} component={routes.SalesSettingsPage} showNavigation />
        <PrivateRoute exact path={ROUTES.SERVICE_SETTINGS} component={routes.ServiceSettingsPage} showNavigation />
        <PrivateRoute
          exact
          path={ROUTES.PRECONFIGURED_RESPONSES}
          component={routes.PreconfiguredResponsesPage}
          showNavigation
        />
        <PrivateRoute exact path={ROUTES.SPECIALS} component={routes.SpecialsPage} showNavigation />
        <PrivateRoute exact path={ROUTES.REPORTS} component={routes.ReportsPage} showNavigation />
        <PrivateRoute exact path={ROUTES.REPORT_BY_NAME} component={routes.ReportPage} showNavigation />
        <PrivateRoute exact path={ROUTES.NOTIFICATIONS} component={routes.NotificationsPage} showNavigation />
        <PrivateRoute exact path={ROUTES.HELP} component={routes.HelpPage} showNavigation />
        <PrivateRoute exact path={ROUTES.CONTACT_US} component={routes.ContactUsPage} showNavigation />
        <PrivateRoute exact path={ROUTES.SHORTCUTS} component={routes.ShortcutsPage} showNavigation />
        <PrivateRoute exact path={`${ROUTES.RESOURCES}/:pageName?`} component={routes.ResourcesPage} showNavigation />
        <PrivateRoute exact path={ROUTES.SUPPORT} component={routes.SupportPage} showNavigation />
        <PrivateRoute
          exact
          path={ROUTES.DEALER_REPORTING_USER_PERFORMANCE}
          component={routes.UserPerformancePage}
          showNavigation
        />
        <PrivateRoute
          exact
          path={ROUTES.DEALER_REPORTING_LEADS_AND_ENGAGEMENT}
          component={routes.LeadsAndEngagementPage}
          showNavigation
        />
        <PrivateRoute
          exact
          path={ROUTES.DEALER_REPORTING_INQUIRIES_TO_LEADS}
          component={routes.InquiriesToLeadsPage}
          showNavigation
        />
        <PrivateRoute path={`${ROUTES.PAGES}/:pageName`} component={routes.StaticPage} showNavigation />
        <Route exact path={ROUTES.QUICK_START} component={routes.QuickStartPage} />
        <ProtectedRoute exact path={ROUTES.SDK_INSTRUCTIONS} component={routes.SdkInstructionsPage} />
        <Route exact path={ROUTES.BEST_PRACTICES} component={routes.BestPracticesPage} />
        <Route component={routes.NotFoundPage} />
      </Switch>
    </main>
  </Router>
);

export default Main;
