import _ from 'lodash';
import graphql from 'graphql-anywhere';

const resolver = (fieldName, root) =>
  root[fieldName] || _.isBoolean(root[fieldName]) || root[fieldName] === 0 ? root[fieldName] : null;

const pickFromObject = ({ query, object }) => graphql(resolver, query, object);

export default {
  pickFromObject,
};
