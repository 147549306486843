import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'reactstrap';

import { Modal } from 'shared-components/modal';
import { Text } from 'shared-components/text';
import { RouterNavLink } from 'shared-components/router-nav-link';

const Logout = ({ isAutoAssignEnabled, isModalOpen, isLoading, handleToggle, handleSubmit, ...props }) =>
  isAutoAssignEnabled ? (
    <>
      <Button className="justify-content-start" onClick={handleToggle}>
        Log out
      </Button>
      <Modal
        title="Log out"
        handleToggle={handleToggle}
        handleSubmit={handleSubmit}
        handleCancel={handleToggle}
        isOpen={isModalOpen}
        isLoading={isLoading}
        submitText="Logout and Turn Off Auto-Assign"
        centered
      >
        <Text tag="p" className="mb-1 font-size-lg">
          You are about to log out while still being active in the auto-assign rotation. Are you sure you want to
          proceed?
        </Text>
      </Modal>
    </>
  ) : (
    <RouterNavLink {...props} />
  );

Logout.propTypes = {
  isAutoAssignEnabled: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Logout;
