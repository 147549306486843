import { INQUIRIES_LIST_INQUIRY_FRAGMENT } from 'core/queries/inquiries';
import { TYPENAME } from 'core/constants/typename';

import inquiryConverter from './graphql-inquiry-converter';

import {
  updateInquiry,
  addNewInquiryToRelatedLists,
  shouldAddNewInquiry,
  removeInquiryFromUnassignedList,
  removeInquiryFromAssignedToMeList,
  removeInquiryFromAssignedToOthersList,
} from './utils';

export default (obj, { input: { inquiry, userFrom, userTo, assign, user } }, { cache, getCacheKey }) => {
  const id = getCacheKey({ __typename: TYPENAME.INQUIRY, id: inquiry.id });
  const cachedInquiry = cache.readFragment({
    id,
    fragment: INQUIRIES_LIST_INQUIRY_FRAGMENT,
    fragmentName: 'InquiriesListInquiry',
  });
  const nextInquiry = {
    ...(cachedInquiry || {}),
    ...inquiry,
    assigned: userTo,
  };
  // new inquiry was assigned
  if (shouldAddNewInquiry({ inquiry: nextInquiry, user })) {
    const inquiryToInsert = {
      ...nextInquiry,
      assigned: userTo,
    };

    // add to related lists
    addNewInquiryToRelatedLists({
      inquiry: inquiryConverter(inquiryToInsert),
      loggedUser: user,
      cache,
    });
  }

  if (!cachedInquiry) {
    return null;
  }

  updateInquiry({
    cachedInquiry,
    nextInquiry,
    loggedUser: user,
    cache,
    id,
  });

  // if from unassigned to assigned
  if (!userFrom || !userFrom.id) {
    removeInquiryFromUnassignedList({
      inquiryId: inquiry.id,
      loggedUser: user,
      cache,
      cacheId: id,
    });
  }

  // if was reassigned from current user
  if (userFrom && userFrom.id === user.id) {
    removeInquiryFromAssignedToMeList({
      inquiryId: inquiry.id,
      loggedUser: user,
      cache,
      cacheId: id,
    });
  }

  if (!userTo || !userTo.id || userTo.id === user.id) {
    removeInquiryFromAssignedToOthersList({
      inquiryId: inquiry.id,
      loggedUser: user,
      cache,
      cacheId: id,
    });
  }

  return null;
};
