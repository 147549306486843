import React from 'react';
import PropTypes from 'prop-types';

import { UserPropTypes } from 'core/prop-types';

import { PAGES } from 'core/constants/pages';

import Logout from './logout.presentation';

class LogoutContainer extends React.Component {
  static propTypes = {
    disableAutoAssignment: PropTypes.func.isRequired,
    disableAutoAssignmentResult: PropTypes.shape({}),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.shape({}),
    loggedUser: UserPropTypes.LoggedUser.isRequired,
  };

  static defaultProps = {
    staticContext: null,
    disableAutoAssignmentResult: null,
  };

  state = {
    isOpen: false,
    isLoading: false,
  };

  handleToggle = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  redirectToLogoutPage = () => {
    const {
      history: { push },
    } = this.props;
    push(PAGES.LOGOUT);
  };

  handleSubmit = async () => {
    this.setState({ isLoading: true });

    await this.props.disableAutoAssignment({
      variables: {
        input: this.props.loggedUser.id,
      },
    });

    this.setState({ isLoading: false });
    this.handleToggle();
    this.redirectToLogoutPage();
  };

  render() {
    const { isOpen, isLoading } = this.state;
    const {
      loggedUser: { autoAssignmentEnabled },
      disableAutoAssignment,
      disableAutoAssignmentResult,
      history,
      staticContext,
      ...otherProps
    } = this.props;

    return (
      <Logout
        isLoading={isLoading}
        isModalOpen={isOpen}
        isAutoAssignEnabled={autoAssignmentEnabled}
        handleToggle={this.handleToggle}
        handleSubmit={this.handleSubmit}
        {...otherProps}
      />
    );
  }
}

export default LogoutContainer;
