export const MESSAGES = {
  CUSTOMER_UPDATE: 'Customer Info has been updated',
  PASWORD_CHANGED: 'Your password has been changed successfully.',
  RESET_NO_USER: "Sorry. We couldn't find this user.",
  RESET_PASSWORD_SENT: 'Reset password instructions have been sent successfully.',
  TRADE_IN_VEHICLE_UPDATE: 'Trade-in Vehicle has been updated',
  UNKNOWN_ERROR: 'Ooops, something went wrong. Please, try again later.',
  UPDATE_ERROR: 'Something went wrong. Please try again',
  VEHICLE_UPDATE: 'Vehicle Of Interest has been updated',
  PRECONFIGURED_CATEGORY_ADD: 'New category was successfully added',
  PRECONFIGURED_CATEGORY_DELETE: 'Category was successfully deleted',
  PRECONFIGURED_RESPONSE_ADD: 'The Preset response was added successfully',
  PRECONFIGURED_RESPONSE_DELETE: 'The Preset Response was deleted successfully',
  PRECONFIGURED_RESPONSE_UPDATE: 'The Preset Response was updated successfully',
  QUICK_REPLY_ADD: 'Quick replies were successfully added',
  QUICK_REPLY_DELETE: 'Quick replies were successfully deleted',
  QUICK_REPLY_UPDATE: 'Quick replies were successfully updated',
  USER_UPDATE: 'User Info has been updated',
  USER_CREATE: 'User Info has been created',
  USER_ADD_TO_DEALERSHIP: 'Assignment data was updated',
  AVATAR_UPLOAD: 'Avatar was successfully updated',
  NO_PAGE_ACCESS: "Sorry, you don't have access to this page",
  INVITE_CUSTOMER: 'An invitation was sent to ',
  GENERAL_SETTINGS_UPDATE: 'General Settings were successfully updated',
  SPECIALS_UPDATE: 'Dealer Specials were successfully updated',
  SALES_SETTINGS_UPDATE: 'Sales Settings were successfully updated',
  SERVICE_SETTINGS_UPDATE: 'Service Settings were successfully updated',
  MESSAGING_SETTINGS_UPDATE: 'Messaging Settings were successfully updated',
  WIDGET_CREATE: 'Widget has been created',
  WIDGET_UPDATE: 'Widget has been updated',
  INVALID_EMAIL: 'Email is invalid, please try another one',
  TERMS: 'Terms must be accepted to complete account update',
  REPORT_WILL_BE_SENT: 'An email with the requested report will be sent to [email]',
  MESSAGING_CONFIRMATION_MODAL_DEFAULT_TITLE: 'Texting confirmation',
  MESSAGING_CONFIRMATION_MODAL_DEFAULT_TEXT:
    'If you have selected text messaging (also known as “SMS”) as a preferred communication method, ' +
    'you are opting in to receive recurring alerts from Edmunds on behalf of your employer through Edmunds CarCode (“CarCode”). ' +
    'No personal information collected from CarCode will be bought, sold, rented or shared with third parties for marketing purposes.',
  MESSAGING_CONFIRMATION_MODAL_NEW_PASSWORD_TITLE: 'Please read to set your password',
  MESSAGING_CONFIRMATION_MODAL_NEW_PASSWORD_TEXT:
    'If you (or your dealership) have selected text messaging (also known as “SMS”) as a preferred communication method ' +
    'for you to receive text message alerts from Edmunds on behalf of your dealership through Edmunds CarCode (“CarCode”), ' +
    'by setting your password you are (i) opting in to receive such text messages, and (ii) consenting to your dealership ' +
    'providing your mobile number to Edmunds for that purpose. ' +
    'No personal information collected from CarCode will be bought, sold, rented or shared with third parties for marketing purposes.',
};
