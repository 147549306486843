import { INQUIRY_FEEDBACK_FRAGMENT } from 'core/queries/inquiry';

import resolverUtils from 'core/utils/resolver/resolver-utils';
import decorator from 'core/resolvers/graphql-type-decorator';

export default wsConversationSummaryObject => {
  const result = resolverUtils.pickFromObject({
    query: INQUIRY_FEEDBACK_FRAGMENT,
    object: wsConversationSummaryObject,
  });

  return decorator.inquiryFeedback(result);
};
