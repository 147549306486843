import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import sortBy from 'lodash/sortBy';

import { UncontrolledDropdown } from 'reactstrap';

import { DropdownToggle } from 'shared-components/dropdown-toggle';
import { DropdownMenu } from 'shared-components/dropdown-menu';
import { SearchBar } from 'shared-components/search-bar';
import { EmptyContent } from 'shared-components/empty-content';

import { SwitchDealerItem } from 'components/header/switch-dealer/switch-dealer-item';
import { SwitchDealerDropdownItem } from './switch-dealer-dropdown-item';

function SwitchDealer({
  dealers,
  activeDealer,
  isSingleDealership,
  isDealerUser,
  handleClick,
  className,
  handleSearchSubmit,
  unread,
  getUnreadInquiries,
  ...props
}) {
  if (isSingleDealership) {
    return (
      <div className="d-flex pl-1 align-items-center h-100 text-white header__single-dealership">
        <SwitchDealerItem dealer={activeDealer} active />
      </div>
    );
  }

  return (
    <UncontrolledDropdown className={classNames(className)} {...props}>
      <DropdownToggle onClick={getUnreadInquiries} caret className="p-0">
        <SwitchDealerDropdownItem
          active
          tag="div"
          dealer={activeDealer}
          className={classNames(
            'header-dropdown-item header-dropdown-item--active btn justify-content-start text-white pr-2_25',
            {
              dealer: isDealerUser,
            },
          )}
        />
      </DropdownToggle>
      <DropdownMenu className="mt-0 w-100 rounded-0">
        <>
          <SearchBar
            activeBackground="transparent"
            background="transparent"
            border="gray-light"
            className="search-bar w-100 overflow-hide text-gray-darker rounded-0"
            inputClassName="border-0 rounded-0"
            handleSubmit={handleSearchSubmit}
            placeholderText="Search Dealer"
          />
          <SwitchDealerDropdownItem divider className={classNames('header-dropdown-item--divider')} />
          {dealers.length === 0 && (
            <EmptyContent description="No dealers to display" position="static" className="my-1" />
          )}
        </>
        {sortBy(dealers, dealer => dealer.name.toLowerCase()).map((dealer, index) => (
          <React.Fragment key={dealer.id}>
            <SwitchDealerDropdownItem
              value={dealer.id}
              onClick={handleClick}
              dealer={dealer}
              className="header-dropdown-item btn justify-content-start"
              isUnread={!!(unread && unread.find(id => id === dealer.id))}
            />
            {index !== dealers.length - 1 && (
              <SwitchDealerDropdownItem divider dealer={dealer} className="header-dropdown-item--divider mx-1" />
            )}
          </React.Fragment>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

SwitchDealer.defaultProps = {
  className: '',
  unread: [],
};

SwitchDealer.propTypes = {
  className: PropTypes.string,
  unread: PropTypes.arrayOf(PropTypes.number),
  isDealerUser: PropTypes.bool.isRequired,
  getUnreadInquiries: PropTypes.func.isRequired,
  dealers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
    }),
  ).isRequired,
  activeDealer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  }).isRequired,
  isSingleDealership: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleSearchSubmit: PropTypes.func.isRequired,
};

export default SwitchDealer;
