import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { SessionLogPropTypes } from 'core/prop-types';
import { useInterval } from 'core/hooks/useInterval';
import { withSessionLog } from 'core/hocs/session-log-hoc';
import { sessionLogService } from 'core/session-log';
import { SESSION_LOG_KEYS } from 'core/constants/session-log';

import LiveSessionLog from './live-session-log.presentation';

const LiveSessionLogContainer = ({ className, minimized, sessionLogStore }) => {
  const [sessionTime, setSessionTime] = useState(0);

  useEffect(() => {
    const sessionStartDate = sessionLogStore.sessionLogObject[SESSION_LOG_KEYS.START_DATE];
    setSessionTime(sessionLogService.getSessionTime(sessionStartDate));
  }, []);

  useInterval(() => {
    setSessionTime(sessionTime + 1);
  }, 1000);

  return (
    <LiveSessionLog
      className={className}
      minimized={minimized}
      pickups={sessionLogStore.sessionLogObject[SESSION_LOG_KEYS.PICKUPS]}
      handoffs={sessionLogStore.sessionLogObject[SESSION_LOG_KEYS.HANDOFFS]}
      leads={sessionLogStore.sessionLogObject[SESSION_LOG_KEYS.LEADS]}
      sessionTime={sessionTime}
    />
  );
};

LiveSessionLogContainer.defaultProps = {
  className: null,
  minimized: false,
};

LiveSessionLogContainer.propTypes = {
  className: PropTypes.string,
  minimized: PropTypes.bool,
  sessionLogStore: SessionLogPropTypes.SessionLogStore.isRequired,
};

export { LiveSessionLogContainer };

export default withSessionLog(LiveSessionLogContainer);
