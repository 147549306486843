import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { Icon } from 'shared-components/icon';

import { Form, Input, Label, InputGroup, InputGroupAddon, Button } from 'reactstrap';

import './search-bar.styles.scss';

const SearchBar = ({
  isActive,
  className,
  inputClassName,
  innerRef,
  iconColor,
  iconSize,
  background,
  activeBackground,
  border,
  placeholderText,
  handleSubmit,
  handleCancel,
  handleSearchChange,
  handleFocus,
  handleBlur,
}) => {
  const defaultClassName = 'search-bar rounded-oval overflow-hide text-gray-darker border w-100';
  const searchBarClass = className || defaultClassName;

  return (
    <Form
      onSubmit={handleSubmit}
      className={classNames(searchBarClass, {
        [`search-bar--active bg-${activeBackground} border-primary`]: isActive,
        [`bg-${background} border-${border || background}`]: !isActive,
      })}
    >
      <InputGroup className="bg-transparent">
        <InputGroupAddon addonType="prepend" className="position-absolute search-bar__addon search-bar__addon--prepend">
          <Label for="search" className="m-0 px-1 d-flex">
            <Icon
              name="search3"
              size={iconSize}
              className={classNames({
                [`text-${iconColor}`]: isActive,
              })}
            />
          </Label>
        </InputGroupAddon>
        <Input
          innerRef={innerRef}
          type="search"
          placeholder={placeholderText}
          aria-label="Search input"
          onChange={handleSearchChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          id="search"
          autoComplete="off"
          className={classNames(
            'search-bar__input pl-2_5 bg-transparent shadow-none',
            {
              'mr-2_5': handleCancel,
              'pr-1': !handleCancel,
            },
            inputClassName,
          )}
        />
        {handleCancel && (
          <Button
            className="position-absolute search-bar__addon search-bar__addon--append m-0 d-flex"
            color="transparent"
            onClick={handleCancel}
          >
            <Icon
              name="cross2"
              size={iconSize}
              className={classNames({
                [`text-${iconColor}`]: isActive,
              })}
            />
          </Button>
        )}
      </InputGroup>
    </Form>
  );
};

SearchBar.defaultProps = {
  className: null,
  inputClassName: null,
  innerRef: null,
  iconColor: 'primary',
  background: 'gray-light',
  activeBackground: 'white',
  handleCancel: null,
  border: null,
  placeholderText: 'Search',
};

SearchBar.propTypes = {
  isActive: PropTypes.bool.isRequired,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  innerRef: PropTypes.shape({}),
  iconColor: PropTypes.string,
  iconSize: PropTypes.string.isRequired,
  background: PropTypes.string,
  activeBackground: PropTypes.string,
  border: PropTypes.string,
  placeholderText: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  handleSearchChange: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

export default SearchBar;
