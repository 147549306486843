import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { DropdownMenu as ReactstrapDropdownMenu } from 'reactstrap';

const DropdownMenu = ({ className, ...props }) => (
  <ReactstrapDropdownMenu className={classNames(className, 'DropdownMenu')} {...props} />
);

DropdownMenu.defaultProps = {
  className: null,
};

DropdownMenu.propTypes = {
  className: PropTypes.string,
};

export default DropdownMenu;
