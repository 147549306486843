import { IS_INVENTORY_SEARCH_CLOSED_CLIENT_QUERY } from 'core/queries/inventory-search';

import decorator from 'core/resolvers/graphql-type-decorator';

export default (obj, { input: { inquiryId, isClosed } }, { cache, getCacheKey }) => {
  cache.writeQuery({
    query: IS_INVENTORY_SEARCH_CLOSED_CLIENT_QUERY,
    variables: {
      inquiryId,
    },
    data: {
      isInventorySearchClosed: decorator.isInventorySearchClosed({
        isClosed,
      }),
    },
  });

  return null;
};
