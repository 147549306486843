import { API } from 'core/api';
import { USER_PERMISSION } from 'core/constants/user';

import { LOGGED_USER_QUERY } from 'core/queries/logged-user';

import { fetch } from 'core/fetch';

const is = (user, permission) => (user.permissions ? user.permissions.includes(permission) : null);

const some = (user, permissions) => permissions.some(permission => is(user, permission));

const every = (user, permissions) => permissions.every(permission => is(user, permission));

const isAdmin = user => is(user, USER_PERMISSION.ADMIN);

const isCallCenterAdmin = user => is(user, USER_PERMISSION.CALL_CENTER_ADMIN);

const isAgent = user => is(user, USER_PERMISSION.AGENT);

const isUser = user => is(user, USER_PERMISSION.USER);

const isAccountExecutive = user => is(user, USER_PERMISSION.AE);

const isCallCenterUserOrAdmin = user => is(user, USER_PERMISSION.CALL_CENTER_ADMIN) || is(user, USER_PERMISSION.AGENT);

const isDealerUserOrAdmin = user =>
  (is(user, USER_PERMISSION.ADMIN) || is(user, USER_PERMISSION.USER)) && !isCallCenterUserOrAdmin(user);

const getUserType = user => {
  if (isCallCenterUserOrAdmin(user)) return 'CALLCENTER';
  if (isDealerUserOrAdmin(user)) return 'DEALER';
  return 'OTHER';
};

const hasPermissions = ({ user, only, some: somePermissions, every: everyPermissions, not }) => {
  let result = true;

  const conditionsLength = [only, somePermissions, everyPermissions].filter(condition => condition).length;

  if (conditionsLength > 1) {
    throw new Error('Wrong arguments were passed. Only one of [only, some and every] can be passed at the same time');
  }

  if (conditionsLength === 0 && !not) {
    throw new Error('Wrong arguments were passed. No at lease one [only, some, every or not] condition');
  }

  if (only) {
    result = result && is(user, only);
  }

  if (somePermissions) {
    result = result && some(user, somePermissions);
  }

  if (everyPermissions) {
    result = result && every(user, everyPermissions);
  }

  if (not) {
    result = result && !some(user, not);
  }

  return result;
};

const hasDealerPermissions = ({ user }) =>
  hasPermissions({ not: [USER_PERMISSION.CALL_CENTER_ADMIN, USER_PERMISSION.AGENT], user });

const hasDealerAdminPermissions = ({ user }) => isAdmin(user) && !isCallCenterAdmin(user); // since a user logged in as a call center admin also has admin permissions available

const hasCallCenterPermissions = ({ user }) =>
  hasPermissions({ some: [USER_PERMISSION.CALL_CENTER_ADMIN, USER_PERMISSION.AGENT], user });

const uploadPhoto = (userId, blob) => {
  const formData = new FormData();
  formData.append('image', blob);

  return fetch(API.USER_UPLOAD_PHOTO.replace(':userId', userId), {
    method: 'POST',
    body: formData,
  });
};

const removePhoto = userId =>
  fetch(API.USER_UPLOAD_PHOTO.replace(':userId', userId), {
    method: 'DELETE',
  });

const updateWithDefaults = (profile, userId) => {
  const result = { ...profile };
  result.creatorId = userId;
  result.showCoachmarks = false;
  return result;
};

const getLoggedUserFromCache = (cache, token) => {
  try {
    const cachedUserQuery = cache.readQuery({ query: LOGGED_USER_QUERY, variables: { token } });
    const loggedUser = cachedUserQuery.getUserByToken;

    return loggedUser;
  } catch (e) {
    // no user in cache
  }

  return null;
};

export default {
  is,
  isAdmin,
  isAgent,
  isUser,
  isCallCenterAdmin,
  isAccountExecutive,
  some,
  every,
  uploadPhoto,
  updateWithDefaults,
  getLoggedUserFromCache,
  hasPermissions,
  hasDealerPermissions,
  hasDealerAdminPermissions,
  hasCallCenterPermissions,
  removePhoto,
  isCallCenterUserOrAdmin,
  isDealerUserOrAdmin,
  getUserType,
};
