import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import classNames from 'classnames';

import { LOGGED_USER_QUERY } from 'core/queries/logged-user';
import { sessionService } from 'core/session';
import { collectionUtils } from 'core/utils/collection';
import { urlUtils } from 'core/utils/url';

import { PAGES } from 'core/constants/pages';

import SwitchDealer from './switch-dealer.presentation';

class SwitchDealerContainer extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    inNavbar: PropTypes.bool,
    isDealerUser: PropTypes.bool,
    dealers: PropTypes.arrayOf(PropTypes.shape()),
    currentDealer: PropTypes.shape(),
    clearBackgroundInquiries: PropTypes.func.isRequired,
    unread: PropTypes.arrayOf(PropTypes.number),
    refetch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: null,
    inNavbar: false,
    isDealerUser: false,
    dealers: [],
    currentDealer: {},
    unread: [],
  };

  constructor(props) {
    super(props);

    const dealerId = +urlUtils.getUrlParam('dealer_id');
    if (dealerId && dealerId !== this.props.currentDealer.id) {
      this.switchDealer({ dealerId, shouldClearBackgroundInquiries: false });
    }
  }

  state = {};

  getUnreadInquiries = () => {
    this.props.refetch();
  };

  handleClick = e => {
    const { history } = this.props;
    this.switchDealer({ dealerId: +e.currentTarget.value }).then(() => {
      history.push(PAGES.INQUIRIES);
    });
  };

  switchDealer = async ({ dealerId, shouldClearBackgroundInquiries = true }) => {
    const { dealers, mutate, loggedUser, clearBackgroundInquiries } = this.props;
    const activeDealer = _.find(dealers, { id: dealerId });

    if (activeDealer) {
      if (shouldClearBackgroundInquiries) await clearBackgroundInquiries();
      await mutate({
        variables: {
          input: {
            userId: loggedUser.id,
            dealerId: activeDealer.id,
          },
        },
        optimisticResponse: {
          switchDealer: null,
        },
        update: (cache, { data }) => {
          const token = sessionService.isAuthenticated();
          const userQueryCached = cache.readQuery({ query: LOGGED_USER_QUERY, variables: { token } });

          const newUserQuery = {
            ...userQueryCached,
            getUserByToken: {
              ...userQueryCached.getUserByToken,
              currentDealer: activeDealer,
            },
          };

          cache.writeQuery({ query: LOGGED_USER_QUERY, data: newUserQuery });
        },
      });
    }
  };

  handleSearchSubmit = searchText => {
    this.setState({
      searchText,
    });
  };

  render() {
    const { dealers, currentDealer, className, inNavbar, unread, isDealerUser } = this.props;
    const { searchText } = this.state;

    const filteredDealers = collectionUtils.filter({
      collection: dealers,
      searchText,
      searchProperties: ['name', 'phoneNumber', 'servicePhoneNumber'],
    });

    return (
      <SwitchDealer
        dealers={filteredDealers}
        isSingleDealership={!dealers.length}
        className={classNames(className)}
        activeDealer={currentDealer}
        inNavbar={inNavbar}
        isDealerUser={isDealerUser}
        handleClick={this.handleClick}
        handleSearchSubmit={this.handleSearchSubmit}
        unread={unread}
        getUnreadInquiries={this.getUnreadInquiries}
      />
    );
  }
}

export default SwitchDealerContainer;
