import PropTypes from 'prop-types';

import PhoneNumber from './phone-number';

export default PropTypes.shape({
  id: PropTypes.number,
  floatingButtonType: PropTypes.string,
  floatingButtonPosition: PropTypes.string,
  phoneNumbers: PropTypes.arrayOf(PhoneNumber),
  primaryButtonChannel: PropTypes.string,
});
