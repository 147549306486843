import resolverUtils from 'core/utils/resolver/resolver-utils';
import decorator from 'core/resolvers/graphql-type-decorator';

import { INQUIRY_QUERY } from 'core/queries/inquiry';

export default wsInquiryObj => {
  const convertedData = resolverUtils.pickFromObject({
    query: INQUIRY_QUERY,
    object: { inquiry: wsInquiryObj },
  });

  return decorator.inquiry(convertedData.inquiry);
};
