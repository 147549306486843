import React from 'react';
import PropTypes from 'prop-types';

import { UserPropTypes } from 'core/prop-types';

import { PAGES } from 'core/constants/pages';

import { UserProfileImage } from 'shared-components/user-profile-image';
import { Logout } from 'shared-components/logout';

import { HeaderLinkDropdown } from 'components/header/header-link-dropdown';
import { HeaderLinkItem } from 'components/header/header-link-item';

const UserLinkDropdown = ({ loggedUser: { id, name, photoLink }, isDealerUser }) => (
  <HeaderLinkDropdown
    className="header__dropdown"
    text={name}
    items={[
      { to: `${PAGES.USERS}/${id}`, text: 'My Profile' },
      { to: PAGES.LOGOUT, text: 'Log out', tag: Logout },
    ]}
  >
    <HeaderLinkItem
      tag="button"
      text={name}
      className="header-dropdown-item header-dropdown-item--active pr-2_25 font-weight-bold"
    >
      <UserProfileImage photoLink={photoLink} name={name} className="mr-0_5" />
    </HeaderLinkItem>
  </HeaderLinkDropdown>
);

UserLinkDropdown.propTypes = {
  loggedUser: UserPropTypes.LoggedUser.isRequired,
  isDealerUser: PropTypes.bool,
};

UserLinkDropdown.defaultProps = {
  isDealerUser: false,
};

export default UserLinkDropdown;
