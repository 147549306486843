import PropTypes from 'prop-types';

export default PropTypes.shape({
  make: PropTypes.shape({}),
  model: PropTypes.shape({}),
  status: PropTypes.shape({}),
  vin: PropTypes.string,
  year: PropTypes.shape({}),
  tab: PropTypes.string,
});
