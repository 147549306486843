import { REGEXP } from 'core/constants/regexp';

export default (phone, symbols = ['(', ') ', '-']) => {
  if (!phone) {
    return '';
  }

  const skippedPhone = phone.replace(/\D/g, '');
  const matchedPhone = REGEXP.PHONE_FORMAT.exec(skippedPhone);

  if (!matchedPhone) {
    return '';
  }

  let result = `${symbols[0]}${matchedPhone[1]}`;

  if (matchedPhone[2]) {
    result += `${symbols[1]}${matchedPhone[2]}`;
  }

  if (matchedPhone[3]) {
    result += `${symbols[2]}${matchedPhone[3]}`;
  }

  return result;
};
