const getPercentage = (value, maxValue) => {
  const percentageValue = Math.round((value / maxValue) * 100);

  return percentageValue > 100 ? 100 : percentageValue;
};

const isNonNegativeNumber = value => value || value === 0;

export default {
  getPercentage,
  isNonNegativeNumber,
};
