import { gql } from '@apollo/client';

export const INQUIRY_BUTTONS_BAR_FRAGMENT = gql`
  fragment InquiryButtonsBarProperties on DealerDtoCallCenter {
    isMmsDisabled
    hasDirection
    hasFbSubscription
    enableAppraisalForm
    hasCreditApplicationLink
    hasServiceApplicationLink
    hasVytLink
    hasEdmundsDealerReviewLink
    hasTestDriveLink
    edmundsRooftopId
  }
`;

export const ACTIVE_HINT_CLIENT_QUERY = gql`
  query GetActiveHint($inquiryId: Long!) {
    activeHint(inquiryId: $inquiryId) @client {
      key
      prompt
    }
  }
`;
