import merge from 'lodash/merge';

import { INQUIRIES_LIST_INQUIRY_FRAGMENT } from 'core/queries/inquiries';
import { TYPENAME } from 'core/constants/typename';

import inquiryConverter from './graphql-inquiry-converter';
import { updateInquiry, addNewInquiryToRelatedLists, shouldAddNewInquiry } from './utils';

export default (obj, { input: { inquiry, user, addInquiryIfMissing } }, { cache, getCacheKey }) => {
  const id = getCacheKey({ __typename: TYPENAME.INQUIRY, id: inquiry.id });
  const cachedInquiry = cache.readFragment({
    id,
    fragment: INQUIRIES_LIST_INQUIRY_FRAGMENT,
    fragmentName: 'InquiriesListInquiry',
  });

  // fix intermittent crashing related to chat_ended event (https://edmunds.atlassian.net/browse/FBMP-508)
  // merge before converting to avoid overriding with null values
  const convertedInquiry = inquiryConverter(merge({}, cachedInquiry, inquiry));

  if (cachedInquiry) {
    updateInquiry({
      cachedInquiry,
      nextInquiry: convertedInquiry,
      loggedUser: user,
      cache,
      id,
    });
  }

  if (addInquiryIfMissing && shouldAddNewInquiry({ inquiry, user })) {
    addNewInquiryToRelatedLists({
      inquiry: convertedInquiry,
      loggedUser: user,
      cache,
    });
  }

  return null;
};
