import { GET_BACKGROUND_INQUIRIES } from 'core/queries/inquiries-background';

import { sessionService } from 'core/session';
import { userService } from 'core/user';
import { inquiriesBackgroundService } from 'core/inquiries-background';

export default (obj, { input: { id } }, { cache }) => {
  const token = sessionService.isAuthenticated();
  const loggedUser = userService.getLoggedUserFromCache(cache, token);

  const cachedBackgroundInquiries = cache.readQuery({
    query: GET_BACKGROUND_INQUIRIES,
  });
  const newBackgroundInquiry = inquiriesBackgroundService.create({
    id,
  });
  const nextBackgroundInquiries = inquiriesBackgroundService.add(
    cachedBackgroundInquiries.backgroundInquiries,
    newBackgroundInquiry,
    loggedUser,
  );

  cache.writeQuery({
    query: GET_BACKGROUND_INQUIRIES,
    data: {
      backgroundInquiries: nextBackgroundInquiries || [],
    },
  });

  return null;
};
