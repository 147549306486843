import { CUSTOMER_TEXTING_QUERY } from 'core/queries/inquiry';

export default (obj, { id }, { cache }) => {
  try {
    const result = cache.readQuery({
      query: CUSTOMER_TEXTING_QUERY,
      variables: {
        id,
      },
    });

    return result;
  } catch (e) {
    return null;
  }
};
