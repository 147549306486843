import { draftMessagesService } from 'core/draft-messages';

import decorator from 'core/resolvers/graphql-type-decorator';
import { DRAFT_MESSAGES_CLIENT_QUERY } from 'core/queries/inquiry';

import { addDraftMessage } from './utils';

export default (obj, { input: { userId, inquiryId, message } }, { cache }) => {
  const cacheDraftMessages = cache.readQuery({ query: DRAFT_MESSAGES_CLIENT_QUERY });

  const newDraftMessage = decorator.draftMessage({ userId, inquiryId, message });
  const draftMessages = addDraftMessage(cacheDraftMessages.draftMessages, newDraftMessage);

  cache.writeQuery({
    query: DRAFT_MESSAGES_CLIENT_QUERY,
    data: { draftMessages },
  });

  draftMessagesService.saveMessageToStorage({ userId, inquiryId, message });

  return null;
};
