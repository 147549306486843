import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { Icon } from 'shared-components/icon';
import { DropdownItem } from 'shared-components/dropdown-item';
import { SwitchDealerItem } from 'components/header/switch-dealer/switch-dealer-item';

const SwitchDealerDropdownItem = ({ dealer, active, divider, className, isUnread, ...props }) => (
  <DropdownItem
    tag="button"
    divider={divider}
    className={classNames(className, 'd-flex justify-content-between overflow-hide px-1 py-0')}
    customPadding
    {...props}
  >
    {!divider && <SwitchDealerItem dealer={dealer} active={active} />}
    {isUnread && <Icon className="ml-0_75" name="envelop5" size="1rem" />}
  </DropdownItem>
);

SwitchDealerDropdownItem.defaultProps = {
  active: false,
  className: '',
  divider: false,
  dealer: null,
  isUnread: false,
};

SwitchDealerDropdownItem.propTypes = {
  dealer: PropTypes.shape({
    phoneNumber: PropTypes.string,
    servicePhoneNumber: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
  active: PropTypes.bool,
  divider: PropTypes.bool,
  className: PropTypes.string,
  isUnread: PropTypes.bool,
};

export default SwitchDealerDropdownItem;
