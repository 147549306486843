import { moneyFormatter } from 'core/formatters/money';

export const INVENTORY_FALLBACK_IMAGE =
  'https://static.ed.edmunds-media.com/unversioned/img/e17/no-vehicle-stock-1-600x400.jpg';

export const INVENTORY_IDENTIFIER_TYPES = {
  VIN: 'vin',
  STOCK: 'stock',
};

export const INVENTORY_SEARCH_MESSAGE = {
  TITLE: {
    STOCK: 'Stock #%s  was mentioned in the conversation.',
    VIN: 'Vin #%s  was mentioned in the conversation.',
    MULTIPLE: 'Several vehicles were mentioned in this conversation.',
  },
  SUB_TITLE: {
    SINGLE: 'Would you like to pull in data related to this vehicle?',
    MULTIPLE: 'Would you like to pull in data related to these vehicles?',
  },
};

export const HISTORY_PROVIDER = {
  EXPERIAN: 'Experian',
  CARFAX: 'CarFax',
};

export const HISTORY_REPORT_ITEMS = {
  noAccidents: {
    keys: [],
    trueTemplate: ' No accidents reported',
  },
  oneOwner: {
    keys: [],
    trueTemplate: ' One owner',
  },
  buyBackProtection: {
    keys: [],
    trueTemplate: ' Sold with buyback protection',
  },
  personalUseOnly: {
    keys: [],
    trueTemplate: ' Personal vehicle',
    falseTemplate: ' Commercially leased vehicle',
  },
  mileage: {
    keys: ['mileage'],
    trueTemplate: ' [mileage] miles reported most recently',
  },
};

export const INVENTORY_IMAGES_MIN_LENGTH = 10;

export const INVENTORY_SEARCH_INPUT_TAB = {
  IDENTIFIER: {
    id: 'identifier',
    text: 'Vin/Stock',
  },
  ADVANCED: {
    id: 'advanced',
    text: 'Advanced',
  },
};

export const INVENTORY_SEARCH_INPUT_TABS = Object.values(INVENTORY_SEARCH_INPUT_TAB);

export const INVENTORY_SEARCH_VIEW = {
  MESSAGE: 'message',
  INPUT: 'input',
  MENU: 'menu',
};

export const INVENTORY_SEARCH_PAGE_SIZE = 10;

export const INVENTORY_SEARCH_VEHICLE_CHARACTERISTICS = [
  {
    title: 'VIN#',
    field: 'vin',
  },
  {
    title: 'Stock#',
    field: 'stockNumber',
  },
  {
    title: 'Price',
    field: 'displayPrice',
    formatter: moneyFormatter,
  },
];

export const DEFAULT_INVENTORY_SEARCH_PARAMS = {
  tab: INVENTORY_SEARCH_INPUT_TAB.IDENTIFIER.id,
  vin: null,
  make: null,
  model: null,
  year: null,
  status: null,
};
