import { INVENTORY_LAST_CHECKED_MESSAGE_CLIENT_QUERY } from 'core/queries/inventory-search';

import decorator from 'core/resolvers/graphql-type-decorator';

export default (obj, { input: { inquiryId, messageCreatedAt } }, { cache, getCacheKey }) => {
  cache.writeQuery({
    query: INVENTORY_LAST_CHECKED_MESSAGE_CLIENT_QUERY,
    variables: {
      inquiryId,
    },
    data: {
      inventoryLastCheckedMessage: decorator.inventoryLastCheckedMessage({
        messageCreatedAt,
      }),
    },
  });

  return null;
};
