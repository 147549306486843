import eventConverter from './graphql-event-converter';

import { addNewEventToDialogue } from './utils';

export default (obj, { input: { inquiryId, msg: event, user } }, { cache, getCacheKey }) => {
  const convertedEvent = eventConverter(event);

  addNewEventToDialogue({
    event: convertedEvent,
    inquiryId,
    user,
    cache,
  });

  return null;
};
