import PropTypes from 'prop-types';

import LeadAssignmentRule from './lead-assignment-rule';

export default PropTypes.shape({
  crmEmail: PropTypes.string,
  departmentType: PropTypes.oneOf(['EDMUNDS', 'NONE', 'SALES', 'SERVICE']),
  fridayHours: PropTypes.string,
  leadAssignmentRule: LeadAssignmentRule,
  mondayHours: PropTypes.string,
  nonWorkingHoursReply: PropTypes.string,
  saturdayHours: PropTypes.string,
  sundayHours: PropTypes.string,
  thursdayHours: PropTypes.string,
  timeZone: PropTypes.string,
  tuesdayHours: PropTypes.string,
  voiceCallGreetings: PropTypes.string,
  voiceCallRedirectNumber: PropTypes.string,
  wednesdayHours: PropTypes.string,
});
