export const COOKIE_KEYS = {
  TOKEN: 'token',
  LOGIN_EMAIL: 'login_email',
  LOGIN_REMEMBER: 'login_remember',
  USER: 'uid',
  DEALER: 'did',
  EDW_PARTNER_VISITOR_ID: '_edwpv',
  EDW_PARTNER_SESSION_ID: '_edwps',
  CARCODE_SMS_FULLSCREEN_DISMISS: 'carcodesms_fullscreen_dismiss',
  CARCODE_APPLICATION_NOTIFICATION_DISMISS: 'carcode_application_notification_dismiss',
  CARCODE_PWA_INSTALLED: 'carcode_pwa_installed',
};
