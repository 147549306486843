import v4 from 'uuid';

import { userService } from 'core/user';
import { appInfoUtils } from 'core/utils/app-info';

/**
 * Builds a request id with a provided user id and dealer id
 * @param cache An apollo cache object from the link context
 * @param token A valid auth token
 * @returns {string}
 */
const requestId = (cache, token) => {
  const artifactVersionNumber = appInfoUtils.getAppVersion();
  const loggedUser = userService.getLoggedUserFromCache(cache, token);
  const requestIdentifier = v4();

  if (loggedUser) {
    return `${requestIdentifier}|u${loggedUser.id}|d${loggedUser.currentDealer.id}|v${artifactVersionNumber}`;
  }

  return `${requestIdentifier}|v${artifactVersionNumber}`;
};

/**
 * Generate an x-client-request-id http header object
 * @param cache An apollo cache object
 * @param token  A valid auth token
 * @returns {{"x-client-request-id": string}}
 */
const xClientRequestId = (cache, token) => ({ 'x-client-request-id': requestId(cache, token) });

/**
 * Generate an X-Artifact-Version http header object
 * @returns {{X-Artifact-Version: string}}
 */
const xArtifactVersion = () => ({ 'X-Artifact-Version': appInfoUtils.getAppVersion() });

/**
 * Generate an X-Artifact-Id http header object
 * @param cache An apollo cache object
 * @param token A valid auth token
 * @returns {string|{X-Artifact-Id: string}}
 */
const xArtifactId = (cache, token) => {
  const loggedUser = userService.getLoggedUserFromCache(cache, token);

  if (loggedUser) {
    return { 'X-Artifact-Id': userService.getUserType(loggedUser) };
  }

  return '';
};

/**
 * Generate an X-AUTH-TOKEN http header object
 * @param token A valid Auth Token
 * @returns {{"X-AUTH-TOKEN"}}
 */
const xAuthToken = token => ({ 'X-AUTH-TOKEN': token });

export default {
  xClientRequestId,
  xAuthToken,
  xArtifactVersion,
  xArtifactId,
};
