import PropTypes from 'prop-types';

export default PropTypes.shape({
  friday: PropTypes.bool,
  monday: PropTypes.bool,
  saturday: PropTypes.bool,
  sunday: PropTypes.bool,
  thursday: PropTypes.bool,
  tuesday: PropTypes.bool,
  wednesday: PropTypes.bool,
});
