import React from 'react';
import PropTypes from 'prop-types';

import { shallowEqual } from 'recompose';

import { Alert as ReactstrapAlert } from 'reactstrap';
import classNames from 'classnames';
import { ALERT_TYPE } from 'core/constants/alert';

import './alert.styles.scss';

class Alert extends React.PureComponent {
  static propTypes = {
    color: PropTypes.string,
    text: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    onDismiss: PropTypes.func.isRequired,
    type: PropTypes.string,
  };

  static defaultProps = {
    color: 'info',
    type: ALERT_TYPE.DEFAULT,
  };

  state = {
    isOpen: true,
  };

  componentDidUpdate(prevProps) {
    if (!shallowEqual(this.props, prevProps)) {
      // make it visible with new props
      this.onUpdate();
    }
  }

  onUpdate = () => {
    this.setState({ isOpen: true });
  };

  onDismiss = () => {
    // dismiss when user clicks on 'close button'
    this.setState({ isOpen: false });
    this.props.onDismiss(this.props.id);
  };

  render() {
    const { isOpen } = this.state;
    const { color, text, type } = this.props;

    return (
      <ReactstrapAlert
        data-test="cc-alert"
        color={color}
        isOpen={isOpen}
        toggle={this.onDismiss}
        className={classNames('alert text-center', {
          conversation: type === ALERT_TYPE.CONVERSATION,
        })}
      >
        {text}
      </ReactstrapAlert>
    );
  }
}

export default Alert;
