export const USER_PERMISSION = {
  CALL_CENTER_ADMIN: 'Call_Center_Admin',
  AGENT: 'Call_Center_Agent',
  ADMIN: 'Admin',
  USER: 'User',
  AE: 'AE',
};

export const CALL_CENTER_USER_TYPE = {
  CALL_CENTER_ADMIN: 'call_center_admin',
  CALL_CENTER_AGENT: 'call_center_agent',
};

export const DEALER_USER_TYPE = {
  USER: 'user',
  ADMIN: 'admin',
};

export const USER_TYPE = {
  ...CALL_CENTER_USER_TYPE,
  ...DEALER_USER_TYPE,
};

export const USER_ROLE = {
  [USER_TYPE.CALL_CENTER_ADMIN]: 'Admin (Call Center)',
  [USER_TYPE.CALL_CENTER_AGENT]: 'Agent',
  [USER_TYPE.USER]: 'User',
  [USER_TYPE.ADMIN]: 'Admin',
};

export const USER_TYPES_TO_CREATE = {
  CALL_CENTER: [
    {
      label: 'Call Center Admin',
      value: USER_TYPE.CALL_CENTER_ADMIN,
    },
    {
      label: 'Agent',
      value: USER_TYPE.CALL_CENTER_AGENT,
    },
  ],
  DEALER: [
    {
      label: 'Admin',
      value: USER_TYPE.ADMIN,
    },
    {
      label: 'User',
      value: USER_TYPE.USER,
    },
  ],
};

export const USER_TYPE_OPTIONS = [...USER_TYPES_TO_CREATE.DEALER, ...USER_TYPES_TO_CREATE.CALL_CENTER];

export const USER_STATE = {
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
  INACTIVE: 'INACTIVE',
};

export const USER_TYPE_BY_ORIGIN = {
  CALL_CENTER: 'Call Center',
  DEALER_APP: 'Dealer Rep',
};

export const USER_ASSIGN_AVAILABILITY = {
  AVAILABLE: 'Available',
  MAX_CAPACITY: 'Max Capacity',
  OFFLINE: '(Offline)',
};

export const USER_ASSIGN_AVAILABILITIES = Object.values(USER_ASSIGN_AVAILABILITY);

export const USER_INQUIRY_CAPACITY_RANGE = {
  MIN: 2,
  MAX: 10,
};
