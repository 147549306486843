import { tokenService } from 'core/token';
import { userService } from 'core/user';

import { ROUTE_PERMISSIONS, ROUTE_ONLY_OWN_ID_PERMISSIONS } from 'core/constants/routes';

const set = ({ token, remember }) => {
  tokenService.set({
    token,
    remember,
  });
};

const isAuthenticated = () => tokenService.get();

const destroy = () => {
  tokenService.remove();
};

const isAccessProvided = ({ path, params }, user) => {
  let hasOnlyOwnIdAccess = false;

  if (ROUTE_ONLY_OWN_ID_PERMISSIONS[path]) {
    hasOnlyOwnIdAccess = userService.hasPermissions({
      user,
      ...ROUTE_ONLY_OWN_ID_PERMISSIONS[path],
    });
  }

  if (hasOnlyOwnIdAccess) {
    return params.userId === user.id.toString();
  }

  if (ROUTE_PERMISSIONS[path]) {
    return userService.hasPermissions({
      user,
      ...ROUTE_PERMISSIONS[path],
    });
  }

  return true;
};

const isReadOnly = user => !userService.isUser(user);

export default {
  set,
  destroy,
  isAuthenticated,
  isAccessProvided,
  isReadOnly,
};
