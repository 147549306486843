import React, { useContext } from 'react';

import { LoggedUserContext } from 'core/context/logged-user';

import { userService } from 'core/user';

import { PageSocket } from 'components/page-socket';

import Page from './page.presentation';

const PageContainer = props => {
  const loggedUser = useContext(LoggedUserContext);

  const isDealerUser = userService.hasDealerPermissions({ user: loggedUser });

  return (
    <>
      {isDealerUser && <PageSocket />}
      <Page {...props} showApplicationNotification={isDealerUser} />
    </>
  );
};

export default PageContainer;
