import React from 'react';
import PropTypes from 'prop-types';

import { Route } from 'react-router-dom';

import { Page } from 'pages/page';

import { AutoLogout } from 'shared-components/auto-logout';
import { LoadingQuery } from 'shared-components/loading-query';
import { KeyboardShortcutsHelpModal } from 'shared-components/keyboard-shortcuts/keyboard-shortcuts-help-modal';
import { RouterRedirect } from 'shared-components/router-redirect';

import { PwaOrchestrator } from 'components/pwa-orchestrator';

import classNames from 'classnames';

import { LoggedUserContext } from 'core/context/logged-user';
import { ReadOnlyContext } from 'core/context/read-only';

import { sessionService } from 'core/session';
import { alertService } from 'core/alert';
import { userService } from 'core/user';
import { darkLaunchService } from 'core/dark-launch';
import { newRelicService } from 'core/new-relic';

import { config } from 'core/config';
import { init as initApi } from 'core/api/api';

import { MESSAGES } from 'core/constants/messages';
import { ROUTES } from 'core/constants/routes';
import { ALERT_COLOR } from 'core/constants/alert';
import { LOGGED_USER_QUERY } from 'core/queries/logged-user';
import { DARK_LAUNCH_FLAGS_QUERY } from 'core/queries/dark-launch';

class PrivateRoute extends React.Component {
  static propTypes = {
    component: PropTypes.func.isRequired,
    redirectWhenWrong: PropTypes.string,
    showNavigation: PropTypes.bool,
  };

  static defaultProps = {
    redirectWhenWrong: ROUTES.INQUIRIES,
    showNavigation: false,
  };

  renderRedirect = pathname => (
    <RouterRedirect
      to={{
        pathname,
        state: { from: this.props.location },
      }}
    />
  );

  renderRoute = props => {
    const { component: Component, redirectWhenWrong, showNavigation, location } = this.props;
    newRelicService.setRouteName(location.pathname, location.search);

    const token = sessionService.isAuthenticated();

    return token ? (
      <LoadingQuery query={DARK_LAUNCH_FLAGS_QUERY}>
        {({ error: darkLaunchQueryError, data: darkLaunchQueryData }) => {
          darkLaunchService.init(darkLaunchQueryError ? [] : darkLaunchQueryData.darkLaunchFlags);

          if (darkLaunchService.is('websocket-v2')) {
            initApi(config, { enableV2WS: true });
          }

          return (
            <LoadingQuery query={LOGGED_USER_QUERY} variables={{ token }}>
              {({ error, data }) => {
                if (error) {
                  newRelicService.logError(error);
                  return `Error!: ${error}`;
                }

                // extend with User.Prototype
                const user = data.getUserByToken;
                const isAccessProvided = sessionService.isAccessProvided(props.match, user);
                const isCallCenterUser = userService.hasCallCenterPermissions({ user });
                const isReadOnly = sessionService.isReadOnly(user);

                if (!isAccessProvided) {
                  newRelicService.logError(MESSAGES.NO_PAGE_ACCESS);
                  alertService.show({
                    text: MESSAGES.NO_PAGE_ACCESS,
                    color: ALERT_COLOR.DANGER,
                  });

                  return this.renderRedirect(redirectWhenWrong);
                }

                return (
                  <LoggedUserContext.Provider value={user}>
                    <ReadOnlyContext.Provider value={isReadOnly}>
                      <PwaOrchestrator />
                      <KeyboardShortcutsHelpModal />
                      <Page showHeader showNavigation={showNavigation}>
                        <Component {...props} className={classNames(props.className, 'fadeIn')} />
                      </Page>
                      {isCallCenterUser && <AutoLogout />}
                    </ReadOnlyContext.Provider>
                  </LoggedUserContext.Provider>
                );
              }}
            </LoadingQuery>
          );
        }}
      </LoadingQuery>
    ) : (
      this.renderRedirect(ROUTES.LOGIN)
    );
  };

  render() {
    const { component, ...rest } = this.props;
    return <Route {...rest} component={this.renderRoute} />;
  }
}

export default PrivateRoute;
