import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from './alert';

const AlertList = ({ alerts, onDismiss }) =>
  alerts.map(alert => <Alert key={alert.id} {...alert} onDismiss={onDismiss} />);

AlertList.propTypes = {
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ),
  onDismiss: PropTypes.func.isRequired,
};

export default AlertList;
