import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { Button } from 'reactstrap';

import { CategoryContent } from './category-content';

import './category.styles.scss';

const Category = ({ active, loading, name, description, count, id, handleClick }) => (
  <Button
    onClick={handleClick}
    onKeyPress={handleClick}
    value={id}
    data-test={`cc-inquiry-category-item-${id}`}
    color="transparent"
    className={classNames('category border-0 text-left p-0 my-0 mx-0 w-100 pl-3 pr-1 mb-0_5', {
      'bg-gray-light': active,
    })}
  >
    <CategoryContent
      id={id}
      name={name}
      description={description}
      count={count}
      active={active}
      loading={loading}
      tabIndex="-1"
      className="font-weight-bold text-gray-darkest"
    />
  </Button>
);

Category.defaultProps = {
  description: '',
  count: null,
  active: false,
  loading: false,
  btnClassName: '',
  contentClassName: '',
};

Category.propTypes = {
  active: PropTypes.bool,
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default Category;
