import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { UiPropTypes, UserPropTypes } from 'core/prop-types';

import { Nav } from 'reactstrap';

import { AdminPermissions } from 'shared-components/admin-permissions';
import { CallCenterAdminPermissions } from 'shared-components/call-center-admin-permissions';

import { deviceService } from 'core/device';

import { NavigationItem } from './navigation-item';
import { NavigationItemsFooter } from './navigation-items-footer';

const NavigationItems = ({ links, collapsed, handleToggle, loggedUser, isOpen, showLiveSessionLog, readOnly }) => {
  const isDesktop = deviceService.isDesktop();
  return (
    <div
      className={classNames('navigation__items d-flex flex-column overflow-y-auto overflow-x-hide pt-1 h-100', {
        'navigation__items--with-session-log': isDesktop && showLiveSessionLog,
        'navigation__items--with-session-log-collapsed': isDesktop && showLiveSessionLog && collapsed,
      })}
    >
      <Nav navbar className="mb-5 mb-lg-0">
        {links.map(link => {
          const navItem = link.component ? (
            React.cloneElement(link.component, {
              ...link,
              collapsed,
              handleToggle,
              key: link.to,
            })
          ) : (
            <NavigationItem
              {...link}
              key={link.to}
              onClick={link.navItems || link.children ? null : handleToggle}
              collapsed={collapsed}
              handleToggle={handleToggle}
            />
          );

          if (link.isAdmin) {
            return <AdminPermissions key={link.to}>{navItem}</AdminPermissions>;
          }

          if (link.isCallCenterAdmin) {
            return <CallCenterAdminPermissions key={link.to}>{navItem}</CallCenterAdminPermissions>;
          }

          return navItem;
        })}
      </Nav>
      <NavigationItemsFooter
        className="mt-auto"
        handleToggle={handleToggle}
        loggedUser={loggedUser}
        collapsed={collapsed}
        isOpen={isOpen}
        showLiveSessionLog={showLiveSessionLog}
        readOnly={readOnly}
      />
    </div>
  );
};

NavigationItems.propTypes = {
  links: PropTypes.arrayOf(UiPropTypes.NavigationItem).isRequired,
  collapsed: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  loggedUser: UserPropTypes.LoggedUser.isRequired,
  isOpen: PropTypes.bool.isRequired,
  showLiveSessionLog: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default NavigationItems;
