import { LimitedQueue } from 'core/limited-queue';

const vehiclesQueue = new LimitedQueue({
  limit: 5,
  limitBy: 'messageId',
});

const push = ({ messageId, vehicle }) =>
  vehiclesQueue.push({
    messageId,
    vehicle,
  });

const get = messageId => {
  const result = vehiclesQueue.get(messageId);

  return result && result.vehicle;
};

export default {
  push,
  get,
};
