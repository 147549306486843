import _ from 'lodash';

import { Loadable } from 'shared-components/loadable';
import { LoadingChunk } from 'shared-components/loading-chunk';

const pages = [
  {
    name: 'InquiriesPage',
    entry: 'inquiries-page/inquiries-page.container',
  },
  {
    name: 'UsersPage',
    entry: 'users-page/users-page.presentation',
  },
  {
    name: 'ExistingUsersPage',
    entry: 'existing-users-page/existing-users-page.presentation',
  },
  {
    name: 'GeneralSettingsPage',
    entry: 'general-settings-page/general-settings-page.presentation',
  },
  {
    name: 'WidgetSettingsPage',
    entry: 'widget-settings-page/widget-settings-page.presentation',
  },
  {
    name: 'WidgetEditPage',
    entry: 'widget-edit-page/widget-edit-page.presentation',
  },
  {
    name: 'PreconfiguredResponsesPage',
    entry: 'preconfigured-responses-page/preconfigured-responses-page.presentation',
  },
  {
    name: 'MessagingSettingsPage',
    entry: 'messaging-settings-page/messaging-settings-page.presentation',
  },
  {
    name: 'SalesSettingsPage',
    entry: 'sales-settings-page/sales-settings-page.presentation',
  },
  {
    name: 'ServiceSettingsPage',
    entry: 'service-settings-page/service-settings-page.presentation',
  },
  {
    name: 'SpecialsPage',
    entry: 'specials-page/specials-page.presentation',
  },
  {
    name: 'SupportPage',
    entry: 'support-page/support-page.presentation',
  },
  {
    name: 'ResourcesPage',
    entry: 'resources-page/resources-page.presentation',
  },
  {
    name: 'LoginPage',
    entry: 'login-page/login-page.presentation',
  },
  {
    name: 'LogoutPage',
    entry: 'logout-page/logout-page.presentation',
  },
  {
    name: 'UserPage',
    entry: 'user-page/user-page.presentation',
  },
  {
    name: 'ReportsPage',
    entry: 'reports-page/reports-page.presentation',
  },
  {
    name: 'ReportPage',
    entry: 'report-page/report-page.presentation',
  },
  {
    name: 'UserPerformancePage',
    entry: 'user-performance-page/user-performance-page.presentation',
  },
  {
    name: 'LeadsAndEngagementPage',
    entry: 'leads-and-engagement-page/leads-and-engagement-page.presentation',
  },
  {
    name: 'InquiriesToLeadsPage',
    entry: 'inquiries-to-leads-page/inquiries-to-leads-page.presentation',
  },
  {
    name: 'ShutDownPage',
    entry: 'shut-down-page/shut-down-page.presentation',
  },
  {
    name: 'NotFoundPage',
    entry: 'not-found-page/not-found-page.presentation',
  },
  {
    name: 'SessionExpiredPage',
    entry: 'session-expired-page/session-expired-page.presentation',
  },
  {
    name: 'ShortcutsPage',
    entry: 'shortcuts-page/shortcuts-page.presentation',
  },
  {
    name: 'NotificationsPage',
    entry: 'notifications-page/notifications-page.presentation',
  },
  {
    name: 'HelpPage',
    entry: 'help-page/help-page.presentation',
  },
  {
    name: 'ContactUsPage',
    entry: 'contact-us-page/contact-us-page.presentation',
  },
  {
    name: 'SdkInstructionsPage',
    entry: 'sdk-instructions-page/sdk-instructions-page.presentation',
  },
  {
    name: 'QuickStartPage',
    entry: 'quick-start-page/quick-start-page.presentation',
  },
  {
    name: 'BestPracticesPage',
    entry: 'best-practices-page/best-practices-page.presentation',
  },
  {
    name: 'StaticPage',
    entry: 'static-page/static-page.presentation',
  },
];

export default _.reduce(
  pages,
  (result, page) =>
    _.assign(result, {
      [page.name]: Loadable({
        // here is some issue with eslint for the next line
        // it throws warning since dynamic import and curly braces are used
        // no a good way to resolve it for now
        // eslint-disable-next-line prefer-template
        loader: () => import('../../pages/' + page.entry),
        LoadingComponent: LoadingChunk,
      }),
    }),
  {},
);
