import _ from 'lodash';

import { BUTTONS_BAR_HINTS, USED_HINTS_STORAGE_KEY } from 'core/constants/buttons-bar-hints';

import { sessionStorage } from 'core/storages/session';
import { numberUtils } from 'core/utils/number';

const findLastOccurenceIndex = (messageText, pattern) => {
  const match = messageText ? messageText.match(pattern) : null;
  return match && match.length ? messageText.lastIndexOf(match[match.length - 1]) : -1;
};

const findLastOccurencesIndex = (messageText, patterns) => {
  const tipsPositions = patterns
    .map(pattern => findLastOccurenceIndex(messageText, pattern))
    .filter(position => position !== -1);

  return _.max(tipsPositions);
};

const findActiveHint = (inquiryId, messages, buttonsBarItems) => {
  if (!messages || !messages.length) {
    return null;
  }

  let i = messages.length - 1;

  while (i >= 0) {
    const messageText = messages[i--].body;
    const occurences = BUTTONS_BAR_HINTS.filter(hint => _.find(buttonsBarItems, { key: hint.key }))
      .map(hint => ({
        index: findLastOccurencesIndex(messageText, hint.patterns),
        item: hint,
      }))
      .filter(hint => numberUtils.isNonNegativeNumber(hint.index));

    // if something were found then return the last
    if (occurences.length) {
      const latestHint = _.maxBy(occurences, 'index').item;
      if (!isUsedHint(inquiryId, latestHint.key)) {
        return latestHint;
      }
      return null;
    }
  }

  return null;
};

const saveUsedHint = (inquiryId, hintKey) => {
  const allUsedHints = sessionStorage.get(USED_HINTS_STORAGE_KEY) || [];
  const inquiryData = _.remove(allUsedHints, { inquiryId })[0] || {
    inquiryId,
    usedHints: [],
  };
  inquiryData.usedHints.push(hintKey);

  sessionStorage.set(USED_HINTS_STORAGE_KEY, [...allUsedHints, inquiryData]);
};

const isUsedHint = (inquiryId, hintKey) => {
  if (!inquiryId) {
    return false;
  }
  const allUsedHints = sessionStorage.get(USED_HINTS_STORAGE_KEY) || [];
  const inquiryData = _.remove(allUsedHints, { inquiryId })[0];

  return inquiryData ? inquiryData.usedHints.includes(hintKey) : false;
};

export default {
  findActiveHint,
  saveUsedHint,
  isUsedHint,
};
