import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.number,
  dealerId: PropTypes.number,
  validTill: PropTypes.string,
  text: PropTypes.string,
  createdAt: PropTypes.number,
  updatedAt: PropTypes.number,
  turnOnAsPopup: PropTypes.bool,
  applyForReturningVisitors: PropTypes.bool,
  popupDelayTime: PropTypes.number,
});
