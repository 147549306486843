import _ from 'lodash';

import { config } from 'core/config';
import { userService } from 'core/user';

import { TYPENAME } from 'core/constants/typename';
import { LIMIT, LIMIT_DEALER } from 'core/constants/inquiries/background';

const add = (cachedBackroundInquiries, newBackgroundInquiry, loggedUser = null) => {
  let limit = config.backgroundInquiriesLimit || LIMIT;
  if (loggedUser && userService.isDealerUserOrAdmin(loggedUser)) {
    limit = LIMIT_DEALER;
  }

  if (!newBackgroundInquiry) {
    return cachedBackroundInquiries;
  }

  // if no cached create an array with new one
  if (!cachedBackroundInquiries) {
    return [newBackgroundInquiry];
  }

  // if new exists in cache then skip adding
  if (_.find(cachedBackroundInquiries, { id: newBackgroundInquiry.id })) {
    return cachedBackroundInquiries;
  }

  return _.takeRight(cachedBackroundInquiries, limit - 1).concat([newBackgroundInquiry]);
};

const remove = (cachedBackroundInquiries, { id }) => {
  if (!id) {
    return cachedBackroundInquiries;
  }

  if (!cachedBackroundInquiries) {
    return null;
  }

  return cachedBackroundInquiries.filter(backgroundInquiry => backgroundInquiry.id !== id);
};

const create = ({ id }) => ({
  __typename: TYPENAME.BACKGROUND_INQUIRY,
  id: +id,
});

export default {
  add,
  remove,
  create,
};
