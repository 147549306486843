import PropTypes from 'prop-types';

export default PropTypes.shape({
  department: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  selectedDate: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.date,
  }),
  selectedTime: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.number,
  }),
  note: PropTypes.string,
  actionHint: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  isAvailable: PropTypes.bool,
  isSelected: PropTypes.bool,
});
