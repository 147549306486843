import React from 'react';
import PropTypes from 'prop-types';

import { Category } from './category';

const CategoriesList = ({ categories, handleCategoryClick }) =>
  categories.map(category => <Category {...category} key={category.id} handleClick={handleCategoryClick} />);

CategoriesList.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      name: PropTypes.string.isRequired,
      count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ),
};

export default CategoriesList;
