import React from 'react';
import PropTypes from 'prop-types';

import { Redirect } from 'react-router-dom';

import { Loading } from 'shared-components/loading';

const RouterRedirect = props => {
  if (window.swUpdate && props.to) {
    let location;
    if (typeof props.to === 'string') {
      location = props.to;
    } else {
      location = props.to.pathname;
    }

    window.location = location;
    return <Loading />;
  }

  return <Redirect {...props} />;
};

RouterRedirect.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

RouterRedirect.defaultProps = {
  to: null,
};

export default RouterRedirect;
