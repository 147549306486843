import { CONFIG, WS_EVENT } from 'core/constants/web-socket';

export default WebSocketImpl =>
  class AutoReconnectWebSocket extends WebSocketImpl {
    constructor(...args) {
      super(...args);

      this.reconnectCount = 0;
      this.reconnectTimeout = null;
      this.registerReconnect();
    }

    registerReconnect = () => {
      this.registerEvent(WS_EVENT.ERROR, this.reconnect);
      this.registerEvent(WS_EVENT.CLOSE, this.reconnect);
    };

    unregisterReconnect = () => {
      this.unregisterEvent(WS_EVENT.ERROR, this.reconnect);
      this.unregisterEvent(WS_EVENT.CLOSE, this.reconnect);
    };

    reconnect = () => {
      // do not need to reconnect if socket is opened
      if (this.is(window.WebSocket.OPEN) || this.is(window.WebSocket.CONNECTING)) {
        return;
      }

      // do not need to reconnect if socket was destroyed
      if (this.isDestroyed) {
        this.unregisterReconnect();

        return;
      }

      // unregister reconnect listeners if socket was normally closed
      if (this.reconnectCount >= CONFIG.RECONNECT_LIMIT) {
        this.unregisterReconnect();

        return;
      }

      // if not already trying to reconnect
      if (!this.reconnectTimeout) {
        this.reconnectCount += 1;

        this.reconnectTimeout = setTimeout(this.timeoutReconnect, this.getReconnectTime());
        this.registerEvent(WS_EVENT.OPEN, this.onReconnect);

        super.reconnect();
      }
    };

    timeoutReconnect = () => {
      this.reconnectTimeout = null;

      this.reconnect();
    };

    onReconnect = () => {
      if (this.reconnectCount) {
        clearTimeout(this.reconnectTimeout);
        this.unregisterEvent(WS_EVENT.OPEN, this.onReconnect);

        this.reconnectCount = 0;
        this.reconnectTimeout = null;
      }
    };

    getReconnectTime = () => {
      if (this.reconnectCount < CONFIG.RECONNECT_BREAK_POINT) {
        return CONFIG.RECONNECT_SHORT_INTERVAL;
      }

      return CONFIG.RECONNECT_LONG_INTERVAL;
    };
  };
