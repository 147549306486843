import _ from 'lodash';
import qs from 'qs';

import { config } from 'core/config';
import { localStorage } from 'core/storages/local';
import { userService } from 'core/user';

import {
  CATEGORIES,
  INQUIRIES_CATEGORY_STORAGE_KEY,
  INITIAL_SUB_CATEGORIES_ID,
  CATEGORY,
  CATEGORY_OPTIONS_MAPPER,
} from 'core/constants/inquiries/category';

import { TYPENAME } from 'core/constants/typename';

const MAPPED_CATEGORIES = CATEGORIES.map(category => ({
  __typename: TYPENAME.CATEGORY,
  ...category,
  keyMapper: `${category.key}.${category.keyMapper}`,
  permissions: {
    ...category.permissions,
    __typename: TYPENAME.CATEGORY_PERMISSION,
  },
}));

const BUILDED_OPTIONS = CATEGORY_OPTIONS_MAPPER;

const get = () => [...MAPPED_CATEGORIES];

const buildCategoryOptions = ({ user }) => {
  const loggedUserParams = {
    clientFilterParams: {
      assigned: {
        id: user.id,
      },
    },
    query: {
      options: {
        variables: {
          filters: {
            assignedTo: user.id,
          },
        },
      },
    },
  };

  // add logged user id to filter and client filter options
  BUILDED_OPTIONS[CATEGORY.ASSIGNED_TO_ME.key].list = BUILDED_OPTIONS[CATEGORY.ASSIGNED_TO_ME.key].list.map(
    listParams => _.merge(listParams, loggedUserParams),
  );

  // choose between agent and admin list for all category
  if (userService.isAgent(user)) {
    BUILDED_OPTIONS[CATEGORY.ALL.key].list = BUILDED_OPTIONS[CATEGORY.ALL.key].agentList;

    BUILDED_OPTIONS[CATEGORY.ALL.key].list[0] = _.merge(BUILDED_OPTIONS[CATEGORY.ALL.key].list[0], loggedUserParams);

    BUILDED_OPTIONS[CATEGORY.COMPLETED.key].list = BUILDED_OPTIONS[CATEGORY.COMPLETED.key].callCenterList;
  } else if (userService.isCallCenterAdmin(user)) {
    BUILDED_OPTIONS[CATEGORY.ALL.key].list = BUILDED_OPTIONS[CATEGORY.ALL.key].callCenterAdminList;
    BUILDED_OPTIONS[CATEGORY.COMPLETED.key].list = BUILDED_OPTIONS[CATEGORY.COMPLETED.key].callCenterList;
  } else if (userService.isAdmin(user)) {
    BUILDED_OPTIONS[CATEGORY.ALL.key].list = BUILDED_OPTIONS[CATEGORY.ALL.key].adminList;
    BUILDED_OPTIONS[CATEGORY.COMPLETED.key].list = BUILDED_OPTIONS[CATEGORY.COMPLETED.key].dealerList;
  } else if (userService.isUser(user)) {
    BUILDED_OPTIONS[CATEGORY.ALL.key].list = BUILDED_OPTIONS[CATEGORY.ALL.key].userList;
    BUILDED_OPTIONS[CATEGORY.COMPLETED.key].list = BUILDED_OPTIONS[CATEGORY.COMPLETED.key].dealerList;
  }

  BUILDED_OPTIONS[CATEGORY.ALL.key].list = BUILDED_OPTIONS[CATEGORY.ALL.key].list.map(listParams =>
    listParams.applyLoggedParams ? _.merge(listParams, loggedUserParams) : listParams,
  );

  return BUILDED_OPTIONS;
};

const isArchived = category => category.id === CATEGORY.ARCHIVED.id || _.inRange(category.id, 900, 999);

const isCompleted = category => category.id === CATEGORY.COMPLETED.id || _.inRange(category.id, 800, 899);

const getReloadInterval = ({ category }) => {
  if (isArchived(category) || isCompleted(category)) {
    return config.archivedInquiriesReloadIntervalMs;
  }

  return config.activeInquiriesReloadIntervalMs;
};

const flat = categories => categories.reduce((accumulator, category) => [category, ...accumulator], []);

const getParentCategoryId = id => Math.floor(id / INITIAL_SUB_CATEGORIES_ID);

const isCategoryChanged = (leftId, rightId) => leftId !== rightId;

const find = (categories, id, userId) => {
  const category = _.find(flat(categories), { id });
  const parentCategory = _.find(flat(categories), { id: getParentCategoryId(id) });

  const categoryOptions = getOptions(category.key, userId);
  const parentCategoryOptions = parentCategory && getOptions(parentCategory.key, userId);

  return {
    ...category,
    ..._.merge({}, parentCategoryOptions, categoryOptions),
  };
};

const mapCount = (categories, inquiriesStructure, additionFilterObject = {}) =>
  categories.map(category => ({
    ...category,
    count: category.skipStats ? null : _.get(inquiriesStructure, category.keyMapper),
  }));

const filterVisible = (categories, user) =>
  categories.filter(category => userService.hasPermissions({ user, ...category.permissions }));

const mapActive = (categories, id) =>
  categories.map(category => {
    const isActiveCategory = category.id === id;

    return {
      ...category,
      active: isActiveCategory,
    };
  });

const update = (categories, inquiriesStructure) =>
  _.sortBy(
    [
      ...mapCount(categories, inquiriesStructure).map(category => ({
        ...category,
      })),
    ],
    'id',
  );

const save = categoryId => localStorage.set(INQUIRIES_CATEGORY_STORAGE_KEY, categoryId);

const getSavedId = () => {
  const savedId = +localStorage.get(INQUIRIES_CATEGORY_STORAGE_KEY) || CATEGORY.ALL.id;
  const hasCategoryById = !!CATEGORIES.find(({ id }) => savedId === id);
  return hasCategoryById ? savedId : Math.floor(savedId / INITIAL_SUB_CATEGORIES_ID);
};

const getDefaultId = () => {
  // get saved locally
  const savedId = getSavedId();

  // get category from qs
  const categoryKey = qs.parse(window.location.search, { ignoreQueryPrefix: true }).category;

  // if no in qs then return saved in storage
  if (!categoryKey) {
    return savedId;
  }

  const category = _.find(flat(MAPPED_CATEGORIES), {
    key: categoryKey,
  });

  // if no such category then return saved in storage
  if (!category) {
    return savedId;
  }

  // return id of category in qs
  return category.id;
};

const getOptions = (categoryKey, userId) => {
  const options =
    {
      ...CATEGORY_OPTIONS_MAPPER[categoryKey],
    } || {};

  return options;
};

const getStructureVariables = ({ categories, user }) =>
  categories.reduce((acc, category) => {
    if (userService.isAgent(user)) {
      // no counters for agents
      return {
        [category.includeStructureKey]: false,
        ...acc,
      };
    }

    if (category.includeStructureKey) {
      return {
        [category.includeStructureKey]: true,
        ...acc,
      };
    }

    return acc;
  }, {});

export default {
  get,
  mapCount,
  find,
  update,
  save,
  getSavedId,
  getDefaultId,
  isArchived,
  isCompleted,
  getOptions,
  mapActive,
  filterVisible,
  buildCategoryOptions,
  isCategoryChanged,
  getStructureVariables,
  getReloadInterval,
};
