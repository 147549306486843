import { REGEXP } from 'core/constants/regexp';

const config = {};

const parseConfig = properties => {
  properties.split('\n').forEach(line => {
    const trimmedLine = line.trim();

    // skip comments
    if (trimmedLine.charAt(0) === '#') {
      return;
    }

    const index = trimmedLine.indexOf('=');
    if (index === -1) return;

    const key = trimmedLine.substr(0, index);
    let value = trimmedLine.substr(index + 1);

    switch (value) {
      case 'true':
      case 'yes': {
        value = true;
        break;
      }
      case 'false':
      case 'no':
      case 'null': {
        value = false;
        break;
      }
      default:
        break;
    }

    // if we have string that matches number
    if (value && value.match && value.match(REGEXP.NUMBER)) {
      value = +value;
    }

    config[key] = value;
  });

  return config;
};

export { parseConfig };
export default config;
