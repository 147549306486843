export const USERS_LIST_SORTING_KEYS = {
  NAME: 'name',
  ROLE: 'userType',
  CONTACT: 'email',
  ASSIGNED_INQUIRIES: 'assignedInquiries',
  AUTO_ASSIGN_ROTATION: 'isAutoAssignmentEnabled',
  RESPONSE: 'avgResponseTime',
  STATUS: 'isActive',
};

/*
averageEngagementTime: null
current: 0
medianResponseTimeInitial: null
medianResponseTimeOverall: null
today: 0
updatedAt: 1608544601052,
week
*/

export const USERS_PERFORMANCE_SORTING_KEYS = {
  NAME: 'userName',
  CURRENT: 'current',
  PREVIOUS_24_HOURS: 'previous_24_hours',
  THIS_WEEK: 'week',
  MEDIAN_RESPONSE_TIME_INITIAL: 'medianResponseTimeInitial',
  MEDIAN_RESPONSE_TIME_OVERALL: 'medianResponseTimeOverall',
  AVERAGE_ENGAGEMENT_TIME: 'averageEngagementTime',
  UPDATED_AT: 'updatedAt',
};

export const USERS_PERFOMANCE_DATE_RANGE_OPTIONS = [
  { label: 'Last 3 days', value: 3, default: false },
  { label: 'Last 7 days', value: 7, default: true },
  { label: 'Last 14 days', value: 14, default: false },
  { label: 'Last 30 days', value: 30, default: false },
];

export const USERS_PERFORMANCE_REQUIRED_FOR_VISIBILITY_DATA_KEYS = [
  'current',
  'today',
  'week',
  'medianResponseTimeInitial',
  'medianResponseTimeOverall',
  'averageEngagementTime',
];

export const USERS_LIST_TABLE_HEADER_ID = {
  NAME: 'userName',
  ROLE: 'userRole',
  CONTACT: 'userContact',
  LEAD_TYPE: 'userLeadType',
  ASSIGNED_INQUIRIES: 'assignedInquiriesCount',
  ASSIGNED_TO: 'assignedTo',
  AUTO_ASSIGN_ROTATION: 'autoAssignRotation',
  RESPONSE: 'responseTime',
  STATUS: 'userStatus',
};
