import { updateCustomer } from './utils';

export default (obj, { input: { inquiryId, msg: nextCustomer } }, { cache, getCacheKey }) => {
  const id = getCacheKey({ __typename: 'CustomerDto', id: nextCustomer.id });

  updateCustomer({
    customer: nextCustomer,
    id,
    cache,
  });

  return null;
};
