import PropTypes from 'prop-types';

export default PropTypes.shape({
  key: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.bool.isRequired,
    PropTypes.number.isRequired,
    PropTypes.shape({}).isRequired,
  ]),
  userName: PropTypes.string,
  isOnline: PropTypes.bool,
});
