export const MEDIA_QUERY = {
  PWA: '(display-mode: standalone), (display-mode: minimal-ui), (display-mode: fullscreen)',
  MOBILE: '(max-width: 767px)',
  TABLET: '(min-width: 768px) and (max-width: 991px)',
  MOBILE_OR_TABLET: '(max-width: 991px)',
  TABLET_OR_DESKTOP: '(min-width: 768px)',
  DESKTOP: '(min-width: 992px)',
  DESKTOP_LARGE: '(min-width: 1280px)',
};

export const MEDIA_QUERY_KEY = {
  PWA: 'PWA',
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  MOBILE_OR_TABLET: 'MOBILE_OR_TABLET',
  TABLET_OR_DESKTOP: 'TABLET_OR_DESKTOP',
  DESKTOP: 'DESKTOP',
  DESKTOP_LARGE: 'DESKTOP_LARGE',
};
