import React from 'react';

import { PWA_FEATURES } from 'core/constants/pwa-features';
import { pwaService } from 'core/pwa';
import { COOKIE_KEYS } from 'core/constants/cookie';
import { cookieService } from 'core/cookie';
import { withLoggedUser } from 'core/hocs/logged-user';

class PwaOrchestratorContainer extends React.PureComponent {
  componentDidMount() {
    const { id: loggedUserId } = this.props.loggedUser;

    if (pwaService.isPwaOpened()) {
      pwaService.trackEvent({
        action: 'pageview',
        label: `${loggedUserId}`,
      });
    }

    if (pwaService.isSupported(PWA_FEATURES.SERVICE_WORKER)) {
      pwaService.trackEvent({
        action: 'supported',
        label: `${loggedUserId}`,
      });
    } else {
      pwaService.trackEvent({
        action: 'unsupported',
        label: `${loggedUserId}`,
      });
    }

    window.addEventListener('appinstalled', this.onAppInstalled);
    window.addEventListener('beforeinstallprompt', this.onBeforeInstall);
  }

  componentWillUnmount() {
    window.removeEventListener('appinstalled', this.onAppInstalled);
    window.removeEventListener('beforeinstallprompt', this.onBeforeInstall);
  }

  onAppInstalled = () => {
    const { id: loggedUserId } = this.props.loggedUser;

    cookieService.set(COOKIE_KEYS.CARCODE_PWA_INSTALLED, true);

    pwaService.trackEvent({
      action: 'install_success',
      label: `${loggedUserId}`,
    });
  };

  onBeforeInstall = event => {
    const { id: loggedUserId } = this.props.loggedUser;

    // Prevent the mini-infobar from appearing on mobile
    event.preventDefault();

    event.userChoice.then(choiceResult => {
      const { outcome } = choiceResult;
      switch (outcome) {
        case 'accepted':
          cookieService.set(COOKIE_KEYS.CARCODE_PWA_INSTALLED, true);

          pwaService.trackEvent({
            action: 'install_accepted',
            label: `${loggedUserId}`,
          });
          break;
        case 'dismissed':
          cookieService.set(COOKIE_KEYS.CARCODE_PWA_INSTALLED, false);

          pwaService.trackEvent({
            action: 'install_dismissed',
            label: `${loggedUserId}`,
          });
          break;
        default:
          pwaService.trackEvent({
            action: 'install_error',
            label: `${loggedUserId}`,
          });
          break;
      }
    });
  };

  render() {
    return null;
  }
}

export { PwaOrchestratorContainer };

export default withLoggedUser(PwaOrchestratorContainer);
