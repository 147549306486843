import PreconfiguredResponse from './preconfigured-response';
import PreconfiguredResponseCategory from './preconfigured-response-category';
import PreconfiguredResponsesGroupedByCategories from './preconfigured-responses-grouped-by-categories';
import Suggestion from './suggestion';
import QuickReply from './quick-reply';

export default {
  PreconfiguredResponse,
  PreconfiguredResponseCategory,
  PreconfiguredResponsesGroupedByCategories,
  Suggestion,
  QuickReply,
};
