import PropTypes from 'prop-types';

export default PropTypes.shape({
  condition: PropTypes.string,
  imageLink: PropTypes.string,
  make: PropTypes.string,
  mileage: PropTypes.number,
  model: PropTypes.string,
  price: PropTypes.number,
  priceSelected: PropTypes.number,
  priceLow: PropTypes.number,
  priceHigh: PropTypes.number,
  trim: PropTypes.string,
  year: PropTypes.number,
  offerType: PropTypes.string,
});
