import React from 'react';
import PropTypes from 'prop-types';

import { Logo } from 'shared-components/logo';
import { Text } from 'shared-components/text';
import { FatalError } from 'shared-components/fatal-error';
import { RouterLink } from 'shared-components/router-link';

import { Button } from 'reactstrap';

const ErrorPage = props => {
  const LinkTag = props.link ? 'a' : 'Link';

  return (
    <FatalError {...props}>
      <header data-test="cc-error-page">
        {props.disableNavigation ? (
          <Logo />
        ) : (
          <RouterLink to="/inquiries" href="/inquiries" className="d-block">
            <Logo />
          </RouterLink>
        )}
      </header>
      {props.showButton && (
        <LinkTag to={props.link} href={props.link} target={props.linkTarget} className="no-decoration">
          <Button color="primary" className="rounded-oval px-5">
            <Text>{props.linkText}</Text>
          </Button>
        </LinkTag>
      )}
    </FatalError>
  );
};

ErrorPage.propTypes = {
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      size: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
  disableNavigation: PropTypes.bool,
  link: PropTypes.string,
  linkText: PropTypes.string,
  linkTarget: PropTypes.string,
};

ErrorPage.defaultProps = {
  showButton: true,
  disableNavigation: false,
  link: '/inquiries',
  linkText: 'Go to Homepage',
  linkTarget: '_self',
};

export default ErrorPage;
