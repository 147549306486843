import React from 'react';
import PropTypes from 'prop-types';

import { PAGES } from 'core/constants/pages';

import { Text } from 'shared-components/text';
import { RouterLink } from 'shared-components/router-link';

const LogoutLink = ({ className }) => (
  <RouterLink title="Log out" to={PAGES.LOGOUT} href={PAGES.LOGOUT} className={className}>
    <Text icon="icon-exit">Log out</Text>
  </RouterLink>
);

LogoutLink.propTypes = {
  className: PropTypes.string,
};

LogoutLink.defaultProps = {
  className: '',
};

export default LogoutLink;
