import { gql } from '@apollo/client';

export const ACTIVE_CATEGORY_ID_QUERY = gql`
  {
    activeCategoryId @client
  }
`;

export const CATEGORY_QUERY = gql`
  query GetCategory($id: Long!) {
    getCategory(id: $id) @client {
      id
      key
    }
  }
`;

export const INQUIRIES_CATEGORIES_QUERY = gql`
  query GetInquiriesCategories {
    categories @client {
      id
      name
      description
      key
      keyMapper
      includeStructureKey
      permissions {
        some
        not
      }
    }
  }
`;
