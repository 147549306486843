import { INQUIRIES_FILTER_KEY } from 'core/constants/inquiries/filter';
import { INQUIRIES_CATEGORY_STORAGE_KEY, INQUIRIES_ORDER_STORAGE_KEY } from 'core/constants/inquiries/category';
import { SORTABLE_KEY } from 'core/constants/inquiries/sortable';
import { SESSION_LOG_KEYS } from 'core/constants/session-log';

export const LAST_ACTION_KEY = 'lastAction';

export const REMOVABLE_ON_LOGIN_KEYS = [
  INQUIRIES_FILTER_KEY,
  INQUIRIES_CATEGORY_STORAGE_KEY,
  SESSION_LOG_KEYS.STORAGE,
  LAST_ACTION_KEY,
  ...Object.values(SORTABLE_KEY),
  ...Object.values(INQUIRIES_ORDER_STORAGE_KEY),
];
