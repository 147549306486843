import PropTypes from 'prop-types';

import WorkingHours from './working-hours';

export default PropTypes.shape({
  appraisalPhoneNumber: PropTypes.string,
  activeBypassDNCR: PropTypes.bool,
  city: PropTypes.string,
  creditAppEnabled: PropTypes.bool,
  edmundsPhoneNumber: PropTypes.string,
  edmundsRooftopId: PropTypes.number,
  id: PropTypes.number,
  name: PropTypes.string,
  salesPhoneNumber: PropTypes.string,
  servicePhoneNumber: PropTypes.string,
  state: PropTypes.string,
  street: PropTypes.string,
  usedPhoneNumber: PropTypes.string,
  websiteUrl: PropTypes.string,
  zip: PropTypes.string,
  sales: WorkingHours,
  service: WorkingHours,
  isMmsDisabled: PropTypes.bool,
  appraisal: PropTypes.bool,
});
