import React from 'react';
import PropTypes from 'prop-types';

import { pure } from 'recompose';
import classNames from 'classnames';
import { Loading } from 'shared-components/loading';

import { Button } from 'reactstrap';

import './loading-button.styles.scss';

const LoadingButton = ({
  isLoading,
  className,
  children,
  isDisabled,
  loadingIndicatorPosition,
  loadingColor,
  ...otherProps
}) => (
  <Button
    className={classNames(className, 'loading-button', {
      'loading-button--loading': isLoading,
    })}
    {...otherProps}
    disabled={isLoading || isDisabled}
  >
    {children}
    {isLoading && (
      <Loading
        size="small"
        color={loadingColor}
        className={classNames('loading-button-indicator', `loading-button-indicator--${loadingIndicatorPosition}`)}
      />
    )}
  </Button>
);

LoadingButton.defaultProps = {
  isLoading: false,
  isDisabled: false,
  loadingIndicatorPosition: 'right',
  className: '',
  loadingColor: 'white',
};

LoadingButton.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  loadingIndicatorPosition: PropTypes.string,
  loadingColor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default pure(LoadingButton);
