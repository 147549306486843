import { INVENTORY_ACTIVE_CLIENT_QUERY } from 'core/queries/inventory-search';

export default (obj, { inquiryId }, { cache }) => {
  try {
    const result = cache.readQuery({
      query: INVENTORY_ACTIVE_CLIENT_QUERY,
      variables: {
        inquiryId,
      },
    });

    return result;
  } catch (e) {
    return null;
  }
};
