import { gql } from '@apollo/client';

const GET_USER_PROFILE_QUERY = gql`
  query GetUserProfileQuery($userId: Long!) {
    getUserProfile(userId: $userId) {
      id
      photoLink
      name
      email
      phone
      userType
      isActive
      inquiryCapacity
      isAdmin
      title
      schedule {
        friday
        saturday
        sunday
        monday
        tuesday
        wednesday
        thursday
      }
      isSales
      isService
      isSkipLeads
      isEmailTextTranscript
      disableAllAccountsAppNotifications
      includeInCalloutPopup
      notificationPreference
      hasPushNotificationsSubscription
      pushNotificationsEnabled
      notNotifyDealerOff
      notNotifyUserOff
      disableNewLeadsNotification
      disableExistingLeadsNotification
      dealers {
        name
        id
      }
    }
  }
`;

export { GET_USER_PROFILE_QUERY };
