import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';

import { UserPropTypes } from 'core/prop-types';

import { withLoggedUser } from 'core/hocs/logged-user';
import { withReadOnly } from 'core/hocs/read-only';

import { userService } from 'core/user';
import { navigationService } from 'core/navigation';

import { NAVIGATION_ITEMS } from 'core/constants/navigation';

import { MediaQuery } from 'shared-components/media-query';

import Navigation from './navigation.presentation';

class NavigationContainer extends React.Component {
  static propTypes = {
    handleCollapse: PropTypes.func,
    handleToggle: PropTypes.func,
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    loggedUser: UserPropTypes.LoggedUser.isRequired,
    readOnly: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    handleCollapse: null,
    handleToggle: () => {},
    className: null,
    isOpen: false,
  };

  state = {
    links: NAVIGATION_ITEMS,
    collapsed: userService.isAgent(this.props.loggedUser),
  };

  handleCollapse = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));

    if (this.props.handleCollapse) {
      this.props.handleCollapse();
    }
  };

  render() {
    const { collapsed, links } = this.state;
    const { loggedUser, readOnly, className, isOpen, handleToggle } = this.props;
    const filteredLinks = navigationService.filterVisibleLinks({ links, loggedUser });
    const isCallCenterUser = userService.hasCallCenterPermissions({ user: loggedUser });
    const flexFromValue = isCallCenterUser ? '6rem' : '4.5rem'; // extra width for call center users is to accommodate the live session log counter

    return (
      <>
        <MediaQuery query="xlg-only">
          <Navigation
            links={filteredLinks}
            collapsed={collapsed}
            isOpen={isOpen}
            className={className}
            handleCollapse={this.handleCollapse}
            handleToggle={handleToggle}
            loggedUser={loggedUser}
            width="17.5rem"
            flexFromValue={flexFromValue}
            showLiveSessionLog={isCallCenterUser}
            readOnly={readOnly}
          />
        </MediaQuery>
        <MediaQuery query="lg-only">
          <Navigation
            links={filteredLinks}
            collapsed={collapsed}
            isOpen={isOpen}
            className={className}
            handleCollapse={this.handleCollapse}
            handleToggle={handleToggle}
            loggedUser={loggedUser}
            width="15rem"
            flexFromValue={flexFromValue}
            showLiveSessionLog={isCallCenterUser}
            readOnly={readOnly}
          />
        </MediaQuery>
        <MediaQuery query="md-down">
          <Navigation
            links={filteredLinks}
            collapsed={false}
            isOpen={isOpen}
            className={className}
            handleCollapse={this.handleCollapse}
            handleToggle={handleToggle}
            loggedUser={loggedUser}
            width="15rem"
            flexFromValue={flexFromValue}
            showLiveSessionLog={isCallCenterUser}
            readOnly={readOnly}
          />
        </MediaQuery>
      </>
    );
  }
}

export { NavigationContainer };

export default compose(withLoggedUser, withReadOnly)(NavigationContainer);
