import PropTypes from 'prop-types';

import { INVENTORY_IDENTIFIER_TYPES } from 'core/constants/inventory-search';

import { VehiclePropTypes, DealerPropTypes } from 'core/prop-types';

export default PropTypes.shape({
  edmundsRooftopId: PropTypes.number,
  type: PropTypes.oneOf(Object.values(INVENTORY_IDENTIFIER_TYPES)),
  value: PropTypes.string,
  vehicle: VehiclePropTypes.Vehicle,
  vehicleDealer: DealerPropTypes.Dealer,
});
