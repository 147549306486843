import React from 'react';

import { AccessibleModal } from 'shared-components/accessible-modal';

import Modal from './modal.presentation';

class ModalContainer extends React.Component {
  modalRef = React.createRef();

  render() {
    return (
      <AccessibleModal innerRef={this.modalRef}>
        <Modal {...this.props} innerRef={this.modalRef} />
      </AccessibleModal>
    );
  }
}

export default ModalContainer;
