import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { PoseGroup } from 'react-pose';

import { POSITION } from 'core/constants/sidebar';

import { SlideAndCollapse } from 'shared-components/animated';

const Sidebar = ({ open, className, handleBackdropClick, position, children }) => (
  <div
    className={classNames('cc-sidebar', {
      'cc-sidebar--open': open,
    })}
  >
    <div onClick={handleBackdropClick} className="cc-sidebar__backdrop" role="button" tabIndex="0" />
    <PoseGroup>
      {open && (
        <SlideAndCollapse
          key="sidebar"
          xFrom={position === POSITION.RIGHT ? '100%' : '-100%'}
          xTo="0"
          className={classNames(
            'cc-sidebar__animation-container d-inline-block bg-white',
            { 'cc-sidebar__animation-container--right': position === POSITION.RIGHT },
            className,
          )}
        >
          <div className="cc-sidebar__content">{children}</div>
        </SlideAndCollapse>
      )}
    </PoseGroup>
  </div>
);

Sidebar.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  handleBackdropClick: PropTypes.func.isRequired,
  position: PropTypes.oneOf(Object.values(POSITION)),
};

Sidebar.defaultProps = {
  className: null,
  open: false,
  position: POSITION.LEFT,
};

export default Sidebar;
