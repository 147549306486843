import PropTypes from 'prop-types';

import LinkPreview from './link-preview';

export default PropTypes.shape({
  fileName: PropTypes.string,
  id: PropTypes.number,
  linkPreviews: PropTypes.arrayOf(LinkPreview),
  niceName: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
});
