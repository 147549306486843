import { userService } from 'core/user';

export const KEYBOARD_SHORTCUTS_HELP = [
  {
    title: 'Navigation',
    items: [
      {
        shortcutKey: 'SELECT_INQUIRY_N',
        description: 'Select an Inquiry',
      },
      {
        shortcutKey: 'NEXT_INQUIRY',
        description: 'Next Inquiry in Queue',
      },
      {
        shortcutKey: 'PREVIOUS_INQUIRY',
        description: 'Previous Inquiry in Queue',
      },
      {
        shortcutKey: 'FOCUS_MESSAGE_BOX',
        description: 'Jump to Message Box',
      },
    ],
  },
  {
    title: 'Actions',
    items: [
      {
        shortcutKey: 'ASSIGN_INQUIRY_SELF',
        description: 'Assign Inquiry to Self',
      },
      {
        shortcutKey: 'TOGGLE_ASSIGN_INQUIRY_MODAL',
        description: 'Assign Inquiry to Other',
      },
      {
        shortcutKey: 'TOGGLE_HANDOFF_FORM',
        description: 'Open Handoff Form',
        isHidden: user => !(userService.isCallCenterAdmin(user) || userService.isAgent(user)),
      },
      {
        shortcutKey: 'SEND_HANDOFF',
        description: 'Send Handoff',
        isHidden: user => !(userService.isCallCenterAdmin(user) || userService.isAgent(user)),
      },
      {
        shortcutKey: 'DISMISS_DIALOG',
        description: 'Dismiss dialogs',
      },
    ],
  },
];

export const KEYBOARD_SHORTCUTS_MAP_MAC = {
  SELECT_INQUIRY_N: 'command+option+1,2,...,9', // this is a dummy shortcut and used just for keyboard help screen
  SELECT_INQUIRY_1: 'command+option+1',
  SELECT_INQUIRY_2: 'command+option+2',
  SELECT_INQUIRY_3: 'command+option+3',
  SELECT_INQUIRY_4: 'command+option+4',
  SELECT_INQUIRY_5: 'command+option+5',
  SELECT_INQUIRY_6: 'command+option+6',
  SELECT_INQUIRY_7: 'command+option+7',
  SELECT_INQUIRY_8: 'command+option+8',
  SELECT_INQUIRY_9: 'command+option+9',
  NEXT_INQUIRY: 'command+option+down',
  PREVIOUS_INQUIRY: 'command+option+up',
  FOCUS_MESSAGE_BOX: 'command+option+r',
  ASSIGN_INQUIRY_SELF: 'command+option+a',
  TOGGLE_ASSIGN_INQUIRY_MODAL: 'command+e',
  TOGGLE_HANDOFF_FORM: 'command+h',
  SEND_HANDOFF: 'command+return',
  SHOW_HELP: 'command+/',
  DISMISS_DIALOG: 'esc', // this one is handled by reactstrap modals, no need to register handlers
};

export const COMMANDS_DELIMITER = '+';

export const KEYBOARD_SHORTCUTS_MAP_WINDOWS = {
  SELECT_INQUIRY_N: 'ctrl+shift+1,2,...,9', // this is a dummy shortcut and used just for keyboard help screen
  SELECT_INQUIRY_1: 'ctrl+shift+1',
  SELECT_INQUIRY_2: 'ctrl+shift+2',
  SELECT_INQUIRY_3: 'ctrl+shift+3',
  SELECT_INQUIRY_4: 'ctrl+shift+4',
  SELECT_INQUIRY_5: 'ctrl+shift+5',
  SELECT_INQUIRY_6: 'ctrl+shift+6',
  SELECT_INQUIRY_7: 'ctrl+shift+7',
  SELECT_INQUIRY_8: 'ctrl+shift+8',
  SELECT_INQUIRY_9: 'ctrl+shift+9',
  NEXT_INQUIRY: 'ctrl+shift+down',
  PREVIOUS_INQUIRY: 'ctrl+shift+up',
  FOCUS_MESSAGE_BOX: 'ctrl+shift+r',
  ASSIGN_INQUIRY_SELF: 'ctrl+shift+a',
  TOGGLE_ASSIGN_INQUIRY_MODAL: 'ctrl+e',
  TOGGLE_HANDOFF_FORM: 'ctrl+h',
  SEND_HANDOFF: 'ctrl+return',
  SHOW_HELP: 'ctrl+/',
  DISMISS_DIALOG: 'esc', // this one is handled by reactstrap modals, no need to register handlers
};
