import React, { useState, useCallback } from 'react';
import BurgerMenu from './burger-menu.presentation';

const BurgerMenuContainer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleMenu = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  return <BurgerMenu isOpen={isOpen} handleToggleMenu={handleToggleMenu} />;
};

export default BurgerMenuContainer;
