import { INQUIRIES_LIST_INQUIRY_FRAGMENT } from 'core/queries/inquiries';
import { TYPENAME } from 'core/constants/typename';

import inquiryConverter from './graphql-inquiry-converter';
import { updateInquiry, addNewInquiryToRelatedLists, shouldAddNewInquiry } from './utils';

export default (obj, { input: { inquiry, user, type } }, { cache, getCacheKey }) => {
  let convertedInquiry = inquiryConverter(inquiry);
  const id = getCacheKey({ __typename: TYPENAME.INQUIRY, id: convertedInquiry.id });
  const cachedInquiry = cache.readFragment({
    id,
    fragment: INQUIRIES_LIST_INQUIRY_FRAGMENT,
    fragmentName: 'InquiriesListInquiry',
  });

  if (cachedInquiry) {
    convertedInquiry = updateInquiry({
      cachedInquiry,
      nextInquiry: convertedInquiry,
      loggedUser: user,
      cache,
      id,
    });
  }

  if (shouldAddNewInquiry({ inquiry, user })) {
    addNewInquiryToRelatedLists({
      inquiry: convertedInquiry,
      loggedUser: user,
      cache,
    });
  }

  return null;
};
