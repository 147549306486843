import PropTypes from 'prop-types';

export default PropTypes.shape({
  baseMsrp: PropTypes.number,
  condition: PropTypes.string,
  displayPrice: PropTypes.number,
  exteriorColorB: PropTypes.number,
  exteriorColorG: PropTypes.number,
  exteriorColorR: PropTypes.number,
  make: PropTypes.string,
  model: PropTypes.string,
  stock: PropTypes.string,
  totalMsrp: PropTypes.number,
  type: PropTypes.string,
  vin: PropTypes.string,
  year: PropTypes.number,
});
