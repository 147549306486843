import PropTypes from 'prop-types';

export default PropTypes.shape({
  make: PropTypes.string,
  model: PropTypes.string,
  type: PropTypes.string,
  vin: PropTypes.string,
  year: PropTypes.number,
  interiorColor: PropTypes.string,
  exteriorColor: PropTypes.string,
  defaultPhotoUrl: PropTypes.string,
});
