const isHandedOffFromCurrentDealer = (dealerAssignment, user) => {
  const {
    from: { id: fromId },
    to: { id: toId },
  } = dealerAssignment;
  const {
    currentDealer: { id: dealerId },
  } = user;

  return fromId === dealerId && toId !== dealerId;
};

export default {
  isHandedOffFromCurrentDealer,
};
