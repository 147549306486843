import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.number,
  contactPhoneNumber: PropTypes.string,
  email: PropTypes.string,
  chatterSessionId: PropTypes.string,
  cognitoId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  locale: PropTypes.string,
  name: PropTypes.string,
  phoneNumber: PropTypes.string,
  photoLink: PropTypes.string,
  timeZone: PropTypes.string,
  visitorId: PropTypes.string,
  status: PropTypes.string,
});
