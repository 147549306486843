import _ from 'lodash';

import { localStorage } from 'core/storages/local';

import { INQUIRY_MESSAGE_KEY } from 'core/constants/inquiry';
import { TYPENAME } from 'core/constants/typename';

const getMessage = (draftMessages = [], userId, inquiryId) => {
  const draftMessageData = _.find(draftMessages, { userId, inquiryId });
  return draftMessageData ? draftMessageData.message : '';
};

const getMessagesFromStorage = () => {
  const storedMessages = localStorage.get(INQUIRY_MESSAGE_KEY);

  if (!storedMessages) {
    return [];
  }

  const messages = [];

  return Object.keys(storedMessages).reduce((acc, userId) => {
    const userMessages = Object.keys(storedMessages[userId]).map(inquiryId => ({
      userId: +userId,
      inquiryId: +inquiryId,
      message: storedMessages[userId][inquiryId],
      __typename: TYPENAME.DRAFT_MESSAGE,
    }));
    return [...acc, ...userMessages];
  }, messages);
};

const addMessage = (messages, newMessage) => {
  const messagesInquiry = (messages && messages[newMessage.userId]) || {};

  return {
    ...messages,
    [newMessage.userId]: {
      ...messagesInquiry,
      [newMessage.inquiryId]: newMessage.message,
    },
  };
};

const removeMessage = (messages, newMessage) => {
  const { userId, inquiryId } = newMessage;

  if (_.isEmpty(messages) || !messages[userId]) {
    return messages;
  }

  const newMessages = _.cloneDeep(messages);
  delete newMessages[userId][inquiryId];

  if (_.isEmpty(newMessages[userId])) {
    delete newMessages[userId];
  }

  return newMessages;
};

const saveMessageToStorage = newMessage => {
  const messages = localStorage.get(INQUIRY_MESSAGE_KEY);
  let newMessages;

  if (newMessage.message && newMessage.message.trim()) {
    newMessages = addMessage(messages, newMessage);
  } else {
    if (!_.has(messages, `${newMessage.userId}.${newMessage.inquiryId}`)) {
      return;
    }
    newMessages = removeMessage(messages, newMessage);
  }

  localStorage.set(INQUIRY_MESSAGE_KEY, newMessages);
};

export default {
  getMessage,
  getMessagesFromStorage,
  saveMessageToStorage,
};
