import { gql } from '@apollo/client';

import { INQUIRY_BUTTONS_BAR_FRAGMENT } from 'core/queries/buttons-bar';

const LOGGED_USER_DEALER_FRAGMENT = gql`
  fragment LoggedUserDealer on DealerDtoCallCenter {
    id
    name
    phoneNumber
    preconfiguredResponsesEnabled
    edmundsRooftopId
    activeBypassDNCR
    servicePhoneNumber
    isServiceTextingEnabled
    partnerProgram
    appraisal
    shiftDigital
    hideCustomerPhone
    allowReassignByRegularUsers
    sales {
      crmEmail
    }
    service {
      crmEmail
    }
    ...InquiryButtonsBarProperties
  }
  ${INQUIRY_BUTTONS_BAR_FRAGMENT}
`;

const LOGGED_USER_QUERY = gql`
  query UserByTokenQuery($token: String!) {
    getUserByToken(token: $token) {
      id
      name
      email
      permissions
      autoAssignmentEnabled
      isSales
      isService
      photoLink
      currentDealer {
        ...LoggedUserDealer
      }
      dealers {
        ...LoggedUserDealer
      }
    }
  }
  ${LOGGED_USER_DEALER_FRAGMENT}
`;

export { LOGGED_USER_QUERY };
