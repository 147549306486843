import React from 'react';

import { browserService } from 'core/browser';

import { ErrorPage } from 'pages/error-page';

const pageIcons = [
  {
    name: 'cog',
    size: '3rem',
  },
];

const currentBrowser = browserService.get();

const CookiesDisabledPage = () => (
  <ErrorPage
    title="Cookies are disabled"
    description="Make sure your cookies are enabled and try again"
    showButton={!!currentBrowser.cookieSupportLink}
    link={currentBrowser.cookieSupportLink}
    linkText={currentBrowser.cookieSupportLink ? 'Learn more' : ''}
    linkTarget="_blank"
    icons={pageIcons}
    disableNavigation
  />
);

export default CookiesDisabledPage;
