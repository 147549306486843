import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { secondsFormatter } from 'core/formatters/seconds';

import './live-session-log.styles.scss';

const LiveSessionLog = ({ className, minimized, pickups, handoffs, leads, sessionTime }) => {
  const formattedSessionTime = secondsFormatter.toHHMMSS(sessionTime);

  const logItemClass = 'd-flex align-items-center';
  const logItemValueClass = 'w-100 text-right font-size-sm';
  const logItemNameClass = classNames('w-100 text-left white-space-nowrap', {
    'font-size-sm': !minimized,
    'font-size-xs': minimized,
  });
  const sessionTimeClass = classNames('w-100 font-size-sm', {
    'text-right': !minimized,
    'text-left': minimized,
  });

  return (
    <div
      className={classNames(
        'live-session-log d-flex flex-column justify-content-between border rounded bg-white h-100',
        { 'p-1': !minimized, 'p-0_5': minimized },
        className,
      )}
    >
      <div className={logItemClass}>
        {!minimized && <span className={logItemNameClass}>Time elapsed</span>}
        <span className={sessionTimeClass}>{formattedSessionTime}</span>
      </div>
      <div className={logItemClass}>
        <span className={logItemNameClass}>Pickups</span>
        <span className={logItemValueClass}>{pickups}</span>
      </div>
      <div className={logItemClass}>
        <span className={logItemNameClass}>Handoffs</span>
        <span className={logItemValueClass}>{handoffs}</span>
      </div>
      <div className={logItemClass}>
        <span className={logItemNameClass}>Leads</span>
        <span className={logItemValueClass}>{leads}</span>
      </div>
    </div>
  );
};

LiveSessionLog.defaultProps = {
  className: null,
  minimized: false,
};

LiveSessionLog.propTypes = {
  className: PropTypes.string,
  minimized: PropTypes.bool,
  pickups: PropTypes.number.isRequired,
  handoffs: PropTypes.number.isRequired,
  leads: PropTypes.number.isRequired,
  sessionTime: PropTypes.number.isRequired,
};

export default LiveSessionLog;
