import { REMOVABLE_ON_LOGIN_KEYS } from 'core/constants/storage';
import { objectUtils } from 'core/utils/object';

const set = (key, value, storage) => {
  if (!key || !value || !storage) {
    return;
  }

  let stringifiedValue = value;

  if (objectUtils.isObject(value)) {
    stringifiedValue = JSON.stringify(value);
  }

  storage.setItem(key, stringifiedValue);
};

const get = (key, storage) => {
  if (!key || !storage) {
    return null;
  }

  let value = storage.getItem(key);

  if (!value) {
    return null;
  }

  // assume it is an object that has been stringified
  if (value[0] === '{' || value[0] === '[') {
    value = JSON.parse(value);
  }

  return value;
};

const remove = (key, storage) => {
  if (!key || !storage) {
    return;
  }

  storage.removeItem(key);
};

const destroy = () => {
  REMOVABLE_ON_LOGIN_KEYS.forEach(key => {
    remove(key, window.sessionStorage);
    remove(key, window.localStorage);
  });
};

export default {
  remove,
  get,
  set,
  destroy,
};
