import PropTypes from 'prop-types';

import { VehiclePropTypes } from 'core/prop-types';

import InventoryAttachmentCard from './inventory-attachment-card';
import WorkingHoursCard from './working-hours-card';
import UsedVehicleTmvCard from './used-vehicle-tmv-card';
import Attachment from './attachment';
import LinkPreview from './link-preview';

export default PropTypes.shape({
  attachments: PropTypes.arrayOf(Attachment),
  body: PropTypes.string,
  createdAt: PropTypes.number,
  failedAt: PropTypes.number,
  failureReason: PropTypes.string,
  id: PropTypes.number,
  inventoryAttachmentCards: PropTypes.arrayOf(InventoryAttachmentCard),
  workingHoursCard: WorkingHoursCard,
  linkPreviews: PropTypes.arrayOf(LinkPreview),
  readAt: PropTypes.number,
  senderName: PropTypes.string,
  sourceLabel: PropTypes.string,
  type: PropTypes.string,
  usedVehicleTMVCard: UsedVehicleTmvCard,
  vehicle: VehiclePropTypes.Vehicle,
  vehicleDealer: VehiclePropTypes.Vehicle,
});
