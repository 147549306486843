import PropTypes from 'prop-types';

import { QUICK_REPLY_TYPE } from 'core/constants/quick-replies';

export default PropTypes.shape({
  id: PropTypes.number,
  preconfiguredResponseId: PropTypes.number,
  quickReplyType: PropTypes.oneOf(Object.values(QUICK_REPLY_TYPE)),
  title: PropTypes.string,
});
