import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { Icon } from 'shared-components/icon';
import { Text } from 'shared-components/text';

import './empty-content.styles.scss';

const EmptyContent = ({ icon, iconClassName, className, description, position }) => (
  <div
    className={classNames(className, 'empty-content text-center fadeIn', {
      [`empty-content--${position} position-absolute`]: position !== 'static',
    })}
  >
    {icon && <Icon className={iconClassName} name={icon} size="2rem" />}
    <Text tag="p">{description}</Text>
  </div>
);

EmptyContent.defaultProps = {
  iconClassName: null,
  icon: null,
  position: 'center',
  className: null,
};

EmptyContent.propTypes = {
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  description: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['center', 'top', 'static']),
  className: PropTypes.string,
};

export default EmptyContent;
