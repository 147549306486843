import { TYPENAME } from 'core/constants/typename';

import { REMOVE_BACKGROUND_INQUIRY_CLIENT_MUTATION } from 'core/mutations/inquiries-background';

import { removeInquiryFromRelatedLists } from './utils';

export default (obj, { input: { id: inquiryId, user } }, { cache, getCacheKey, client }) => {
  const cacheId = getCacheKey({ __typename: TYPENAME.INQUIRY, id: inquiryId });

  removeInquiryFromRelatedLists({
    inquiryId,
    loggedUser: user,
    cache,
    cacheId,
  });

  client.mutate({
    mutation: REMOVE_BACKGROUND_INQUIRY_CLIENT_MUTATION,
    variables: {
      input: {
        id: +inquiryId,
      },
    },
  });

  return null;
};
