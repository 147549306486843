import { isNetworkRequestInFlight } from '@apollo/client/core/networkStatus';
import { branch, renderComponent } from 'recompose';

import { Loading } from 'shared-components/loading';

export default ({ component = Loading, propName = 'data' } = {}) =>
  branch(props => {
    const isRefetching =
      (props[propName] && props[propName].networkStatus && isNetworkRequestInFlight(props[propName].networkStatus)) ||
      (props.networkStatus && isNetworkRequestInFlight(props.networkStatus));

    const isLoading = (props[propName] && props[propName].loading) || props.loading;

    return isLoading || isRefetching;
  }, renderComponent(component));
