import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { PAGES } from 'core/constants/pages';
import { UserPropTypes } from 'core/prop-types';
import { darkLaunchService } from 'core/dark-launch';

import { Navbar, NavbarBrand, NavItem, Nav } from 'reactstrap';

import { Logo } from 'shared-components/logo';
import { Icon } from 'shared-components/icon';
import { LogoutLink } from 'shared-components/logout-link';
import { RouterLink } from 'shared-components/router-link';

import { ColorModeToggle } from 'components/color-mode-toggle';

import { SwitchDealer } from './switch-dealer';
import { UserLinkDropdown } from './user-link-dropdown';
import { BurgerMenu } from './burger-menu';

const Header = ({ isDealerUser, loggedUser, readOnly }) => (
  <header
    className={classNames('header pl-lg-1_5', {
      'header--dealer': isDealerUser,
    })}
  >
    <Navbar expand="xs" className="header__nav p-0 flex-nowrap">
      <NavbarBrand tag="div" className="header__brand d-none d-lg-block">
        <RouterLink title="All inquiries" to={PAGES.INQUIRIES} href={PAGES.INQUIRIES} className="d-block">
          <Logo white={isDealerUser} />
        </RouterLink>
      </NavbarBrand>
      <Nav
        navbar
        className={classNames('justify-content-between w-100', {
          dealer: isDealerUser,
        })}
      >
        <NavItem className="header__nav-item d-flex align-items-center d-xs-block d-lg-none">
          <BurgerMenu />
        </NavItem>
        <NavItem className="header__nav-item header__nav-item--switch-dealer min-w-0">
          <SwitchDealer className="header__dropdown header__switch-dealer" inNavbar isDealerUser />
        </NavItem>
        <NavItem className="header__nav-item d-flex align-items-center d-xs-block d-sm-none">
          <RouterLink
            color="transparent"
            title="All inquiries"
            to={PAGES.INQUIRIES}
            href={PAGES.INQUIRIES}
            className="d-flex align-items-center px-1 py-0_25 no-decoration"
          >
            <Icon name="bubbles5" size="1.25rem" className="text-white" />
          </RouterLink>
        </NavItem>
        {(darkLaunchService.is('cc9675') || !isDealerUser) && (
          <NavItem className="header__nav-item d-flex ml-auto mr-1">
            <ColorModeToggle />
          </NavItem>
        )}
        <NavItem
          className={classNames('header__nav-item d-none d-sm-block profile-menu', {
            dealer: isDealerUser,
          })}
        >
          {readOnly ? (
            <div className="d-flex h-100 align-items-center mr-1">
              <LogoutLink className="text-white d-flex align-items-center" />
            </div>
          ) : (
            <UserLinkDropdown loggedUser={loggedUser} isDealerUser={isDealerUser} />
          )}
        </NavItem>
      </Nav>
    </Navbar>
  </header>
);

Header.propTypes = {
  isDealerUser: PropTypes.bool.isRequired,
  loggedUser: UserPropTypes.LoggedUser.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default Header;
