import { VOI_QUERY } from 'core/queries/inquiry-details';

import inquiryVoiConverter from './graphql-inquiry-voi-converter';

export default (obj, { input: { inquiryId, body } }, { cache }) => {
  cache.writeQuery({
    query: VOI_QUERY,
    variables: {
      inquiryId,
    },
    data: inquiryVoiConverter(body),
  });

  return null;
};
