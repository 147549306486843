export const LIVE_REGION_ROLE = {
  LOG: 'log',
  STATUS: 'status',
  ALERT: 'alert',
  PROGRESSBAR: 'progressbar',
  MARQUEE: 'marquee',
  TIMER: 'timer',
};

export const LIVE_REGION_ROLES = Object.values(LIVE_REGION_ROLE);

export const ARIA_LIVE_POLITNESS = {
  OFF: 'off',
  POLITE: 'polite',
  ASSERTIVE: 'assertive',
};

export const ARIA_LIVE_POLITNESSES = Object.values(ARIA_LIVE_POLITNESS);
