import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { UserPropTypes } from 'core/prop-types';

import { PAGES } from 'core/constants/pages';

import { userService } from 'core/user';

import { Text } from 'shared-components/text';
import { MediaQuery } from 'shared-components/media-query';
import { LiveSessionLog } from 'shared-components/live-session-log';
import { RouterLink } from 'shared-components/router-link';

import { DarkLaunch } from 'components/dark-launch';

import './navigation-items-footer.styles.scss';

const NavigationItemsFooter = ({
  className,
  handleToggle,
  loggedUser,
  collapsed,
  isOpen,
  showLiveSessionLog,
  readOnly,
}) => (
  <div className={className}>
    {showLiveSessionLog && (
      <MediaQuery query="lg-up">
        <div
          className={classNames('navigation__live-session-log-container px-0_5 pt-0_5 w-100', {
            'navigation__live-session-log-container--collapsed': collapsed,
          })}
        >
          <LiveSessionLog minimized={collapsed && !isOpen} />
        </div>
      </MediaQuery>
    )}
    <MediaQuery query="md-down">
      {!readOnly && (
        <RouterLink
          className="mb-0_5 navigation-item__link text-gray-darkest font-weight-bold py-0_5 mr-1 px-1 d-block text-truncate"
          to={`${PAGES.USERS}/${loggedUser.id}`}
          onClick={handleToggle}
        >
          <Text icon="user">
            {userService.isAdmin(loggedUser) ? '(Admin)' : ''} {loggedUser.name}{' '}
          </Text>
        </RouterLink>
      )}
      <RouterLink
        className="mb-0_5 navigation-item__link text-gray-darkest py-0_5 d-block mr-1 pl-1"
        to={`${PAGES.LOGOUT}`}
      >
        Sign Out
      </RouterLink>
    </MediaQuery>
  </div>
);

NavigationItemsFooter.defaultProps = {
  className: '',
};

NavigationItemsFooter.propTypes = {
  className: PropTypes.string,
  handleToggle: PropTypes.func.isRequired,
  loggedUser: UserPropTypes.LoggedUser.isRequired,
  collapsed: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  showLiveSessionLog: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default NavigationItemsFooter;
