import { INQUIRIES_FILTER_QUERY } from 'core/queries/inquiries-filter';

import { inquiriesFilterService } from 'core/inquiries-filter';

export default (obj, { input: inquiriesFilter }, { cache }) => {
  const cachedFilters =
    cache.readQuery({
      query: INQUIRIES_FILTER_QUERY,
    }) || {};

  const newFilters = {
    ...cachedFilters,
    inquiriesFilter: (cachedFilters.inquiriesFilter || [])
      .filter(filter => filter.userId !== inquiriesFilter.userId || filter.dealerId !== inquiriesFilter.dealerId)
      .concat([inquiriesFilter]),
  };

  cache.writeQuery({
    query: INQUIRIES_FILTER_QUERY,
    data: newFilters,
  });

  inquiriesFilterService.saveFilters(newFilters.inquiriesFilter);

  return null;
};
