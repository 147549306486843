import AppointmentSettings from './appointment-settings';
import DealerInfo from './dealer-info';
import DealerSpecialHour from './dealer-special-hour';
import Dealer from './dealer';
import WorkingHours from './working-hours';
import Address from './address';
import GeneralSettings from './general-settings';
import DepartmentSettings from './department-settings';
import LeadAssignmentRule from './lead-assignment-rule';
import DealerCrmSettings from './dealer-crm-settings';

export default {
  AppointmentSettings,
  DealerInfo,
  DealerSpecialHour,
  Dealer,
  WorkingHours,
  Address,
  GeneralSettings,
  DepartmentSettings,
  LeadAssignmentRule,
  DealerCrmSettings,
};
