import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import { Badge } from 'reactstrap';

const KeyboardButton = ({ variant, children, className }) => (
  <Badge
    className={cn('px-0_5 py-0_25 font-size-sm', className, {
      'bg-gray-light text-gray-darkest': variant === 'gray',
      'bg-white text-gray-darkest': variant === 'light',
    })}
  >
    {children}
  </Badge>
);

KeyboardButton.defaultProps = {
  className: null,
  variant: 'light',
};

KeyboardButton.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['gray', 'light']),
};

export default KeyboardButton;
