import React from 'react';
import PropTypes from 'prop-types';

import MediaQuery from 'react-responsive';

const queries = {
  'sm-only': '(min-width: 576px) and (max-width: 767px)',
  'sm-down': '(max-width: 767px)',
  'md-only': '(min-width: 768px) and (max-width: 991px)',
  'md-up': '(min-width: 768px)',
  'md-down': '(max-width: 991px)',
  'lg-only': '(min-width: 992px) and (max-width: 1279px)',
  'lg-up': '(min-width: 992px)',
  'lg-down': '(max-width: 1279px)',
  'xlg-only': '(min-width: 1280px)',
};

/**
 * MediaQuery component.
 *
 * Used to prevent rendering of children depending on the screen size.
 *
 * E.g. if we have component that makes request to the server,
 * we can not just hide it on mobile using media queries since it will still make requests.
 * Instead, we should prevent rendering using this component.
 *
 * <MediaQuery
 *   query="lg-up"
 * >
 *   <Navigation />
 * </MediaQuery>
 *
 */
const CarCodeMediaQuery = ({ query, ...props }) => <MediaQuery query={queries[query]} {...props} />;

CarCodeMediaQuery.propTypes = {
  query: PropTypes.oneOf(Object.keys(queries)).isRequired,
};

export default CarCodeMediaQuery;
