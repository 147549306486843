import PropTypes from 'prop-types';

import PreconfiguredResponse from './preconfigured-response';

export default PropTypes.shape({
  categoryId: PropTypes.number,
  colorId: PropTypes.number,
  responses: PropTypes.arrayOf(PreconfiguredResponse),
  text: PropTypes.string,
});
