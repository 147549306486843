import { INQUIRY_FEEDBACK_QUERY } from 'core/queries/inquiry';

import inquiryFeedbackConverter from './graphql-inquiry-feedback-converter';

export default (obj, { input: { inquiryId, feedback } }, { cache }) => {
  cache.writeQuery({
    query: INQUIRY_FEEDBACK_QUERY,
    variables: { inquiryId },
    data: {
      inquiryFeedback: inquiryFeedbackConverter(feedback),
    },
  });

  return null;
};
