import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { DropdownItem as ReactstrapDropdownItem } from 'reactstrap';

import './dropdown-item.styles.scss';

const DropdownItem = ({ className, divider, customPadding, ...props }) => (
  <ReactstrapDropdownItem
    divider={divider}
    className={classNames(className, {
      'dropdown-item': !divider,
      'px-1_5 py-1': !divider && !customPadding,
      'm-0': divider,
    })}
    {...props}
  />
);

DropdownItem.defaultProps = {
  className: '',
  customPadding: false,
  divider: false,
};

DropdownItem.propTypes = {
  className: PropTypes.string,
  customPadding: PropTypes.bool,
  divider: PropTypes.bool,
};

export default DropdownItem;
