import PropTypes from 'prop-types';

export default PropTypes.shape({
  className: PropTypes.string,
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  centerMode: PropTypes.bool,
  initialSlide: PropTypes.number,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  outerEdgeLimit: PropTypes.bool,
  variableWidth: PropTypes.bool,
  beforeChange: PropTypes.func,
  afterChange: PropTypes.func,
});
