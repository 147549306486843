import _ from 'lodash';

import { MINUTE_MS } from 'core/constants/time';
import { CALL_CENTER_USER_TYPE, USER_TYPE_BY_ORIGIN } from 'core/constants/user';
import { INQUIRY_TYPE } from 'core/constants/inquiry/type';
import { PHONE_LABEL } from 'core/constants/phone';

import { userService } from 'core/user';
import { conversationTypeService } from 'core/conversation-type';
import { stringUtils } from 'core/utils/string';

const isReadOnlyForAgent = (user, inquiry) => userService.isAgent(user) && inquiry.assigned.id !== user.id;

const checkIfBelongsToDealer = (inquiry, dealerId) =>
  inquiry.dealerId === dealerId || (inquiry.phoneNumber && inquiry.phoneNumber.dealerId === dealerId);

const markAsReplied = inquiry => ({
  ...inquiry,
  msgStats: {
    ...inquiry.msgStats,
    timerPeriod: null,
    unanswered: 0,
    unread: 0,
  },
});

const isAutoAssignAllowed = ({ user, inquiry, hasToken = false, hasAssignParam = false }) => {
  if (hasToken && !hasAssignParam) {
    return false;
  }

  if (!user) {
    return false;
  }
  if (!inquiry) {
    return false;
  }

  return (
    !isInquiryTransferred(user, inquiry) &&
    !userService.isAdmin(user) &&
    !inquiry.assigned.id &&
    isPickupAllowed(inquiry, user)
  );
};

const hasTradeInAppraisal = inquiry =>
  conversationTypeService.isChat(inquiry.conversationType) &&
  inquiry.originDealer &&
  inquiry.originDealer.enableAppraisalForm;

const isUnassigned = inquiry => !inquiry.assigned.id;

const isAssignedTo = (inquiry, id) => inquiry.assigned.id === id;

const isInquiryTransferred = (user, inquiry) => user.currentDealer.id !== inquiry.dealerId;

const isArchived = inquiry => inquiry && inquiry.archivedAt;

const isManaged = inquiry => inquiry && inquiry.phoneNumber.dealerId !== inquiry.dealerId;

const isManagedByAnotherDealership = (inquiry, user) =>
  isManaged(inquiry) && inquiry.dealerId !== user.currentDealer.id;

const isStopped = inquiry => inquiry && inquiry.stopped;

const isBlocked = inquiry => inquiry && inquiry.isBlocked;

const isCompleted = inquiry => inquiry && (inquiry.conversationCompleted === null || inquiry.conversationCompleted);

const isReadOnly = (user, inquiry) =>
  !!(
    inquiry &&
    (isInquiryTransferred(user, inquiry) ||
      isReadOnlyForAgent(user, inquiry) ||
      isArchived(inquiry) ||
      isManagedByAnotherDealership(inquiry, user))
  );

const isHandoffAllowed = (user, inquiry) => inquiry.phoneNumber.dealerId !== user.currentDealer.id;

const isBlockAllowed = inquiry => !isBlocked(inquiry) && conversationTypeService.isSms(inquiry.conversationType);

const isBlockChatAllowed = ({ isReadOnlyInquiry, inquiry }) =>
  !isReadOnlyInquiry && !isBlocked(inquiry) && conversationTypeService.isChat(inquiry.conversationType);

const isBlockFacebookChatAllowed = ({ isReadOnlyInquiry, inquiry }) =>
  !isReadOnlyInquiry && !isBlocked(inquiry) && conversationTypeService.isFacebook(inquiry.conversationType);

const isEndChatAllowed = inquiry => !inquiry.ended && conversationTypeService.isChat(inquiry.conversationType);

const isCrmSendingAllowed = (inquiry, loggedUser) => !isReadOnly(loggedUser, inquiry) && !isManaged(inquiry);

const isReassignByRegularUsersAllowed = loggedUser => loggedUser.currentDealer.allowReassignByRegularUsers;

const isTransferAllowed = (inquiry, loggedUser, dealers) => {
  const isSalesInquiry = belongsToSalesPhone(inquiry);
  const isServiceInquiry = belongsToServicePhone(inquiry);
  const hasServiceDepartment = !!loggedUser.currentDealer.servicePhoneNumber;
  const hasDealersForTransfer = Boolean(dealers && dealers.length > 1);
  if (hasDealersForTransfer) return true;

  return (
    !isReadOnly(loggedUser, inquiry) &&
    !isManaged(inquiry) &&
    !conversationTypeService.isFacebook(inquiry.conversationType) &&
    ((isSalesInquiry && hasServiceDepartment) || isServiceInquiry)
  );
};

const isPickupAllowed = (inquiry, loggedUser, readOnly = false) => {
  // readOnly session
  if (readOnly) {
    return userService.isAdmin(loggedUser) && !userService.isAccountExecutive(loggedUser);
  }

  if (userService.isAdmin(loggedUser)) {
    return true;
  }

  if (userService.isCallCenterUserOrAdmin(loggedUser)) {
    return true;
  }

  // inquiry is assigned to another user (not proxy)
  // and user is dealer user
  if (!isUnassigned(inquiry) && !isManaged(inquiry) && userService.isUser(loggedUser)) {
    return isReassignByRegularUsersAllowed(loggedUser);
  }

  if (stringUtils.equalsIgnoreCase(inquiry.type, INQUIRY_TYPE.SALES)) {
    return loggedUser.isSales;
  }

  if (stringUtils.equalsIgnoreCase(inquiry.type, INQUIRY_TYPE.SERVICE)) {
    return loggedUser.isService;
  }

  return true;
};

const isChatEnded = inquiry => inquiry.ended;

const isReplied = timerPeriod => timerPeriod || timerPeriod === 0;

const isInquiryWatched = (user, inquiry) =>
  !isArchived(inquiry) && inquiry.proxyUserId && inquiry.proxyDealerId === user.currentDealer.id;

const isInquiryOverWatched = (user, inquiry) => inquiry.isIntercepted && inquiry.dealerId !== user.currentDealer.id;

const isWsConnectionAllowed = (user, inquiry) =>
  !isInquiryTransferred(user, inquiry) || isInquiryWatched(user, inquiry) || isInquiryOverWatched(user, inquiry);

const getConversationSourceLabel = dialogues => _.get(dialogues, 'dialog[0].dialogues.[0].sourceLabel', '');

const getConversationManagedByValue = ({ userType }) =>
  Object.values(CALL_CENTER_USER_TYPE).includes(userType)
    ? USER_TYPE_BY_ORIGIN.CALL_CENTER
    : USER_TYPE_BY_ORIGIN.DEALER_APP;

const getAvgResponseTimeFormat = ({ avgResponseTime }) => {
  if (avgResponseTime === MINUTE_MS) {
    return 'm[m]';
  }
  return avgResponseTime < MINUTE_MS ? 's[s]' : 'm[m] s[s]';
};

const hasMsgStats = inquiry => inquiry && !!inquiry.msgStats;

const getInquiryWithMsgStats = inquiry => {
  if (hasMsgStats(inquiry)) return inquiry;

  return {
    ...inquiry,
    msgStats: {
      idleCustomerTimeStamp: 0,
      timerPeriod: null,
      timerType: '',
      lastMsgText: '',
      unread: 0,
    },
  };
};

const isActionsEnabled = inquiryContextSettings =>
  inquiryContextSettings.isAssignedToLoggedUser && !inquiryContextSettings.isReadOnlyInquiry;

const getCrmStatus = inquiry => {
  if (inquiry.crmNotificationSent) {
    return 'Sent to CRM';
  }

  return 'Open';
};

const belongsToPhone = (inquiry, label) => {
  if (!inquiry.phoneNumber) {
    return false;
  }

  return stringUtils.equalsIgnoreCase(inquiry.phoneNumber.label, label);
};

const belongsToSalesPhone = inquiry => belongsToPhone(inquiry, PHONE_LABEL.SALES);

const belongsToServicePhone = inquiry => belongsToPhone(inquiry, PHONE_LABEL.SERVICE);

export default {
  checkIfBelongsToDealer,
  isAutoAssignAllowed,
  isReadOnly,
  markAsReplied,
  isHandoffAllowed,
  isBlockAllowed,
  isBlockChatAllowed,
  isBlockFacebookChatAllowed,
  isEndChatAllowed,
  isCrmSendingAllowed,
  isAssignedTo,
  isUnassigned,
  isChatEnded,
  isReplied,
  isWsConnectionAllowed,
  isStopped,
  isBlocked,
  isCompleted,
  isArchived,
  isPickupAllowed,
  hasMsgStats,
  getConversationSourceLabel,
  getConversationManagedByValue,
  getAvgResponseTimeFormat,
  isActionsEnabled,
  hasTradeInAppraisal,
  getCrmStatus,
  getInquiryWithMsgStats,
  isManaged,
  isManagedByAnotherDealership,
  isTransferAllowed,
  belongsToSalesPhone,
  belongsToServicePhone,
};
