import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { pure } from 'recompose';

import './loading.styles.scss';

const Loading = ({ size, color, pastDelay, className }) =>
  pastDelay && (
    <div
      data-test="cc-loading"
      data-testid="cc-loading"
      className={classNames(className, 'loading position-absolute', `loading--${color}`, {
        [`loading--size-${size}`]: size,
      })}
    />
  );

Loading.defaultProps = {
  pastDelay: true,
  size: null,
  color: 'dark',
  className: null,
};

Loading.propTypes = {
  pastDelay: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'average']),
  color: PropTypes.string,
  className: PropTypes.string,
};

export default pure(Loading);
