export const QUICK_REPLY_MAX_LENGTH = 20;

export const QUICK_REPLY_TYPE = {
  TEXT: 'text',
  EMAIL: 'user_email',
  PHONE_NUMBER: 'user_phone_number',
};

export const QUICK_REPLY_DEFAULT_VALUE_BY_TYPE = {
  [QUICK_REPLY_TYPE.TEXT]: '',
  [QUICK_REPLY_TYPE.EMAIL]: 'Email (Add Customer Info as Reply Option)',
  [QUICK_REPLY_TYPE.PHONE_NUMBER]: 'Phone Number (Add Customer Info as Reply Option)',
};

export const CUSTOMER_INFO_OPTIONS = [
  {
    label: 'Customer Email',
    value: QUICK_REPLY_TYPE.EMAIL,
    quickReplyValue: QUICK_REPLY_DEFAULT_VALUE_BY_TYPE[QUICK_REPLY_TYPE.EMAIL],
  },
  {
    label: 'Customer Phone Number',
    value: QUICK_REPLY_TYPE.PHONE_NUMBER,
    quickReplyValue: QUICK_REPLY_DEFAULT_VALUE_BY_TYPE[QUICK_REPLY_TYPE.PHONE_NUMBER],
  },
];
