import { gql } from '@apollo/client';

export const ALL_ACTIVE_FEATURES = gql`
  query GetAllActiveFeaturesQuery {
    getAllActiveFeatures {
      id
      iconUrl
      link
      linkText
      state
      title
      text
      createdAt
    }
  }
`;
