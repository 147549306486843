import { CONVERSATION_TYPE } from 'core/constants/inquiry/conversation-type';
import { CRM_STATUS } from 'core/constants/inquiry/crm-status';
import { DEPARTMENT_TYPE } from 'core/constants/inquiry/department-type';
import { ORIGIN_SOURCE } from 'core/constants/inquiry/origin-source';

export const FILTER_CONVERSATION_TYPES = [
  {
    id: null,
    name: 'All',
  },
  {
    id: CONVERSATION_TYPE.SMS.toUpperCase(),
    name: 'SMS',
  },
  {
    id: CONVERSATION_TYPE.FACEBOOK.toUpperCase(),
    name: 'Facebook',
  },
  {
    id: CONVERSATION_TYPE.DEALER_CHAT.toUpperCase(),
    name: 'Chat',
  },
  {
    id: CONVERSATION_TYPE.GBM.toUpperCase(),
    name: 'Google',
  },
];

export const FILTER_DEPARTMENT_TYPES = [
  {
    id: null,
    name: 'All',
  },
  {
    id: DEPARTMENT_TYPE.SALES.toUpperCase(),
    name: 'Sales',
  },
  {
    id: DEPARTMENT_TYPE.SERVICE.toUpperCase(),
    name: 'Service',
  },
  {
    id: DEPARTMENT_TYPE.EDMUNDS.toUpperCase(),
    name: 'Edmunds',
  },
];

export const FILTER_ORIGIN_SOURCES = [
  {
    id: null,
    name: 'Any',
  },
  {
    id: ORIGIN_SOURCE.EDMUNDS.toUpperCase(),
    name: 'Edmunds',
  },
  {
    id: ORIGIN_SOURCE.DEALER.toUpperCase(),
    name: 'Dealer',
  },
  {
    id: ORIGIN_SOURCE.SERVICE_LANE.toUpperCase(),
    name: 'Service Lane',
  },
];

export const INQUIRIES_FILTER_KEY = 'inquiries-filter';

export const FILTER_CRM_STATUS = [
  {
    id: null,
    name: 'All',
  },
  {
    id: CRM_STATUS.OPEN.toUpperCase(),
    name: 'Open',
  },
  {
    id: CRM_STATUS.SENT_TO_CRM.toUpperCase(),
    name: 'Sent to CRM',
  },
];
