import PropTypes from 'prop-types';

import PhoneNumber from './phone-number';

export default PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  floatingButtonPosition: PropTypes.string,
  mobileFloatingButtonPosition: PropTypes.string,
  phoneNumbers: PropTypes.arrayOf(PhoneNumber),
  isDefault: PropTypes.bool,
  embedCode: PropTypes.string,
});
