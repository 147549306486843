import React, { lazy } from 'react';

import Loadable from './loadable.presentation';

const LoadableFn = ({ LoadingComponent, loader }) =>
  class LoadableContainer extends React.Component {
    state = {};

    componentDidCatch(error, info) {
      this.setState({
        error,
      });
    }

    LazyComponent = lazy(loader);

    render() {
      const { error } = this.state;

      return (
        <Loadable
          LoadingComponent={LoadingComponent}
          LazyComponent={this.LazyComponent}
          error={error}
          {...this.props}
        />
      );
    }
  };

export default LoadableFn;
