import messageConverter from './graphql-message-converter';

import { updateMessageInDialogue } from './utils';

export default (obj, { input: { inquiryId, msg: message, appReplyId, user } }, { cache, getCacheKey }) => {
  const convertedMessage = messageConverter(message);

  updateMessageInDialogue({
    message: convertedMessage,
    inquiryId,
    user,
    updateBy: appReplyId,
    cache,
  });

  return null;
};
