import { INQUIRIES_LIST_INQUIRY_FRAGMENT } from 'core/queries/inquiries';
import { TYPENAME } from 'core/constants/typename';

export default (obj, { input: { inquiryId, isBlocked } }, { cache, getCacheKey }) => {
  const id = getCacheKey({ __typename: TYPENAME.INQUIRY, id: inquiryId });
  const cachedInquiry = cache.readFragment({
    id,
    fragment: INQUIRIES_LIST_INQUIRY_FRAGMENT,
    fragmentName: 'InquiriesListInquiry',
  });

  if (!cachedInquiry) {
    return null;
  }

  const nextCachedInquiry = {
    ...cachedInquiry,
    isBlocked,
  };

  cache.writeFragment({
    id,
    fragment: INQUIRIES_LIST_INQUIRY_FRAGMENT,
    fragmentName: 'InquiriesListInquiry',
    data: nextCachedInquiry,
  });

  return null;
};
