import { gql } from '@apollo/client';

export const USER_FEATURES = gql`
  query getUserFeatureNotifications($userId: Long!) {
    getUserFeatureNotifications(userId: $userId) {
      id
      featureNotificationId
      seen
    }
  }
`;
