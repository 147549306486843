import { GET_BACKGROUND_INQUIRIES } from 'core/queries/inquiries-background';

export default (obj, variables, { cache }) => {
  cache.writeQuery({
    query: GET_BACKGROUND_INQUIRIES,
    data: {
      backgroundInquiries: [],
    },
  });

  return null;
};
