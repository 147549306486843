export const SYSTEM_EVENTS = {
  PONG: 'pong',
  PING: 'ping',
};

export const WS_EVENT = {
  MESSAGE: 'onmessage',
  ERROR: 'onerror',
  OPEN: 'onopen',
  CLOSE: 'onclose',
};

export const EVENTS = [WS_EVENT.OPEN, WS_EVENT.ERROR, WS_EVENT.MESSAGE, WS_EVENT.CLOSE];

export const CLOSE_REASON = {
  EXPECTED: 'expected',
};

export const CONFIG = {
  PING_INTERVAL: 10000,
  PONG_WAIT_TIME: 2000,
  RECONNECT_BREAK_POINT: 10,
  RECONNECT_LIMIT: 20,
  RECONNECT_SHORT_INTERVAL: 2000,
  RECONNECT_LONG_INTERVAL: 30000,
};

export const USER_CHANNEL_EVENTS = {
  MESSAGE: 'message',
  REPLY: 'reply',
  CUSTOMER_UPDATE: 'customer_update',
  TRANSFER: 'transfer',
  ASSIGN_INQUIRY: 'user_assign',
  REASSIGN_INQUIRY: 'user_reassign',
  UPDATE_INQUIRY: 'update_inquiry',
  BLOCK_INQUIRY: 'inquiry_block',
  DELETE_INQUIRY: 'delete_inquiry',
  TRANSFER_INQUIRY: 'transfer_inquiry',
  UNARCHIVE_INQUIRY: 'unarchive_inquiry',
  CHAT_ENDED: 'chat_ended',
  CRM_NOTIFICATION_SENT: 'crm_notification_sent',
};

export const INQUIRY_CHANNEL_EVENTS = {
  MESSAGE: 'message',
  REPLY: 'reply',
  CUSTOMER_TEXTING: 'customer_texting',
  CUSTOMER_SUBMIT_FEEDBACK: 'customer_submit_feedback',
  CUSTOMER_IDLE: 'customer_idle',
  UPDATE_CUSTOMER: 'customer_update',
  USER_REASSIGN: 'user_reassign',
  USER_ASSIGN: 'user_assign',
  CRM_NOTIFICATION_SENT: 'crm_notification_sent',
  BLOCK_INQUIRY: 'inquiry_block',
  COMPLETE_INQUIRY: 'inquiry_conversation_complete',
  STOP_INQUIRY: 'inquiry_stop',
  INQUIRY_EVENT: 'inquiry_event',
  PICK_UP_INQUIRY: 'pick_up_inquiry',
  CONFIRMATION_REPLY: 'confirmation_reply',
  TRANSFER: 'transfer',
  OFFLINE: 'offline',
  ONLINE: 'online',
  TRANSCRIPT: 'transcript',
  PII_NAME_PROVIDED: 'pii_name_provided',
  PII_PHONE_PROVIDED: 'pii_phone_provided',
  PII_EMAIL_PROVIDED: 'pii_email_provided',
  PII_FORM_SHOWN: 'pii_form_shown',
  INVENTORY_SEARCH: 'inventory_search',
  TRADE_IN_VEHICLE_ADDED: 'trade_in_vehicle_added',
  TRADE_IN_VEHICLE_SEARCH: 'trade_in_vehicle_search',
  CHAT_ENDED: 'chat_ended',
  VEHICLE_LOOKUP_ENDED: 'vehicle_lookup_ended',
  VOI_UPDATE: 'voi_update',
};
