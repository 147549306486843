import React from 'react';
import { localStorage } from 'core/storages/local';
import { googleAnalyticsService } from 'core/tracking/google-analytics';
import { ACTIONS, CATEGORIES, LABELS } from 'core/constants/tracking';

import './color-mode-toggle.styles.scss';

const ColorModeToggle = () => {
  const bright = 'bright';
  const dark = 'dark';
  const analytics = {
    action: ACTIONS.DARK_MODE_TOGGLE,
    category: CATEGORIES.UI_APPEARANCE,
  };

  const isDarkMode =
    localStorage.get('isDarkMode') === 'true' || document.documentElement.getAttribute('data-theme') === dark;

  if (isDarkMode) {
    document.documentElement.setAttribute('data-theme', dark);
  }

  function toggle(event) {
    if (event.target.checked) {
      document.documentElement.setAttribute('data-theme', dark);
      googleAnalyticsService.event({
        ...analytics,
        label: LABELS.DARK_MODE_ENABLED,
      });
      localStorage.set('isDarkMode', true);
    } else {
      document.documentElement.setAttribute('data-theme', bright);
      googleAnalyticsService.event({
        ...analytics,
        label: LABELS.LIGHT_MODE_ENABLED,
      });
      localStorage.remove('isDarkMode');
    }
  }

  return (
    <div className="color-mode-toggle text-white d-flex align-items-center justify-content-center">
      <div className="d-none mr-1 d-sm-inline">App Theme</div>
      <div className="d-flex color-mode-switcher">
        <input
          type="checkbox"
          className="checkbox"
          aria-label="Dark Theme Toggle"
          id="darkModeToggle"
          onClick={toggle}
          defaultChecked={isDarkMode}
        />
        <div className="knobs" />
        <div className="layer" />
      </div>
    </div>
  );
};

export default ColorModeToggle;
