import PropTypes from 'prop-types';

const Permissions = ({ children, isAllowed }) => {
  if (isAllowed) {
    return children;
  }

  return null;
};

Permissions.propTypes = {
  isAllowed: PropTypes.bool.isRequired,
};

export default Permissions;
