export const TYPENAME = {
  BACKGROUND_INQUIRY: 'InquiryBackgroundClient',
  CATEGORY: 'InquiryCategory',
  CHANNEL: 'Channel',
  DEPARTMENT: 'Department',
  INQUIRIES_LIST_CHECKBOX: 'InquiriesListCheckbox',
  INQUIRY: 'InquiryDto',
  INQUIRIES_FILTER: 'InquiriesFilter',
  INQUIRY_REPLY: 'InquiryReplyDto',
  ORIGIN: 'Origin',
  TAB: 'InquiryTabClient',
  USER: 'UserPageDto',
  INQUIRY_MESSAGE: 'DialogueItemDto',
  MEDIA_CARD: 'MediaCardDto_MediaCardDataDto',
  MEDIA_CARD_DATA: 'MediaCardDataDto',
  SECURITY_USER: 'SecurityUserDto',
  CATEGORY_PERMISSION: 'CategoryPermissionDto',
  DRAFT_MESSAGE: 'DraftMessageDto',
  DEALER: 'DealerDtoCallCenter',
  CRM_STATUS: 'CrmStatus',
};
