import { EVENTS, CLOSE_REASON, WS_EVENT } from 'core/constants/web-socket';

class WebSocket {
  constructor({ wsUrl, user }) {
    this.wsUrl = wsUrl;
    this.user = user;
    this.events = {};

    this.init();
  }

  init() {
    // no need to initiate destroyed socket
    // it might happen when reconnect callback tries to reinitiate destroyed socket
    if (this.isDestroyed) {
      return;
    }

    this.webSocket = new window.WebSocket(this.wsUrl);

    this.initEvents();
  }

  reconnect() {
    // no need to reconnect destroyed socket
    // it might happen when reconnect callback tries to reinitiate destroyed socket
    if (this.isDestroyed) {
      return;
    }

    this.disconnect();
    this.init();
  }

  initEvents() {
    EVENTS.forEach(event => {
      if (!this.events[event]) {
        this.events[event] = [];
      }

      this.webSocket[event] = message => {
        if (Object.keys(this.events).length === 0) {
          return;
        }

        this.events[event].forEach(handler => {
          if (event === WS_EVENT.MESSAGE) {
            const messageData = JSON.parse(message.data);

            // call listeners
            handler.call(this.webSocket, messageData);
          } else {
            handler.call(this.webSocket, message);
          }
        });
      };
    });
  }

  sendMessage(data) {
    if (!this.is(window.WebSocket.OPEN)) {
      return;
    }

    this.webSocket.send(data);
  }

  sendData(data) {
    this.sendMessage(JSON.stringify(data));
  }

  disconnect(code, reason) {
    // already closed
    if (this.is(window.WebSocket.CLOSING) || this.is(window.WebSocket.CLOSED)) {
      return;
    }

    if (reason === CLOSE_REASON.EXPECTED) {
      this.destroy();
    }

    this.webSocket.close(code, reason);
  }

  is(readyState) {
    return this.webSocket.readyState === readyState;
  }

  isEmpty() {
    return !this.events[WS_EVENT.MESSAGE].length;
  }

  registerEvent(event, handlerRef) {
    if (!handlerRef || !EVENTS.includes(event) || this.isDestroyed) {
      return;
    }

    this.events[event].push(handlerRef);
  }

  registerEvents(events) {
    if (!events || !events.length || this.isDestroyed) {
      return;
    }

    events.forEach(({ name, handler }) => this.registerEvent(name, handler));
  }

  destroy() {
    this.events = {};
    this.wsUrl = null;
    this.isDestroyed = true;

    EVENTS.forEach(name => {
      this.webSocket[name] = null;
    });
  }

  unregisterEvent(event, handlerRef) {
    // if no such event
    // then nothing to unregister
    if (!this.events[event]) {
      return;
    }

    this.events[event] = this.events[event].filter(handler => handler !== handlerRef);
  }

  unregisterEvents(events) {
    if (!events || !events.length) {
      return;
    }

    events.forEach(({ name, handler }) => this.unregisterEvent(name, handler));
  }
}

export default WebSocket;
