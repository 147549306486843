let localDarkLaunchFlags = [];

const is = flag => localDarkLaunchFlags.includes(flag);

const init = darkLaunchFlags => {
  localDarkLaunchFlags = darkLaunchFlags || [];
};

export default {
  is,
  init,
};
