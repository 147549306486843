import { setInterval, clearInterval } from 'worker-timers';

import { config } from 'core/config';
import { localStorage } from 'core/storages/local';

import { LAST_ACTION_KEY } from 'core/constants/storage';
import {
  SKIP_CLICKS_ID_SELECTOR,
  CHECK_INTERVAL_MS,
  MINUTES_UNTIL_AUTO_LOGOUT_DEFAULT,
} from 'core/constants/auto-logout';

export const EVENTS = {
  INACTIVE: 'inactive',
};

export const getMaxInactiveTimeInMins = () => +config.autoLogoutMinutes || MINUTES_UNTIL_AUTO_LOGOUT_DEFAULT;

/**
 * This service tracks logged user activity.
 *
 * It will notify subscribers when user becomes inactive for more than {maxInactiveTimeInMins}.
 * By inactive we currently mean no clicks inside the appliction.
 *
 * We store last user active time ms in localStorage by {LAST_ACTION_KEY} key. Then we compare it with
 * current time ms each {CHECK_INTERVAL_MS} and if it more than {maxInactiveTimeInMins} then subscribers are notified.
 *
 * We need to store last active time ms in localStorage to synchronized application between separate tabs.
 */

class AutoLogoutService {
  constructor({ maxInactiveTimeInMins }) {
    if (!maxInactiveTimeInMins) {
      throw new Error('maxInactiveTimeInMins should be specified');
    }

    this.maxInactiveTimeInMs = maxInactiveTimeInMins * 60 * 1000;

    this.setDefault();
    this.initListener();
    this.initInterval();

    this.events = {};
  }

  // eslint-disable-next-line class-methods-use-this
  get lastAction() {
    return parseInt(localStorage.get(LAST_ACTION_KEY), 10);
  }

  // eslint-disable-next-line class-methods-use-this
  set lastAction(value) {
    localStorage.set(LAST_ACTION_KEY, value);
  }

  setDefault = () => {
    // if no last action timestamp then set it to the current date
    if (!this.lastAction) {
      this.reset();
    }
  };

  activityListener = () => {
    // if no element that indicates preventing activity in DOM
    // then check activity
    // SKIP_CLICKS_ID_SELECTOR will be in DOM when modal to confirm user status is appeared
    // so that we will not check activity except for submit click
    if (!document.getElementById(SKIP_CLICKS_ID_SELECTOR)) {
      this.reset();
    }
  };

  initListener = () => {
    // update last acitve time on each click inside the application
    document.body.addEventListener('click', this.activityListener);
  };

  on = (event, callback) => {
    if (!this.events[event]) {
      this.events[event] = [];
    }

    this.events[event].push(callback);
  };

  un = (event, callback) => {
    if (!this.events[event]) {
      return;
    }

    this.events[event] = this.events[event].filter(fn => fn !== callback);
  };

  notify = event => {
    if (!this.events[event]) {
      return;
    }

    this.events[event].forEach(callback => {
      callback();
    });
  };

  destroy = () => {
    document.body.removeEventListener('click', this.reset);
    this.events = {};

    if (this.intervalId) {
      clearInterval(this.intervalId);

      this.intervalId = null;
    }
  };

  reset = () => {
    this.lastAction = Date.now();
  };

  initInterval = () => {
    this.intervalId = setInterval(() => {
      this.check();
    }, CHECK_INTERVAL_MS);
  };

  isInactive = () => {
    const now = Date.now();
    const timeleft = this.lastAction + this.maxInactiveTimeInMs;
    const diff = timeleft - now;
    const isInactive = diff < 0;

    return isInactive;
  };

  check = () => {
    const isInactive = this.isInactive();

    // if more than speicified mins passed then notify the listeners about it
    if (isInactive) {
      this.notify(EVENTS.INACTIVE);
    }
  };
}

export default AutoLogoutService;
