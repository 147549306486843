import _ from 'lodash';

class LimitedQueue {
  static DEFAULT_LIMIT = 5;
  static DEFAULT_LIMIT_BY = 'id';

  constructor({ limit = LimitedQueue.DEFAULT_LIMIT, limitBy = LimitedQueue.DEFAULT_LIMIT_BY }) {
    this.queue = [];
    this.limit = limit;
    this.limitBy = limitBy;
  }

  push(item) {
    if (this.queue.length === this.limit) {
      this.queue.splice(0, 1);
    }

    const existingItemInQueue = this.get(item[this.limitBy]);
    existingItemInQueue ? _.merge(existingItemInQueue, item) : this.queue.push(item);

    return this.queue.length;
  }

  get(key) {
    return _.find(this.queue, {
      [this.limitBy]: key,
    });
  }
}

export default LimitedQueue;
