import { gql } from '@apollo/client';

export const INQUIRIES_FILTER_QUERY = gql`
  query GetInquiriesFilters {
    inquiriesFilter @client {
      userId
      dealerId
      channel {
        id
        name
      }
      customerEmail
      customerName
      customerPhoneNumber
      department {
        id
        name
      }
      endDate
      startDate
      filterAssignedUser {
        id
        name
      }
      filterProcessedUser {
        id
        name
      }
      selectedDealers {
        id
        name
      }
      origin {
        id
        name
      }
      crmStatus
    }
  }
`;
