export const ALERT = {
  TIMEOUT: 3500,
  CONVERSATION_TIMEOUT: 4500,
};

export const ALERT_COLOR = {
  INFO: 'info',
  DANGER: 'danger',
  SUCCESS: 'success',
  WARNING: 'warning',
  PRIMARY: 'primary',
};

export const ALERT_TYPE = {
  DEFAULT: 'default',
  CONVERSATION: 'conversation',
};

export const CONVERSATION_ALERT_QUERIES = [
  'DealerWorkingHours',
  'DealerServiceApp',
  'DealerVyt',
  'DealerReview',
  'DealerTestDrive',
  'AdvancedInventorySearch',
  'ReplyInquiryMutation',
];

export const ALERT_QUERIES_EXCEPTIONS = ['NotifyChatMutation', 'scheduleServiceAppointment'];

export const ALERT_QUERIES_TEXT = {
  ReplyInquiryMutation: 'Message failed to send due to a network error',
};

export const CONVERSATION_ALERT_CONTAINER_ID = 'conversation-alert';
