import { CONVERSATION_TYPE } from 'core/constants/inquiry/conversation-type';
import { ORIGIN_SOURCE } from 'core/constants/inquiry/origin-source';

const getIcon = conversationType => {
  switch (conversationType) {
    case CONVERSATION_TYPE.FACEBOOK:
      return 'facebook-messenger';
    case CONVERSATION_TYPE.GBM:
      return 'google';
    case CONVERSATION_TYPE.DEALER_CHAT:
      return 'bubble7';
    case CONVERSATION_TYPE.SMS:
      return 'mobile2';
    case CONVERSATION_TYPE.WHATSAPP:
      return 'whatsapp';
    default:
      return null;
  }
};

const isFacebook = conversationType => conversationType === CONVERSATION_TYPE.FACEBOOK;

const isGBM = conversationType => conversationType === CONVERSATION_TYPE.GBM;

const isSms = conversationType => conversationType === CONVERSATION_TYPE.SMS;

const isChat = conversationType => conversationType === CONVERSATION_TYPE.DEALER_CHAT;

const isWhatsapp = conversationType => conversationType === CONVERSATION_TYPE.WHATSAPP;

const isFBMP = ({ inquirySource }) => ORIGIN_SOURCE.MARKETPLACE === inquirySource.toLowerCase();

const isFBMPDirect = ({ inquirySource }) => ORIGIN_SOURCE.MARKETPLACE_DIRECT === inquirySource.toLowerCase();

export default {
  getIcon,
  isFacebook,
  isSms,
  isChat,
  isWhatsapp,
  isFBMP,
  isGBM,
  isFBMPDirect,
};
