import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { DropdownToggle as ReactstrapDropdownToggle } from 'reactstrap';

import './dropdown-toggle.styles.scss';

const DropdownToggle = ({ className, ...props }) => (
  <ReactstrapDropdownToggle className={classNames(className, 'dropdown-toggle position-relative')} {...props} />
);

DropdownToggle.defaultProps = {
  className: null,
};

DropdownToggle.propTypes = {
  className: PropTypes.string,
};

export default DropdownToggle;
