import { TYPENAME } from 'core/constants/typename';
import { transferService } from 'core/transfer';

import { removeInquiryFromRelatedLists } from './utils';

// TODO dealerAssigment - is a typo from BE side, need to correct after BE changes
export default (obj, { input: { inquiry, user, dealerAssigment } }, { cache, getCacheKey }) => {
  if (!transferService.isHandedOffFromCurrentDealer(dealerAssigment, user)) {
    return null;
  }

  const cacheId = getCacheKey({ __typename: TYPENAME.INQUIRY, id: inquiry.id });

  removeInquiryFromRelatedLists({
    inquiryId: inquiry.id,
    loggedUser: user,
    cache,
    cacheId,
  });

  return null;
};
