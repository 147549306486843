import { useRef, useEffect } from 'react';

/**
 * usePrevious hook.
 *
 * Useful for getting the previous value of props or state. This hook accepts a
 * value and will store it via the built-in useRef hook.
 *
 * E.g.
 *
 * const MyComponent = ({ count }) => {
 *  const prevCount = usePrevious(count);
 *
 *  useEffect(() => {
 *    // Can compare the current value of count from props with the previous value stored in prevCount
 *  }, [count, prevCount])
 *
 *  return (
 *    <div>
 *      <span>Now: {count}, Previous: {prevCount}</span>
 *    </div>
 *  )
 * }
 */

const usePrevious = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default usePrevious;
