import React, { useState } from 'react';
import { NavigationItem } from 'components/navigation/navigation-items/navigation-item';
import { NotificationPropTypes } from 'core/prop-types';
import PropTypes from 'prop-types';
import { useEffectAllDepsChange } from 'core/hooks/useEffectAllDepsChange';

const NotificationsNavigationContainer = ({ allFeatures, userFeatures, ...props }) => {
  const [newNotification, setNewNotification] = useState(false);
  useEffectAllDepsChange(() => {
    let hasNewNotification = false;
    if (allFeatures.length > 0) {
      const seenFeaturesId = userFeatures.reduce(
        (accumulator, value) => ({ ...accumulator, [value.featureNotificationId]: value.seen }),
        {},
      );
      allFeatures.forEach(feature => {
        if (!seenFeaturesId[feature.id]) {
          hasNewNotification = true;
        }
      });

      setNewNotification(hasNewNotification);
    }
  }, [allFeatures, userFeatures]);

  return <NavigationItem {...props} redCircle={newNotification} />;
};

NotificationsNavigationContainer.defaultProps = {
  allFeatures: [],
  userFeatures: [],
};

NotificationsNavigationContainer.propTypes = {
  allFeatures: PropTypes.arrayOf(NotificationPropTypes.Notification.isRequired),
  userFeatures: PropTypes.arrayOf(NotificationPropTypes.UserNotification.isRequired),
};

export default NotificationsNavigationContainer;
