import { API } from 'core/api';
import { LABELS } from 'core/constants/tracking';

import edw from './edw';

export const trackCtaEvent = eventData => {
  // This was added to resolve circular dependency in edw tracker
  // and allows testing the code
  // eslint-disable-next-line import/no-named-as-default-member
  edw.track(LABELS.CLIENT_CTA_EVENT, { env: API.ENV, ...eventData });
};
