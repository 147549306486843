import PropTypes from 'prop-types';

export default PropTypes.shape({
  dealerId: PropTypes.number.isRequired,
  dealerName: PropTypes.string.isRequired,
  phoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
    }),
  ),
});
