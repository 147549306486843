import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Route } from 'react-router-dom';
import { Page } from 'pages/page';

import { LoadingQuery } from 'shared-components/loading-query';
import { PwaOrchestrator } from 'components/pwa-orchestrator';

import classNames from 'classnames';
import { LoggedUserContext } from 'core/context/logged-user';
import { sessionService } from 'core/session';
import { newRelicService } from 'core/new-relic';

import { ROUTES } from 'core/constants/routes';
import { LOGGED_USER_QUERY } from 'core/queries/logged-user';

const ProtectedRoute = ({ component: Component, location, className, ...rest }) => {
  const [token, setToken] = useState('');

  useEffect(() => {
    newRelicService.setRouteName(location.pathname, location.search);
    setToken(sessionService.isAuthenticated());
  }, []);

  return (
    <Route {...rest}>
      {token ? (
        <LoadingQuery query={LOGGED_USER_QUERY} variables={{ token }}>
          {({ error, data }) => {
            if (error) {
              newRelicService.logError(error);
              return `Error!: ${error}`;
            }
            // extend with User.Prototype
            const user = data.getUserByToken;
            return (
              <LoggedUserContext.Provider value={user}>
                <PwaOrchestrator />
                <Page>
                  <Component {...rest} className={classNames(className, 'fadeIn')} />
                </Page>
              </LoggedUserContext.Provider>
            );
          }}
        </LoadingQuery>
      ) : (
        <Component {...rest} className={classNames(className, 'fadeIn')} />
      )}
    </Route>
  );
};

ProtectedRoute.defaultProps = {
  redirectWhenWrong: ROUTES.INQUIRIES,
  showNavigation: false,
  publicPage: false,
  className: '',
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  redirectWhenWrong: PropTypes.string,
  showNavigation: PropTypes.bool,
  publicPage: PropTypes.bool,
  className: PropTypes.string,
};

export default ProtectedRoute;
