import { INQUIRY_DIALOGUE_QUERY_FRAGMENT } from 'core/queries/inquiry';

import resolverUtils from 'core/utils/resolver/resolver-utils';
import decorator from 'core/resolvers/graphql-type-decorator';

export default wsDialogueItemObject => {
  let result = resolverUtils.pickFromObject({
    query: INQUIRY_DIALOGUE_QUERY_FRAGMENT,
    object: wsDialogueItemObject,
  });

  result = {
    ...decorator.dialogueItem(result),
    attachments: decorator.attachments(result.attachments),
    mediaCards: decorator.mediaCards(result.mediaCards),
    linkPreviews: decorator.linkPreviews(result.linkPreviews),
    inventoryAttachmentCards: decorator.inventoryAttachmentCards(result.inventoryAttachmentCards),
  };

  if (result.vehicle) {
    result.vehicle = {
      ...decorator.vehicle(result.vehicle),
      outdated: result.vehicle.outdated || false,
    };
  }

  if (result.vehicleDealer) {
    result.vehicleDealer = {
      ...decorator.vehicleDealer(result.vehicleDealer),
      sales: decorator.sales(result.vehicleDealer.sales),
    };
  }

  if (result.workingHoursCard) {
    result.workingHoursCard = {
      ...decorator.workingHoursCard(result.workingHoursCard),
      operations: decorator.workingOperations(result.workingHoursCard.operations),
    };
  }

  if (result.workingHoursCard && result.workingHoursCard.operations) {
    result.workingHoursCard.operations = {
      ...decorator.workingOperations(result.workingHoursCard.operations),
    };
  }

  if (result.usedVehicleTMVCard) {
    result.usedVehicleTMVCard = {
      ...decorator.usedVehicleTMVCard(result.usedVehicleTMVCard),
    };
  }

  if (result.vehicle && result.vehicle.edmundsVehicleDealer) {
    result.vehicle.edmundsVehicleDealer = {
      ...decorator.edmundsVehicleDealer(result.vehicle.edmundsVehicleDealer),
      sales: decorator.sales(result.vehicle.edmundsVehicleDealer.sales),
      zip: result.vehicle.edmundsVehicleDealer.zip || null,
    };
  }

  return result;
};
