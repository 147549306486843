import { storage } from 'core/storages/storage';

const set = (key, value) => storage.set(key, value, window.localStorage);
const get = key => storage.get(key, window.localStorage);
const remove = key => storage.remove(key, window.localStorage);

export default {
  set,
  get,
  remove,
};
