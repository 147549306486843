import posed from 'react-pose';

const SlideAndCollapse = posed.div({
  enter: {
    x: ({ xTo }) => xTo,
    'flex-basis': ({ flexTo }) => flexTo,
    transition: {
      ease: 'linear',
      duration: 250,
    },
  },
  exit: {
    x: ({ xFrom }) => xFrom,
    'flex-basis': ({ flexFrom }) => flexFrom,
    transition: {
      ease: 'linear',
      duration: 250,
    },
  },
});

export default SlideAndCollapse;
