import { graphql, withApollo } from '@apollo/client/react/hoc';
import { compose, pure, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';

import { withLoggedUser } from 'core/hocs/logged-user';

import { inquiriesFilterService } from 'core/inquiries-filter';
import { inquiriesCategoryService } from 'core/inquiries-category';

import { ACTIVE_CATEGORY_ID_CLIENT_MUTATION } from 'core/mutations/inquiries-categories';
import { INQUIRIES_STRUCTURE_QUERY } from 'core/queries/inquiries-structure';
import { INQUIRIES_CATEGORIES_QUERY, ACTIVE_CATEGORY_ID_QUERY } from 'core/queries/inquiries-categories';
import { INQUIRIES_FILTER_QUERY } from 'core/queries/inquiries-filter';
import { POLL_INTERVAL } from 'core/constants/inquiries/structure';
import { userService } from 'core/user';

import InquiriesNavigationCategoriesContainer from './inquiries-navigation-categories.container';

export default compose(
  withLoggedUser,
  withRouter,
  pure,
  graphql(INQUIRIES_FILTER_QUERY, {
    name: 'getInquiriesFilter',
  }),
  graphql(INQUIRIES_CATEGORIES_QUERY, {
    name: 'getCategories',
  }),
  withProps(({ getCategories: { categories }, loggedUser: user }) => ({
    categories: inquiriesCategoryService.filterVisible(categories, user),
  })),
  graphql(ACTIVE_CATEGORY_ID_CLIENT_MUTATION, { name: 'updateActiveCategoryId' }),
  graphql(INQUIRIES_STRUCTURE_QUERY, {
    name: 'getStructure',
    options: ({ loggedUser, getInquiriesFilter: { inquiriesFilter }, categories }) => {
      const {
        id: userId,
        currentDealer: { id: dealerId },
      } = loggedUser;

      const isDealerUser = userService.hasDealerPermissions({ user: loggedUser });
      const pollInterval = !isDealerUser ? POLL_INTERVAL : null;

      return {
        fetchPolicy: 'cache-and-network',
        pollInterval,
        variables: {
          dealerIds: inquiriesFilterService.getDealerIds(inquiriesFilter, userId, dealerId),
          filters: inquiriesFilterService.getFiltersParams(
            inquiriesFilterService.extractFilters(inquiriesFilter, userId, dealerId),
          ),
          ...inquiriesCategoryService.getStructureVariables({
            categories,
            user: loggedUser,
          }),
        },
      };
    },
  }),
  graphql(ACTIVE_CATEGORY_ID_QUERY, {
    name: 'getActiveCategoryId',
  }),
  withProps(
    ({
      getStructure: { inquiriesStructure, loading, refetch },
      getActiveCategoryId: { activeCategoryId },
      categories,
    }) => {
      const categoriesWithCounts = inquiriesStructure
        ? inquiriesCategoryService.update(categories, inquiriesStructure)
        : categories;

      return {
        categories: inquiriesCategoryService
          .mapActive(categoriesWithCounts, activeCategoryId)
          // map loading
          .map(category => ({
            ...category,
            loading,
          })),
        refetch,
      };
    },
  ),
  withApollo,
)(InquiriesNavigationCategoriesContainer);
