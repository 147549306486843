import _ from 'lodash';

import { localStorage } from 'core/storages/local';
import { ORDER_DIRECTION } from 'core/constants/order';
import { USERS_LIST_SORTING_KEYS, USERS_PERFORMANCE_SORTING_KEYS } from 'core/constants/users';
import { INQUIRIES_ORDER_STORAGE_KEY } from 'core/constants/inquiries/category';

import { userService } from 'core/user';

const is = (order, orderToCompare) => order === orderToCompare;

const isAsc = order => is(order, ORDER_DIRECTION.ASC);

const isDesc = order => is(order, ORDER_DIRECTION.DESC);

const toggle = orderDirection => (isAsc(orderDirection) ? ORDER_DIRECTION.DESC : ORDER_DIRECTION.ASC);

const getDefault = ({ key, user }) => {
  if (key && Object.values(INQUIRIES_ORDER_STORAGE_KEY).includes(key)) {
    if (user && userService.hasDealerPermissions({ user })) return ORDER_DIRECTION.DESC;
    return ORDER_DIRECTION.ASC;
  }

  if (key && Object.values(USERS_PERFORMANCE_SORTING_KEYS).includes(key)) {
    return ORDER_DIRECTION.ASC;
  }

  switch (key) {
    case USERS_LIST_SORTING_KEYS.NAME:
      return ORDER_DIRECTION.ASC;
    case USERS_LIST_SORTING_KEYS.ROLE:
      return ORDER_DIRECTION.ASC;
    case USERS_LIST_SORTING_KEYS.CONTACT:
      return ORDER_DIRECTION.ASC;
    case USERS_LIST_SORTING_KEYS.ASSIGNED_INQUIRIES:
      return ORDER_DIRECTION.ASC;
    case USERS_LIST_SORTING_KEYS.AUTO_ASSIGN_ROTATION:
      return ORDER_DIRECTION.ASC;
    case USERS_LIST_SORTING_KEYS.RESPONSE:
      return ORDER_DIRECTION.ASC;
    case USERS_LIST_SORTING_KEYS.STATUS:
      return ORDER_DIRECTION.ASC;
    default:
      return ORDER_DIRECTION.DESC;
  }
};

const get = ({ key, user }) => localStorage.get(key) || getDefault({ key, user });

const save = ({ key, direction }) => localStorage.set(key, direction);

const toggleCategoryListOrders = ({ category, user }) => ({
  ...category,
  list: category.list.map(listParams =>
    _.merge(listParams, {
      query: {
        options: {
          variables: {
            pagination: {
              page: 1,
              order: toggle(get({ key: listParams.toolbar.order.storageKey, user })),
            },
          },
        },
      },
    }),
  ),
});

export default {
  toggleCategoryListOrders,
  toggle,
  get,
  save,
  isAsc,
  isDesc,
};
