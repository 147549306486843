import PropTypes from 'prop-types';

export default PropTypes.shape({
  configuredWidgetId: PropTypes.number,
  delayInSeconds: PropTypes.number,
  id: PropTypes.number,
  keywords: PropTypes.string,
  popupType: PropTypes.string,
  text: PropTypes.string,
  viewThroughCustomerTriggerOn: PropTypes.bool,
});
