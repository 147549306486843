import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { UiPropTypes, UserPropTypes } from 'core/prop-types';

import { CollapseFooter } from 'shared-components/collapse-footer';
import { SlideAndCollapse } from 'shared-components/animated';

import { NavigationItems } from './navigation-items';
import { NavigationFooter } from './navigation-footer';

import './navigation.styles.scss';

const Navigation = ({
  links,
  collapsed,
  className,
  handleCollapse,
  handleToggle,
  isOpen,
  width,
  flexFromValue,
  showLiveSessionLog,
  loggedUser,
  readOnly,
}) => {
  for (let i = 0; i < links.length; i += 1) {
    if (links[i].navItems) {
      for (let j = 0; j < links[i].navItems.length; j += 1) {
        // eslint-disable-next-line no-param-reassign
        links[i].navItems[j].onClick = handleToggle;
      }
    }
  }

  return (
    <SlideAndCollapse
      pose={collapsed ? 'exit' : 'enter'}
      flexFrom={flexFromValue}
      flexTo={width}
      className={classNames(
        className,
        'navigation position-relative bg-gray-lightest border-right border-gray-light flex-shrink-0',
        {
          'navigation--collapsed': collapsed,
        },
      )}
    >
      <NavigationItems
        links={links}
        collapsed={collapsed}
        handleToggle={handleToggle}
        loggedUser={loggedUser}
        isOpen={isOpen}
        showLiveSessionLog={showLiveSessionLog}
        readOnly={readOnly}
      />
      <NavigationFooter className="d-block d-lg" />
      <CollapseFooter
        className="navigation__footer border-gray-light p-0 d-none d-lg-block"
        handleCollapse={handleCollapse}
        collapsed={collapsed}
      />
    </SlideAndCollapse>
  );
};

Navigation.defaultProps = {
  className: null,
  isOpen: false,
};

Navigation.propTypes = {
  links: PropTypes.arrayOf(UiPropTypes.NavigationItem).isRequired,
  collapsed: PropTypes.bool.isRequired,
  className: PropTypes.string,
  handleCollapse: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  width: PropTypes.string.isRequired,
  flexFromValue: PropTypes.string.isRequired,
  showLiveSessionLog: PropTypes.bool.isRequired,
  loggedUser: UserPropTypes.LoggedUser.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default Navigation;
