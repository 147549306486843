import _ from 'lodash';

import { REGEXP } from 'core/constants/regexp';

const getActiveInventoryIdentifier = (activeInventory, inventoryIdentifiers) => {
  if (!hasIdentifiers(inventoryIdentifiers)) {
    return null;
  }

  const defaultIdentifier = inventoryIdentifiers.identifiers[inventoryIdentifiers.identifiers.length - 1];

  if (!activeInventory || !activeInventory.value) {
    return defaultIdentifier;
  }

  const activeIdentifier = inventoryIdentifiers.identifiers.filter(
    inventoryIdentifier => inventoryIdentifier.value === activeInventory.value,
  )[0];

  return activeIdentifier || defaultIdentifier;
};

const hasIdentifiers = item => item && item.identifiers && item.identifiers.length > 0;

const mergeInventoryIdentifiers = (inventoryIdentifiers, validIdentifiers) =>
  _.uniqBy(
    hasIdentifiers(inventoryIdentifiers)
      ? [...inventoryIdentifiers.identifiers, ...validIdentifiers]
      : [...validIdentifiers],
    'value',
  );

const getUnique = (identifiers, inventoryIdentifiers) =>
  identifiers.reduce((newIdentifiers, current) => {
    const isExist = inventoryIdentifiers.identifiers.some(
      ({ vehicle }) => vehicle.stockNumber === current.value || vehicle.vin === current.value,
    );

    return isExist ? newIdentifiers : [...newIdentifiers, current];
  }, []);

const getActualIdentifiers = (newIdentifiers, oldIdentifiers) => {
  const isNewIdentifiers = newIdentifiers && newIdentifiers.length > 0;
  const isOldIdentifiers = hasIdentifiers(oldIdentifiers);

  return isOldIdentifiers && isNewIdentifiers ? getUnique(newIdentifiers, oldIdentifiers) : [...newIdentifiers];
};

const isVin = identifier => new RegExp(REGEXP.VIN).test(identifier);

export default {
  getActiveInventoryIdentifier,
  mergeInventoryIdentifiers,
  getUnique,
  hasIdentifiers,
  getActualIdentifiers,
  isVin,
};
