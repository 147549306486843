const normalizeURL = url => {
  if (!url) {
    return url;
  }

  if (url.includes('https://') || url.includes('http://')) {
    return url;
  }

  return `https://${url}`;
};

const getUrlParam = key => {
  let value;
  const params = new URLSearchParams(window.location.search);

  if (params.has(key)) {
    value = params.get(key);
  }

  return value;
};

const isExternalHostname = () => window.location.href.includes('dev-dsg11-ext');

export default {
  normalizeURL,
  getUrlParam,
  isExternalHostname,
};
