import mutations from './mutations';
import queries from './queries';

export default {
  Mutation: {
    ...mutations,
  },
  Query: {
    ...queries,
  },
};
