import React from 'react';
import PropTypes from 'prop-types';

import { FatalError } from 'shared-components/fatal-error';
import { Text } from 'shared-components/text';

import { Button } from 'reactstrap';

const ErrorBoundary = ({ icons, handleReload, handleErrorFeedback, hasError, children }) => {
  if (!hasError) {
    return children;
  }

  return (
    <FatalError title="Something went wrong on client side." description="Please try to reload." icons={icons}>
      <div />
      <div className="d-flex align-items-center">
        <Button onClick={handleReload} color="primary" className="rounded-oval px-5 mx-0_5">
          <Text>Reload</Text>
        </Button>
        {handleErrorFeedback && (
          <Button onClick={handleErrorFeedback} color="blue-dark" className="rounded-oval mx-0_5">
            <Text>Provide Feedback</Text>
          </Button>
        )}
      </div>
    </FatalError>
  );
};

ErrorBoundary.defaultProps = {
  hasError: false,
  handleErrorFeedback: null,
};

ErrorBoundary.propTypes = {
  hasError: PropTypes.bool,
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      size: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleReload: PropTypes.func.isRequired,
  handleErrorFeedback: PropTypes.func,
};

export default ErrorBoundary;
