import _ from 'lodash';

import { alertService } from 'core/alert';
import { tokenService } from 'core/token';
import { requestHeadersService } from 'core/request-headers';

import { MESSAGES } from 'core/constants/messages';
import { ALERT_COLOR } from 'core/constants/alert';
import { newRelicService } from 'core/new-relic';

const getContentType = options => {
  if (!options || !options.headers) {
    return '';
  }

  if (options.headers['Content-Type']) {
    return options.headers['Content-Type'];
  }

  if (options.headers.get) {
    return options.headers.get('Content-Type');
  }

  return '';
};

const processOptions = options => {
  const contentType = getContentType(options);

  if (contentType.includes('application/json')) {
    return {
      ...options,
      body: JSON.stringify(options.body),
    };
  }

  return options;
};

const processResponse = response => {
  const contentType = getContentType(response);

  if (contentType.includes('application/json')) {
    return response.json();
  }

  if (contentType.includes('application/octet-stream')) {
    return response.blob();
  }

  return response;
};

const fetch = async (api, options = {}) => {
  const token = tokenService.get() || null;
  const xArtifactVersion = requestHeadersService.xArtifactVersion();
  const xAuthToken = requestHeadersService.xAuthToken(token);

  const response = await window.fetch(api, {
    headers: {
      ...xAuthToken,
      ...xArtifactVersion,
      ...options.headers,
    },
    ..._.omit(processOptions(options), ['headers']),
  });

  if (!response.ok) {
    const error = await response.json();

    alertService.show({
      color: ALERT_COLOR.DANGER,
      text: (error && error.message) || MESSAGES.UNKNOWN_ERROR,
    });

    newRelicService.logError(error);
    throw error || new Error(response.statusText);
  }

  if (response.status === 204) {
    return null;
  }

  return processResponse(response);
};

export default fetch;
