import { INVENTORY_IDENTIFIERS_CLIENT_QUERY } from 'core/queries/inventory-search';

import { inventoryIdentifierService } from 'core/inventory-identifier';

import decorator from 'core/resolvers/graphql-type-decorator';

export default (obj, { input: { inquiryId, identifiers } }, { cache, getCacheKey }) => {
  const { inventoryIdentifiers: cachedIdentifiers } = cache.readQuery({
    query: INVENTORY_IDENTIFIERS_CLIENT_QUERY,
    variables: { inquiryId },
  });

  const newIdentifiers = inventoryIdentifierService.mergeInventoryIdentifiers(cachedIdentifiers, identifiers);

  cache.writeQuery({
    query: INVENTORY_IDENTIFIERS_CLIENT_QUERY,
    variables: {
      inquiryId,
    },
    data: {
      inventoryIdentifiers: {
        identifiers: decorator.inventoryIdentifiers(newIdentifiers),
        __typename: 'InventoryIdentifiersDto',
      },
    },
  });

  return null;
};
