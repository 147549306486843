import PropTypes from 'prop-types';

import { CANNED_MESSAGES_TYPE } from 'core/constants/widget-edit';

export default PropTypes.oneOf([
  CANNED_MESSAGES_TYPE.BUBBLE.id,
  CANNED_MESSAGES_TYPE.BUBBLES_WITHOUT_MESSAGE_BOX.id,
  CANNED_MESSAGES_TYPE.BUBBLE_FIRST.id,
  CANNED_MESSAGES_TYPE.CHECKBOX.id,
  CANNED_MESSAGES_TYPE.DROPDOWN.id,
  CANNED_MESSAGES_TYPE.MESSAGE_ONLY.id,
]);
