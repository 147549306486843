import PropTypes from 'prop-types';

import { DealerPropTypes } from 'core/prop-types';

import UserSchedule from './user-schedule';

export default PropTypes.shape({
  creatorId: PropTypes.number,
  currentDealer: DealerPropTypes.Dealer,
  dealers: PropTypes.arrayOf(DealerPropTypes.Dealer),
  email: PropTypes.string,
  id: PropTypes.number,
  isActive: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isEmailTextTranscript: PropTypes.bool,
  isOnline: PropTypes.bool,
  isSales: PropTypes.bool,
  isSendWelcomeEmail: PropTypes.bool,
  isService: PropTypes.bool,
  isSkipLeads: PropTypes.bool,
  name: PropTypes.string,
  notNotifyDealerOff: PropTypes.bool,
  notNotifyUserOff: PropTypes.bool,
  notificationPreference: PropTypes.number,
  phone: PropTypes.string,
  photoLink: PropTypes.string,
  schedule: UserSchedule,
  showCoachMarks: PropTypes.bool,
  userType: PropTypes.string,
  assignedInquiries: PropTypes.number,
  avgResponseTime: PropTypes.number,
  disableAllAccountsAppNotifications: PropTypes.bool,
  includeInCalloutPopup: PropTypes.bool,
  hasPushNotificationsSubscription: PropTypes.bool,
  pushNotificationsEnabled: PropTypes.bool,
  disableNewLeadsNotification: PropTypes.bool,
  disableExistingLeadsNotification: PropTypes.bool,
});
