import PropTypes from 'prop-types';

export default PropTypes.shape({
  createdAt: PropTypes.number,
  description: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  messageId: PropTypes.number,
  replyId: PropTypes.number,
  screenshotUrl: PropTypes.string,
  source: PropTypes.string,
  title: PropTypes.string,
  updatedAt: PropTypes.number,
  url: PropTypes.string,
  previewTitle: PropTypes.string,
});
