import Makes from './makes';
import Model from './model';
import TradeInVehicle from './trade-in-vehicle';
import Vehicle from './vehicle';

export default {
  Makes,
  Model,
  TradeInVehicle,
  Vehicle,
};
