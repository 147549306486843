import { PERMISSION } from 'core/constants/notification';

const createNotification = ({ title, options }) => new Notification(title, options);

const isPermissionGranted = () => Notification.permission === PERMISSION.GRANTED;

const isPermissionDenied = () => Notification.permission === PERMISSION.DENIED;

const requestPermission = ({ onGranted } = {}) => {
  Notification.requestPermission(result => {
    if (result === PERMISSION.GRANTED && onGranted) onGranted();
  });
};

const isNotificationSupported = () => window.Notification !== undefined;

export default {
  createNotification,
  isPermissionGranted,
  isPermissionDenied,
  requestPermission,
  isNotificationSupported,
};
