export const BUTTONS_BAR_HINT = {
  ADDRESS: {
    key: 'address',
    patterns: [/address/gi, /locat/gi],
    prompt:
      'We noticed that dealership location was mentioned in the conversation. Click the plus to send the customer a Google Maps link to your dealership address.',
  },
  CREDIT_APP_LINK: {
    key: 'credit_app_link',
    patterns: [/credit/gi, /financ/gi],
    prompt:
      'We noticed that financing was mentioned in the conversation. Click the plus to send a link to your credit application.',
  },
  SERVICE_APP_LINK: {
    key: 'service_app_link',
    patterns: [/servic/gi],
    prompt:
      'We noticed that service was mentioned in the conversation. Click the plus to send a link to your service scheduling tool.',
  },
  TRADE_IN_LINK: {
    key: 'trade_in_link',
    patterns: [/trade/gi],
    prompt:
      "We noticed that trade-in's were mentioned in the conversation. Click the plus to send a link to your trade-in tool link.",
  },
  TMV_REPORT: {
    key: 'tmv_report',
    patterns: [/value/gi],
    prompt:
      'We noticed that trade-in value was mentioned in the conversation. Click the plus to generate a true market value report for your customer.',
  },
};

export const BUTTONS_BAR_HINTS = Object.values(BUTTONS_BAR_HINT);

export const USED_HINTS_STORAGE_KEY = 'used-hints';
