import posed from 'react-pose';

const ShowAndHide = posed.div({
  enter: {
    height: ({ heightTo = 'auto' }) => heightTo,
    opacity: ({ opacityTo = 1 }) => opacityTo,
    transition: {
      ease: 'linear',
      duration: 250,
    },
  },
  exit: {
    height: ({ heightFrom = '0px' }) => heightFrom,
    opacity: ({ opacityFrom = 0 }) => opacityFrom,
    transition: {
      ease: 'linear',
      duration: 250,
    },
  },
});

export default ShowAndHide;
