import { ROUTES } from 'core/constants/routes';
import { USER_CHANNEL_EVENTS } from 'core/constants/web-socket';
import { PAGES } from 'core/constants/pages';

import { browserNotificationService } from 'core/browser-notification';
import { urlUtils } from 'core/utils/url';
import { notificationService } from 'core/notification';
import operatingSystem from 'core/operating-system/operating-system';

const onMessage =
  ({ dealerId, location, history, hideCustomerPhone }) =>
  message => {
    // if no data then skip
    if (!message.data) {
      return;
    }

    // if event not for this dealer then skip
    if (message.data.dealerId && message.data.dealerId !== dealerId) {
      return;
    }

    const messageData = message.data.msg || message.data;

    if (document.hasFocus() && location.pathname.indexOf(ROUTES.INQUIRIES) !== -1) {
      const inquiryId = urlUtils.getUrlParam('id');

      // if the page is in focus and the event is for the currently selected inquiry, then skip
      if (inquiryId && messageData && messageData.id && inquiryId.toString() === messageData.id.toString()) return;
    }

    if (message.event === USER_CHANNEL_EVENTS.MESSAGE) {
      if (!notificationService.isNotificationSupported() || operatingSystem.isAndroid()) {
        return;
      }
      browserNotificationService.notify({
        notification: browserNotificationService.getBrowserNotificationObject(messageData, hideCustomerPhone),
        onNotificationClick: () => {
          history.push(`${PAGES.INQUIRIES}?id=${messageData.id}`);
          window.focus();
        },
      });
    }
  };

export default {
  onMessage,
};
