import React from 'react';

import { userService } from 'core/user';
import { settingsService } from 'core/settings';

import { InquiriesNavigationCategories } from 'components/navigation/inquiries-navigation-categories';
import { NotificationsNavigation } from 'components/navigation/notifications-navigation';
import { Icon } from 'shared-components/icon';

export const NAVIGATION_ITEMS = [
  {
    to: '/inquiries',
    href: '/inquiries',
    icon: 'comments10',
    text: 'Inquiries',
    children: <InquiriesNavigationCategories />,
  },
  {
    to: '/dealers/users',
    href: '/dealers/users',
    icon: 'steering-wheel',
    text: 'Admin',
    isAdmin: true,
    navItems: [
      {
        to: '/dealers/users',
        href: '/dealers/users',
        icon: '',
        text: 'All Users',
      },
    ],
  },
  {
    to: '/dealers/reports',
    href: '/dealers/reports',
    icon: 'pulse2',
    text: 'Analytics',
    isCallCenterAdmin: true,
    navItems: [
      {
        to: '/dealers/reports',
        href: '/dealers/reports',
        icon: '',
        text: 'Reports',
      },
    ],
  },
  {
    to: '/dealers/reporting',
    href: '/dealers/reporting',
    icon: 'pulse2',
    text: 'Reporting',
    isAdmin: true,
    isDisabled: user => userService.isCallCenterAdmin(user),
    navItems: [
      {
        to: '/dealers/reporting',
        href: '/dealers/reporting',
        exact: true,
        icon: '',
        text: 'User Performance',
      },
      {
        to: '/dealers/reporting/leads-and-engagement',
        href: '/dealers/reporting/leads-and-engagement',
        exact: true,
        icon: '',
        text: 'Leads & Engagement',
      },
      {
        to: '/dealers/reporting/inquiries-to-leads',
        href: '/dealers/reporting/inquiries-to-leads',
        exact: true,
        icon: '',
        text: 'Inquiries to Leads',
      },
    ],
  },
  {
    to: '/dealers/settings',
    href: '/dealers/settings',
    icon: 'wrench3',
    text: 'Settings',
    isAdmin: true,
    isDisabled: user => userService.isCallCenterAdmin(user),
    navItems: [
      {
        to: '/dealers/settings',
        href: '/dealers/settings',
        exact: true,
        icon: '',
        text: 'General',
      },
      {
        to: '/dealers/settings/widget',
        href: '/dealers/settings/widget',
        exact: false,
        icon: '',
        text: 'Widget',
      },
      {
        to: '/dealers/settings/responses',
        href: '/dealers/settings/responses',
        isDisabled: user => settingsService.disablePreconfiguredResponses(user),
        exact: true,
        icon: '',
        text: 'Preset Responses',
      },
      {
        to: '/dealers/settings/messaging',
        href: '/dealers/settings/messaging',
        exact: true,
        icon: '',
        text: 'Messaging',
      },
      {
        to: '/dealers/settings/sales',
        href: '/dealers/settings/sales',
        exact: true,
        icon: '',
        text: 'Sales',
      },
      {
        to: '/dealers/settings/service',
        href: '/dealers/settings/service',
        exact: true,
        icon: '',
        text: 'Service',
      },
      {
        to: '/dealers/settings/specials',
        href: '/dealers/settings/specials',
        exact: true,
        icon: '',
        text: 'Specials',
      },
    ],
  },
  {
    to: '/dealers/settings/responses',
    href: '/dealers/settings/responses',
    icon: 'wrench3',
    text: 'Settings',
    isCallCenterAdmin: true,
    isDisabled: user => !userService.isCallCenterAdmin(user) || settingsService.disablePreconfiguredResponses(user),
    navItems: [
      {
        to: '/dealers/settings/responses',
        href: '/dealers/settings/responses',
        exact: true,
        icon: '',
        text: 'Preset Responses',
      },
    ],
  },
  {
    to: '/notifications',
    href: '/notifications',
    icon: 'bell2',
    exact: true,
    text: 'Notifications',
    component: <NotificationsNavigation />,
  },
  {
    to: '/help',
    href: '',
    icon: 'question3',
    text: 'Help',
    isButton: true,
    isDisabled: user => !userService.isDealerUserOrAdmin(user),
    navItems: [
      {
        isAdmin: true,
        to: '',
        href: '',
        icon: '',
        text: '',
        component: (
          <li className={'navigation-item w-100 mr-1 nav-item'}>
            <a
              className={'pl-3 navigation-item__link text-gray-darkest font-weight-bold py-0_5 d-block mr-1 pl-1'}
              href="https://dealersupport.edmunds.com/hc/en-us/?utm_source=carcode&utm_medium=helpmenu&utm_campaign=q4"
              target={'_blank'}
              rel={'noreferrer'}
            >
              Help Center <Icon className={'ml-0_5'} name={'new-tab1'} />
            </a>
          </li>
        ),
      },
      {
        to: '/help/resources',
        href: '/help/resources',
        icon: '',
        isAdmin: true,
        text: 'Resources',
      },
      {
        to: '/help/contact-us',
        href: '/help/contact-us',
        icon: '',
        isAdmin: true,
        exact: true,
        text: 'Contact Us',
      },
      {
        to: '/help/shortcuts',
        href: '/help/shortcuts',
        icon: '',
        isAdmin: true,
        exact: true,
        text: 'Keyboard Shortcuts',
      },
    ],
  },
  {
    to: '/help',
    href: '/help',
    icon: 'question3',
    text: 'Help',
    isDisabled: user => !userService.isCallCenterUserOrAdmin(user),
    navItems: [
      {
        to: '/help',
        href: '/help',
        icon: '',
        isAdmin: true,
        exact: true,
        text: 'Shortcuts',
      },
      {
        to: '/help/support',
        href: '/help/support',
        icon: '',
        isAdmin: true,
        isDisabled: user => !userService.isCallCenterAdmin(user),
        exact: true,
        text: 'Support',
      },
    ],
  },
];
