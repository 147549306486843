import React from 'react';
import { newRelicService } from 'core/new-relic';

import ErrorBoundary from './error-boundary.presentation';

class ErrorBoundaryContainer extends React.Component {
  state = {
    hasError: false,
    error: { message: '', stack: '' },
    info: { componentStack: '' },
  };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    newRelicService.logError(error, info.componentStack.toString());
    newRelicService.logsApi(error, info);
  }

  static icons = [
    {
      name: 'lightning2',
      size: '3rem',
    },
  ];

  handleReload = () => {
    window.location.reload();
  };

  render() {
    return (
      <ErrorBoundary
        {...this.props}
        icons={ErrorBoundaryContainer.icons}
        hasError={this.state.hasError}
        handleReload={this.handleReload}
      />
    );
  }
}

export default ErrorBoundaryContainer;
