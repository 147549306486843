import PropTypes from 'prop-types';

import Model from './model';

export default PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    models: PropTypes.arrayOf(Model),
    name: PropTypes.string,
    niceName: PropTypes.string,
  }),
);
