import React from 'react';
import PropTypes from 'prop-types';

import { newRelicService } from 'core/new-relic';

import Error, { ERROR_TYPE, ERROR_TYPES } from './error.presentation';

class ErrorContainer extends React.Component {
  static propTypes = {
    position: PropTypes.oneOf(['absolute', 'static']),
    propName: PropTypes.string,
    retryCount: PropTypes.number,
    refetch: PropTypes.func,
    type: PropTypes.oneOf(ERROR_TYPES),
    autoRefetch: PropTypes.bool,
  };

  static defaultProps = {
    position: 'absolute',
    propName: null,
    refetch: null,
    retryCount: 1,
    type: ERROR_TYPE.SERVER,
    autoRefetch: true,
  };

  state = {
    secondsToUpdate: 20,
    shouldAutomaticallyRefetch: this.props.retryCount < 4 && this.props.autoRefetch,
  };

  componentDidMount() {
    const { type } = this.props;
    if (this.state.shouldAutomaticallyRefetch) {
      this.setRefetchTimer();
    }

    if (type !== ERROR_TYPE.CHUNK) {
      newRelicService.logError(`ErrorContainer Type: ${type}`);
    }
  }

  componentWillUnmount() {
    this.cancelRefetchTimer();

    this.isUnmounted = true;
  }

  setRefetchTimer() {
    this.timerId = setTimeout(() => {
      this.refetch();
      this.timerId = null;
    }, 20000);

    this.intervalId = setInterval(() => {
      this.setState(state => ({
        secondsToUpdate: state.secondsToUpdate - 1,
      }));
    }, 1000);
  }

  cancelRefetchTimer() {
    if (this.timerId) {
      clearTimeout(this.timerId);

      this.timerId = null;
    }

    if (this.intervalId) {
      clearInterval(this.intervalId);

      this.intervalId = null;
    }
  }

  // we need to create a wrapper around refetch because of some apollo error when trying to call it without wrapper
  // https://github.com/apollographql/apollo-client/issues/1291
  refetch = async () => {
    this.cancelRefetchTimer();

    const { refetch, propName } = this.props;

    try {
      await (refetch || this.props[propName].refetch)();
    } finally {
      if (!this.isUnmounted) {
        this.setState({
          shouldAutomaticallyRefetch: false,
          secondsToUpdate: 0,
        });
      }
    }
  };

  render() {
    const { secondsToUpdate, shouldAutomaticallyRefetch } = this.state;
    const { position, type } = this.props;

    return (
      <Error
        secondsToUpdate={secondsToUpdate}
        shouldAutomaticallyRefetch={shouldAutomaticallyRefetch}
        handleRefetch={this.refetch}
        position={position}
        type={type}
      />
    );
  }
}

export default ErrorContainer;
