import PropTypes from 'prop-types';

export default PropTypes.shape({
  fridayHours: PropTypes.string,
  mondayHours: PropTypes.string,
  saturdayHours: PropTypes.string,
  sundayHours: PropTypes.string,
  thursdayHours: PropTypes.string,
  tuesdayHours: PropTypes.string,
  wednesdayHours: PropTypes.string,
});
