import { gql } from '@apollo/client';

export const ADD_BACKGROUND_INQUIRY_CLIENT_MUTATION = gql`
  mutation AddBackgroundInquiryClientMutation($input: Tab!) {
    addBackgroundInquiry(input: $input) @client
  }
`;

export const REMOVE_BACKGROUND_INQUIRY_CLIENT_MUTATION = gql`
  mutation RemoveBackgroundInquiryClientMutation($input: Tab!) {
    removeBackgroundInquiry(input: $input) @client
  }
`;

export const CLEAR_BACKGROUND_INQUIRIES_CLIENT_MUTATION = gql`
  mutation ClearBackgroundInquiriesClientMutation($input: Tab!) {
    clearBackgroundInquiries(input: $input) @client
  }
`;
