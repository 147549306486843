import resolverUtils from 'core/utils/resolver/resolver-utils';
import { VOI_FRAGMENT } from 'core/queries/inquiry-details';

import decorator from 'core/resolvers/graphql-type-decorator';

export default wsInquiryObj => {
  const result = resolverUtils.pickFromObject({
    query: VOI_FRAGMENT,
    object: wsInquiryObj,
  });

  return {
    getVOIByInquiryId: decorator.vehicle(result),
  };
};
