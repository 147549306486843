import _ from 'lodash';

const isObject = obj => typeof obj === 'object' && obj !== null;

const isEmpty = (obj, hiddenKeys = []) => {
  if (_.isEmpty(obj)) {
    return true;
  }

  const omittedObject = _.omit(obj, hiddenKeys.concat('__typename'));

  return _.isEmpty(_.pickBy(omittedObject, _.identity));
};

const checkProperty = (obj, property) => obj && Object.prototype.hasOwnProperty.call(obj, property);

const trimObjectValues = (obj = {}) =>
  Object.keys(obj).reduce(
    (accumulator, key) => ({
      ...accumulator,
      [key]: obj[key] && obj[key].trim ? obj[key].trim() : obj[key],
    }),
    {},
  );

export default {
  isObject,
  isEmpty,
  checkProperty,
  trimObjectValues,
};
