import { USER_PERMISSION } from 'core/constants/user';

export const ROUTES = {
  DEFAULT: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  ERROR: '/error',
  INQUIRIES: '/inquiries',
  USER_PASSWORD: '/users/password',
  USER_NEW_PASSWORD: '/users/password/new',
  ALL_USERS: '/dealers/users',
  EXISTING_USERS: '/dealers/users/existing',
  USER_NEW: '/dealers/users/new',
  USER_BY_ID: '/dealers/users/:userId',
  SETTINGS: '/dealers/settings',
  WIDGET_SETTINGS: '/dealers/settings/widget',
  WIDGET_NEW: '/dealers/settings/widget/new',
  WIDGET_BY_ID: '/dealers/settings/widget/:widgetId',
  MESSAGING_SETTINGS: '/dealers/settings/messaging',
  SALES_SETTINGS: '/dealers/settings/sales',
  SERVICE_SETTINGS: '/dealers/settings/service',
  PRECONFIGURED_RESPONSES: '/dealers/settings/responses',
  SPECIALS: '/dealers/settings/specials',
  REPORTS: '/dealers/reports',
  REPORT_BY_NAME: '/dealers/reports/:reportName',
  NOTIFICATIONS: '/notifications',
  HELP: '/help',
  CONTACT_US: '/help/contact-us',
  SHORTCUTS: '/help/shortcuts',
  RESOURCES: '/help/resources',
  SUPPORT: '/help/support',
  SESSION_EXPIRED: '/session-expired',
  DEALER_REPORTING_USER_PERFORMANCE: '/dealers/reporting',
  DEALER_REPORTING_LEADS_AND_ENGAGEMENT: '/dealers/reporting/leads-and-engagement',
  DEALER_REPORTING_INQUIRIES_TO_LEADS: '/dealers/reporting/inquiries-to-leads',
  QUICK_START: '/quick-start',
  SDK_INSTRUCTIONS: '/sdk-instructions',
  BEST_PRACTICES: '/best-practices',
  PAGES: '/pages',
  FAVICON: '/favicon.ico',
  SITEMAP: '/sitemap.xml',
};

export const REPORT_ROUTE = {
  LEADS_AND_APPOINTMENTS: `${ROUTES.REPORTS}/leads-and-appointments`,
  LONG_RESPONSE: `${ROUTES.REPORTS}/long-response`,
  USER_PERFORMANCE: `${ROUTES.REPORTS}/user-performance`,
};

export const ROUTE_PERMISSIONS = {
  [ROUTES.ALL_USERS]: {
    some: [USER_PERMISSION.ADMIN, USER_PERMISSION.CALL_CENTER_ADMIN],
  },
  [ROUTES.USER_NEW]: {
    some: [USER_PERMISSION.ADMIN, USER_PERMISSION.CALL_CENTER_ADMIN],
  },
  [ROUTES.REPORTS]: {
    some: [USER_PERMISSION.CALL_CENTER_ADMIN],
  },
  [ROUTES.REPORT_BY_NAME]: {
    some: [USER_PERMISSION.CALL_CENTER_ADMIN],
  },
  [ROUTES.SUPPORT]: {
    some: [USER_PERMISSION.CALL_CENTER_ADMIN],
  },
  [ROUTES.SETTINGS]: {
    some: [USER_PERMISSION.ADMIN],
    not: [USER_PERMISSION.CALL_CENTER_ADMIN],
  },
  [ROUTES.WIDGET_SETTINGS]: {
    some: [USER_PERMISSION.ADMIN],
    not: [USER_PERMISSION.CALL_CENTER_ADMIN],
  },
  [ROUTES.WIDGET_NEW]: {
    some: [USER_PERMISSION.ADMIN],
    not: [USER_PERMISSION.CALL_CENTER_ADMIN],
  },
  [ROUTES.WIDGET_BY_ID]: {
    some: [USER_PERMISSION.ADMIN],
    not: [USER_PERMISSION.CALL_CENTER_ADMIN],
  },
  [ROUTES.PRECONFIGURED_RESPONSES]: {
    some: [USER_PERMISSION.ADMIN, USER_PERMISSION.CALL_CENTER_ADMIN],
  },
  [ROUTES.MESSAGING_SETTINGS]: {
    some: [USER_PERMISSION.ADMIN],
    not: [USER_PERMISSION.CALL_CENTER_ADMIN],
  },
  [ROUTES.SALES_SETTINGS]: {
    some: [USER_PERMISSION.ADMIN],
    not: [USER_PERMISSION.CALL_CENTER_ADMIN],
  },
  [ROUTES.SERVICE_SETTINGS]: {
    some: [USER_PERMISSION.ADMIN],
    not: [USER_PERMISSION.CALL_CENTER_ADMIN],
  },
  [ROUTES.SPECIALS]: {
    some: [USER_PERMISSION.ADMIN],
    not: [USER_PERMISSION.CALL_CENTER_ADMIN],
  },
  [ROUTES.DEALER_REPORTING_USER_PERFORMANCE]: {
    some: [USER_PERMISSION.ADMIN],
    not: [USER_PERMISSION.CALL_CENTER_ADMIN],
  },
  [ROUTES.DEALER_REPORTING_LEADS_AND_ENGAGEMENT]: {
    some: [USER_PERMISSION.ADMIN],
    not: [USER_PERMISSION.CALL_CENTER_ADMIN],
  },
  [ROUTES.DEALER_REPORTING_INQUIRIES_TO_LEADS]: {
    some: [USER_PERMISSION.ADMIN],
    not: [USER_PERMISSION.CALL_CENTER_ADMIN],
  },
};

export const ROUTE_ONLY_OWN_ID_PERMISSIONS = {
  [ROUTES.USER_BY_ID]: {
    some: [USER_PERMISSION.USER, USER_PERMISSION.AGENT, USER_PERMISSION.AE],
    not: [USER_PERMISSION.ADMIN, USER_PERMISSION.CALL_CENTER_ADMIN],
  },
};
