import { GET_USER_PROFILE_QUERY } from 'core/queries/user';

export default (obj, { input: { userId, user } }, { cache }) => {
  const cachedUser = cache.readQuery({
    query: GET_USER_PROFILE_QUERY,
    variables: { userId },
  });

  cache.writeQuery({
    query: GET_USER_PROFILE_QUERY,
    variables: { userId },
    data: {
      getUserProfile: {
        ...cachedUser.getUserProfile,
        ...user,
      },
    },
  });

  return null;
};
