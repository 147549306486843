import PropTypes from 'prop-types';

export default PropTypes.shape({
  condition: PropTypes.string,
  inquiryId: PropTypes.number,
  make: PropTypes.string,
  mileage: PropTypes.number,
  model: PropTypes.string,
  year: PropTypes.number,
  zip: PropTypes.string,
  vin: PropTypes.string,
});
