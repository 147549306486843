import _ from 'lodash';

import { vehicleStorage } from 'core/storages/vehicle';

const extendMessageWithVehicle = (message, vehicle = vehicleStorage.get(message.id)) => {
  let result = message;

  if (vehicle) {
    result = _.merge(message, {
      vehicle,
    });
  }

  return result;
};

const saveVehicle = ({ messageId, vehicle }) =>
  vehicleStorage.push({
    messageId,
    vehicle,
  });

export default {
  extendMessageWithVehicle,
  saveVehicle,
};
