import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';

import { UserPropTypes } from 'core/prop-types';

import { withLoggedUser } from 'core/hocs/logged-user';
import { withReadOnly } from 'core/hocs/read-only';

import { userService } from 'core/user';

import Header from './header.presentation';

import './header.styles.scss';

function HeaderContainer({ loggedUser, readOnly }) {
  const isDealerUser = userService.hasDealerPermissions({ user: loggedUser });

  return <Header isDealerUser={isDealerUser} loggedUser={loggedUser} readOnly={readOnly} />;
}

HeaderContainer.propTypes = {
  loggedUser: UserPropTypes.LoggedUser.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export { HeaderContainer };

export default compose(withLoggedUser, withReadOnly)(HeaderContainer);
