import { gql } from '@apollo/client';

const AUTO_ASSIGNMENT_FRAGMENT = gql`
  fragment AutoAssignment on SecurityUserDto {
    id
    autoAssignmentEnabled
  }
`;

const ENABLE_AUTO_ASSIGNMENT_MUTATION = gql`
  mutation EnableAutoAssignment($input: Long!) {
    enableAutoAssignment(userId: $input) {
      ...AutoAssignment
    }
  }
  ${AUTO_ASSIGNMENT_FRAGMENT}
`;

const DISABLE_AUTO_ASSIGNMENT_MUTATION = gql`
  mutation DisableAutoAssignment($input: Long!) {
    disableAutoAssignment(userId: $input) {
      ...AutoAssignment
    }
  }
  ${AUTO_ASSIGNMENT_FRAGMENT}
`;

export { ENABLE_AUTO_ASSIGNMENT_MUTATION, DISABLE_AUTO_ASSIGNMENT_MUTATION };
