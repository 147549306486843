import { TYPENAME } from 'core/constants/typename';

import { removeInquiryFromListByCategory } from './utils';

export default (obj, { input: { id: inquiryId, user, category } }, { cache, getCacheKey, client }) => {
  const cacheId = getCacheKey({ __typename: TYPENAME.INQUIRY, id: inquiryId });

  if (!category) {
    return null;
  }

  removeInquiryFromListByCategory({
    category,
    inquiryId,
    loggedUser: user,
    cache,
    cacheId,
  });

  return null;
};
