import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Route } from 'react-router-dom';

import { Button, NavItem } from 'reactstrap';

import { Text } from 'shared-components/text';
import { Icon } from 'shared-components/icon';
import { RouterNavLink } from 'shared-components/router-nav-link';

import './navigation-item.styles.scss';

const NavigationItem = ({
  to,
  href,
  icon,
  text,
  collapsed,
  onClick,
  className,
  linkClassName,
  renderChildren,
  exact,
  redCircle,
  isButton,
  isOpened,
  ...props
}) => {
  // eslint-disable-next-line react/no-children-prop
  const route = <Route path={to} children={renderChildren} />;

  return (
    <NavItem key={to} className={`navigation-item ${className}`}>
      {isButton && (
        <>
          <Button
            onClick={onClick}
            className={classNames(
              linkClassName,
              'button navigation-item__link text-gray-darkest font-weight-bold py-0_5 d-block mr-1 pl-1 pr-0',
              { 'bg-gray-light': isOpened, collapsed },
            )}
          >
            {!collapsed ? (
              <Text icon={icon} className="font-size-m">
                {text}
              </Text>
            ) : (
              <Icon name={icon} />
            )}
          </Button>
          {!collapsed && route}
        </>
      )}
      {!isButton && (
        <>
          <RouterNavLink
            to={to}
            href={href}
            onClick={onClick}
            exact={exact}
            className={classNames(
              linkClassName,
              'navigation-item__link text-gray-darkest font-weight-bold py-0_5 d-block mr-1 pl-1',
              { 'text-center': collapsed },
            )}
            activeClassName="bg-gray-light"
          >
            {redCircle ? <span className={classNames('red-circle', { collapsed })} /> : null}

            {!collapsed ? (
              <Text icon={icon} className="font-size-m">
                {text}
              </Text>
            ) : (
              <Icon name={icon} />
            )}
          </RouterNavLink>
          {!collapsed && route}
        </>
      )}
    </NavItem>
  );
};

NavigationItem.propTypes = {
  to: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
  collapsed: PropTypes.bool,
  className: PropTypes.string,
  linkClassName: PropTypes.string,
  renderChildren: PropTypes.func,
  exact: PropTypes.bool,
  redCircle: PropTypes.bool,
  isButton: PropTypes.bool,
  isOpened: PropTypes.bool,
};

NavigationItem.defaultProps = {
  collapsed: false,
  exact: false,
  icon: '',
  className: '',
  linkClassName: '',
  onClick: () => null,
  renderChildren: () => null,
  redCircle: false,
  isButton: false,
  isOpened: false,
};

export default NavigationItem;
