import { SYSTEM_EVENTS, CONFIG, WS_EVENT } from 'core/constants/web-socket';

export default WebSocketImpl =>
  class PingPongWebsocket extends WebSocketImpl {
    constructor(...args) {
      super(...args);

      this.registerEvent(WS_EVENT.OPEN, this.registerPingPong);
      this.registerEvent(WS_EVENT.CLOSE, this.unregisterPingPong);
    }

    destroy() {
      clearTimeout(this.pongTimeout);
      clearInterval(this.interval);

      super.destroy();
    }

    isEmpty = () => {
      const onMessageSubscribtions = this.events[WS_EVENT.MESSAGE];
      return super.isEmpty() || (onMessageSubscribtions.length === 1 && onMessageSubscribtions[0] === this.onPong);
    };

    registerPingPong = () => {
      this.registerEvent(WS_EVENT.MESSAGE, this.onPong);

      this.interval = setInterval(this.sendPing, CONFIG.PING_INTERVAL);
      this.sendPing();
    };

    unregisterPingPong = () => {
      clearTimeout(this.pongTimeout);
      clearInterval(this.interval);

      this.unregisterEvent(WS_EVENT.MESSAGE, this.onPong);
    };

    checkPong = () => {
      if (this.hasPong) {
        return;
      }

      super.reconnect();
    };

    onPong = message => {
      switch (message.event) {
        case SYSTEM_EVENTS.PONG: {
          this.hasPong = true;
          break;
        }
        default: {
          break;
        }
      }
    };

    sendPing = () => {
      this.hasPong = false;

      this.sendData({
        event: SYSTEM_EVENTS.PING,
      });

      this.pongTimeout = setTimeout(this.checkPong, CONFIG.PONG_WAIT_TIME);
    };
  };
