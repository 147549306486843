import PropTypes from 'prop-types';

const Notification = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  iconUrl: PropTypes.string,
  status: PropTypes.string,
  createdAt: PropTypes.number,
});

const UserNotification = PropTypes.shape({
  featureNotificationId: PropTypes.number,
  seen: PropTypes.bool,
});

const SortedNotification = PropTypes.shape({
  id: PropTypes.number,
  iconUrl: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  state: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  createdAt: PropTypes.number,
  date: PropTypes.string,
  seen: PropTypes.bool,
});

export default { Notification, UserNotification, SortedNotification };
