import PropTypes from 'prop-types';

import { VehiclePropTypes, DealerPropTypes } from 'core/prop-types';

import HandoffData from './handoff-data';

export default PropTypes.shape({
  vehicle: VehiclePropTypes.Vehicle,
  vehicleDealer: DealerPropTypes.Dealer,
  handoffData: HandoffData,
});
