import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { Header } from 'components/header';
import { Navigation } from 'components/navigation';
import { MediaQuery } from 'shared-components/media-query';

import './page.styles.scss';

const Page = ({ showHeader, showNavigation, children }) => (
  <section className="w-100 h-100 d-flex flex-column page">
    {showHeader && <Header />}
    <div className="page-body w-100 d-flex flex-grow-1">
      <MediaQuery query="lg-up">{showNavigation && <Navigation />}</MediaQuery>
      <div className={classNames('flex-grow-1 overflow-y-hide mh-100 position-relative')}>{children}</div>
    </div>
  </section>
);

Page.defaultProps = {
  showHeader: false,
  showNavigation: false,
  isNavigationCollapsed: false,
};

Page.propTypes = {
  showHeader: PropTypes.bool,
  showNavigation: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Page;
