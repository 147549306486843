import React from 'react';
import PropTypes from 'prop-types';

export default Component => formatter => {
  const TextFormatter = ({ text, ...props }) => <Component {...props}>{formatter(text)}</Component>;

  TextFormatter.defaultProps = {
    text: '',
  };

  TextFormatter.propTypes = {
    text: PropTypes.string,
  };

  return TextFormatter;
};
