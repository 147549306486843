import { ACTIVE_CATEGORY_ID_QUERY } from 'core/queries/inquiries-categories';

import { inquiriesCategoryService } from 'core/inquiries-category';

export default (obj, { input: { id } }, { cache }) => {
  cache.writeQuery({
    query: ACTIVE_CATEGORY_ID_QUERY,
    data: { activeCategoryId: id },
  });

  inquiriesCategoryService.save(id);

  return null;
};
