import { operatingSystemService } from 'core/operating-system';

import {
  KEYBOARD_SHORTCUTS_MAP_MAC,
  KEYBOARD_SHORTCUTS_MAP_WINDOWS,
  COMMANDS_DELIMITER,
} from 'core/constants/keyboard-shortcuts';

const INQUIRY_NAV_ITEM_SELECTOR = '.inquiry-navigation-item';
const INQUIRY_NAV_ITEM_ACTIVE_SELECTOR = '.inquiry-navigation-item--active';
const INQUIRY_NAV_ITEM_ACTIVE_CLASS = 'inquiry-navigation-item--active';

const handleSelectInquiry = (event, number) => {
  event.preventDefault();
  const inquiryListElement = document.querySelectorAll(INQUIRY_NAV_ITEM_SELECTOR)[number - 1];
  if (inquiryListElement) inquiryListElement.click();
};

const handleGetNextOrPreviousInquiry = (event, direction) => {
  event.preventDefault();
  const inquiryListItems = document.querySelectorAll(INQUIRY_NAV_ITEM_SELECTOR);
  const selectedInquiryListElement = document.querySelector(INQUIRY_NAV_ITEM_ACTIVE_SELECTOR);
  const firstInquiry = inquiryListItems[0];

  if (!selectedInquiryListElement && firstInquiry) {
    firstInquiry.click();
  } else {
    let index = 0;
    for (let i = 0; i < inquiryListItems.length; i++) {
      if (inquiryListItems[i].classList.contains(INQUIRY_NAV_ITEM_ACTIVE_CLASS)) {
        index = i;
        break;
      }
    }

    let targetElement;
    switch (direction) {
      case 'next':
        targetElement = inquiryListItems[index + 1];
        break;
      case 'previous':
        targetElement = inquiryListItems[index - 1];
        break;
      default:
        break;
    }
    if (targetElement) targetElement.click();
  }
};

const getKeyboardShortcutsMap = () => {
  let keyboardShortcutsMap;
  if (operatingSystemService.isMac()) {
    keyboardShortcutsMap = KEYBOARD_SHORTCUTS_MAP_MAC;
  } else {
    keyboardShortcutsMap = KEYBOARD_SHORTCUTS_MAP_WINDOWS;
  }

  return keyboardShortcutsMap;
};

const filterVisibleShortcuts = (loggedUser, shortcutItems) =>
  shortcutItems.map(block => {
    const items = block.items.filter(shortcut => !shortcut.isHidden || !shortcut.isHidden(loggedUser));
    return { ...block, items };
  });

const getDefaultHandlers = () => ({
  SELECT_INQUIRY_1: e => handleSelectInquiry(e, 1),
  SELECT_INQUIRY_2: e => handleSelectInquiry(e, 2),
  SELECT_INQUIRY_3: e => handleSelectInquiry(e, 3),
  SELECT_INQUIRY_4: e => handleSelectInquiry(e, 4),
  SELECT_INQUIRY_5: e => handleSelectInquiry(e, 5),
  SELECT_INQUIRY_6: e => handleSelectInquiry(e, 6),
  SELECT_INQUIRY_7: e => handleSelectInquiry(e, 7),
  SELECT_INQUIRY_8: e => handleSelectInquiry(e, 8),
  SELECT_INQUIRY_9: e => handleSelectInquiry(e, 9),
  NEXT_INQUIRY: e => handleGetNextOrPreviousInquiry(e, 'next'),
  PREVIOUS_INQUIRY: e => handleGetNextOrPreviousInquiry(e, 'previous'),
});

const getCommands = shortcut => {
  if (!shortcut) {
    return [];
  }

  return shortcut.split(COMMANDS_DELIMITER);
};

export default {
  getKeyboardShortcutsMap,
  getDefaultHandlers,
  getCommands,
  handleSelectInquiry,
  handleGetNextOrPreviousInquiry,
  filterVisibleShortcuts,
};
