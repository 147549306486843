import React from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';

import { POSITION } from 'core/constants/sidebar';

import Sidebar from './sidebar.presentation';
import './sidebar.styles.scss';

/**
 * Sidebar component.
 *
 * Draws sidebar with animation on the screen.
 * Open state is contolled by the parent component and provided via 'open' property.
 * Sidebar can be displayed on left, right and as a burger menu.
 *
 */
class SidebarContainer extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    position: PropTypes.oneOf(Object.values(POSITION)),
    handleToggle: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: null,
    open: false,
    position: POSITION.LEFT,
  };

  constructor(props) {
    super(props);

    this.createContainer();
  }

  componentWillUnmount() {
    this.removeContainer();
  }

  handleBackdropClick = () => {
    this.props.handleToggle();
  };

  createContainer = () => {
    const { position } = this.props;

    this.container = document.createElement('section');
    this.container.classList.add('position-absolute', 'sidebar', `sidebar--${position}`, 'animated');

    document.body.appendChild(this.container);
  };

  removeContainer = () => {
    if (!this.container) {
      return;
    }

    document.body.removeChild(this.container);
    this.container = null;
  };

  render() {
    const { className, open, position, children } = this.props;

    return ReactDOM.createPortal(
      <Sidebar open={open} className={className} handleBackdropClick={this.handleBackdropClick} position={position}>
        {children}
      </Sidebar>,
      this.container,
    );
  }
}

export default SidebarContainer;
