import React from 'react';
import PropTypes from 'prop-types';

import { CollapseIcon } from 'shared-components/collapse-icon';
import { Text } from 'shared-components/text';

import { Button } from 'reactstrap';

const CollapseButton = ({ direction, full, ...props }) => (
  <Button {...props} className={props.className}>
    <CollapseIcon direction={direction} />
    {full && <Text>Collapse sidebar</Text>}
  </Button>
);

CollapseButton.defaultProps = {
  className: null,
  direction: 'left',
  full: false,
};

CollapseButton.propTypes = {
  full: PropTypes.bool,
  className: PropTypes.string,
  direction: PropTypes.oneOf(['left', 'right']),
};

export default CollapseButton;
