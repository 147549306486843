import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { keyboardShortcutsManager } from 'core/keyboard-shortcuts-manager';

const KeyboardShortcutsContainer = ({ handlers, pauseOtherEvents }) => {
  useEffect(() => {
    keyboardShortcutsManager.registerHandlers(handlers, pauseOtherEvents);

    return () => {
      Object.keys(handlers).forEach(name => keyboardShortcutsManager.removeHandler(name));
      if (pauseOtherEvents) keyboardShortcutsManager.resumePausedEvents();
    };
  }, []);

  return null;
};

KeyboardShortcutsContainer.defaultProps = {
  handlers: {},
  pauseOtherEvents: false,
};

KeyboardShortcutsContainer.propTypes = {
  handlers: PropTypes.shape({}),
  pauseOtherEvents: PropTypes.bool,
};

export { KeyboardShortcutsContainer };

export default KeyboardShortcutsContainer;
