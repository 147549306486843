import { INQUIRY_CONVERSATION_SUMMARY_FRAGMENT } from 'core/queries/inquiry';

import resolverUtils from 'core/utils/resolver/resolver-utils';
import decorator from 'core/resolvers/graphql-type-decorator';

export default wsConversationSummaryObject => {
  let result = resolverUtils.pickFromObject({
    query: INQUIRY_CONVERSATION_SUMMARY_FRAGMENT,
    object: wsConversationSummaryObject,
  });

  result = { ...decorator.conversationSummary(result) };

  if (result.managedByUsers) {
    result.managedByUsers = result.managedByUsers.map(dataObj => decorator.userBase(dataObj));
  }

  return result;
};
