import merge from 'lodash/merge';

import { INQUIRY_QUERY } from 'core/queries/inquiry';

import inquiryConverter from './graphql-inquiry-converter';

export default (obj, { input: { inquiry: newInquiry, inquiryId } }, { cache }) => {
  const { inquiry: cachedInquiry } = cache.readQuery({
    query: INQUIRY_QUERY,
    variables: { inquiryId },
  });

  const nextCachedInquiry = inquiryConverter(merge({}, cachedInquiry, newInquiry));

  cache.writeQuery({
    query: INQUIRY_QUERY,
    variables: {
      inquiryId,
    },
    data: {
      inquiry: nextCachedInquiry,
    },
  });

  return null;
};
