import { TYPENAME } from 'core/constants/typename';

import { removeInquiryFromRelatedLists, removeInquiryFromCompletedList } from './utils';

export default (obj, { input: { inquiry, inquiryId, user } }, { cache, getCacheKey }) => {
  const cacheId = getCacheKey({ __typename: TYPENAME.INQUIRY, id: inquiryId });

  if (inquiry.conversationCompleted) {
    removeInquiryFromCompletedList({
      inquiryId,
      loggedUser: user,
      cache,
      cacheId,
    });
  } else {
    removeInquiryFromRelatedLists({
      inquiryId,
      loggedUser: user,
      cache,
      cacheId,
    });
  }

  return null;
};
