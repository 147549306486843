import { gql } from '@apollo/client';

export const INQUIRIES_STRUCTURE_QUERY = gql`
  query InquiriesStructureQuery(
    $dealerIds: [Long!]
    $filters: FiltersDtoInput
    $includeAll: Boolean = false
    $includeAssignedToMe: Boolean = false
    $includeAssigned: Boolean = false
    $includeUnassigned: Boolean = false
    $includeManaged: Boolean = false
    $includeOutbound: Boolean = false
    $includeArchived: Boolean = true
    $includeUnanswered: Boolean = true
  ) {
    inquiriesStructure(dealerIds: $dealerIds, filters: $filters) {
      all @include(if: $includeAll) {
        total
      }
      assignedToOthers @include(if: $includeAssigned) {
        total
      }
      unassigned @include(if: $includeUnassigned) {
        total
      }
      assignedToMe @include(if: $includeAssignedToMe) {
        total
      }
      managed @include(if: $includeManaged) {
        total
      }
      outbound @include(if: $includeOutbound) {
        total
      }
      archived @include(if: $includeArchived) {
        total
      }
      unanswered @include(if: $includeUnanswered) {
        total
      }
    }
  }
`;
