import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { Icon } from 'shared-components/icon';

import './collapse-icon.styles.scss';

const CollapseIcon = ({ direction, className, size }) => (
  <div className={classNames('d-flex align-items-center', className)}>
    <Icon name={`arrow-${direction}3`} size={size} />
    <Icon className="collapse-icon--move text-gray-darker" name={`arrow-${direction}3`} size={size} />
  </div>
);

CollapseIcon.defaultProps = {
  direction: 'left',
  className: null,
  size: '1rem',
};

CollapseIcon.propTypes = {
  direction: PropTypes.oneOf(['left', 'right', 'up', 'down']),
  className: PropTypes.string,
  size: PropTypes.string,
};

export default CollapseIcon;
