import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { useReactRouterLink } from 'core/hooks/useReactRouterLink';

const RouterLink = ({ onClick, ...restProps }) => {
  const { onLinkClick } = useReactRouterLink({ to: restProps.to, onClick });
  return <Link {...restProps} onClick={onLinkClick} />;
};

RouterLink.propTypes = {
  onClick: PropTypes.func,
};

RouterLink.defaultProps = {
  onClick: null,
};

export default RouterLink;
