import React from 'react';

import PropTypes from 'prop-types';
import cn from 'classnames';

import { Row, Col } from 'reactstrap';

import { withLoggedUser } from 'core/hocs/logged-user';

import { KeyboardButton } from 'shared-components/keyboard-button';

import { KEYBOARD_SHORTCUTS_HELP } from 'core/constants/keyboard-shortcuts';
import { keyboardShortcutsService } from 'core/keyboard-shortcuts';

import './keyboard-shortcuts-help.styles.scss';

const shortcuts = keyboardShortcutsService.getKeyboardShortcutsMap();

const renderButtons = (shortcutKey, variant) =>
  keyboardShortcutsService.getCommands(shortcuts[shortcutKey]).map(command => (
    <KeyboardButton key={command} variant={variant} className="mr-0_25 text-capitalize">
      {command}
    </KeyboardButton>
  ));

const KeyboardShortcutsHelp = ({ variant, headerClassName, headerTextClassName, loggedUser }) => {
  const filteredShortcuts = keyboardShortcutsService.filterVisibleShortcuts(loggedUser, KEYBOARD_SHORTCUTS_HELP);

  const keybordStyles = cn('keyboard-shortcuts-help', `keyboard-shortcuts-help--${variant}`);

  const headerStyles = cn('d-flex align-items-center', headerClassName);

  const headerTextStyles = cn('text-center mb-0 mr-2 font-size-xxl', headerTextClassName);

  return (
    <div className={keybordStyles}>
      <div className={headerStyles}>
        <h2 className={headerTextStyles}>Keyboard Shortcuts</h2>
        {renderButtons('SHOW_HELP', variant)}
      </div>
      <Row>
        {filteredShortcuts.map(keyboardShortcutsHelpEntry => (
          <Col xs="12" key={keyboardShortcutsHelpEntry.title} className="mb-1_5 mb-lg-0 pb-1_5">
            <h5 className="font-size-xl font-weight-semibold mb-1">{keyboardShortcutsHelpEntry.title}</h5>
            <ul className="list-unstyled m-0 p-0">
              {keyboardShortcutsHelpEntry.items.map(({ shortcutKey, description }) => (
                <li className="d-sm-flex align-items-center flex-wrap" key={shortcutKey}>
                  <span className="mr-0_5">{description}: </span>
                  <div className="mt-0_5 mt-sm-0">{renderButtons(shortcutKey, variant)}</div>
                </li>
              ))}
            </ul>
          </Col>
        ))}
      </Row>
    </div>
  );
};

KeyboardShortcutsHelp.defaultProps = {
  variant: 'light',
  headerClassName: 'justify-content-center mb-1_5',
  headerTextClassName: 'font-weight-semibold',
};

KeyboardShortcutsHelp.propTypes = {
  variant: PropTypes.oneOf(['gray', 'light']),
  headerClassName: PropTypes.string,
  headerTextClassName: PropTypes.string,
};

export { KeyboardShortcutsHelp };

export default withLoggedUser(KeyboardShortcutsHelp);
