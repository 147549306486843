import resolverUtils from 'core/utils/resolver/resolver-utils';
import { INQUIRIES_LIST_INQUIRY_FRAGMENT } from 'core/queries/inquiries';

import decorator from 'core/resolvers/graphql-type-decorator';

export default wsInquiryObj => {
  const result = resolverUtils.pickFromObject({
    query: INQUIRIES_LIST_INQUIRY_FRAGMENT,
    object: wsInquiryObj,
  });

  return decorator.inquiry(result);
};
