import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from 'reactstrap';

import { Text } from 'shared-components/text';

import './error.styles.scss';

export const ERROR_TYPE = {
  SERVER: 'server',
  CLIENT: 'client',
  CHUNK: 'chunk',
};

export const ERROR_TYPES = Object.values(ERROR_TYPE);

// eslint-disable-next-line react/prop-types
const renderErrorDescription = ({ type }) => {
  if (type === ERROR_TYPE.SERVER) {
    return (
      <>
        We&#39;re having trouble
        <br />
        connecting to the server.
        <br />
      </>
    );
  }

  if (type === ERROR_TYPE.CLIENT) {
    return (
      <>
        A client error occurred
        <br />
        while displaying the interface.
        <br />
      </>
    );
  }

  if (type === ERROR_TYPE.CHUNK) {
    return (
      <>
        Looks like a new version of the application
        <br />
        has been deployed recently.
        <br />
        <br />
        Please, click on the button below
        <br />
        to continue working with the application:
        <br />
      </>
    );
  }

  return null;
};

// eslint-disable-next-line react/prop-types
const renderRefetchContent = ({ type, secondsToUpdate }) => {
  if (type === ERROR_TYPE.SERVER) {
    return (
      <>
        <br />
        We&#39;ll automatically re-attempt
        <br />
        to connect in <Text color="primary">{secondsToUpdate}</Text> seconds.
        <br />
        <br />
        Otherwise, you can also
        <br />
        re-attempt connecting here:
        <br />
      </>
    );
  }

  if (type === ERROR_TYPE.CLIENT) {
    return (
      <>
        <br />
        We&#39;ll automatically re-attempt
        <br />
        to rerender the interface in <Text color="primary">{secondsToUpdate}</Text> seconds.
        <br />
        <br />
        Otherwise, you can also
        <br />
        re-attempt rendering here:
        <br />
      </>
    );
  }

  return null;
};

const renderActions = ({ type, handleRefetch }) => {
  if (type === ERROR_TYPE.CHUNK) {
    return (
      <Button color="primary" onClick={handleRefetch} className="rounded-oval">
        <Text icon="rotate-ccw2">Upgrade Application</Text>
      </Button>
    );
  }

  return (
    <Button color="primary" onClick={handleRefetch} className="rounded-oval">
      <Text icon="rotate-ccw2">Re-Attempt</Text>
    </Button>
  );
};

const Error = ({ shouldAutomaticallyRefetch, secondsToUpdate, position, type, handleRefetch }) => (
  <div
    className={classNames('error text-center d-flex flex-column align-items-center w-100 fadeIn', `error--${position}`)}
  >
    <Text tag="h3" className="error-title px-1 py-0_75 text-danger border border-gray-light mb-1">
      Something went wrong.
    </Text>
    <Text tag="p" className="mb-1">
      {renderErrorDescription({ type })}
      {shouldAutomaticallyRefetch && renderRefetchContent({ type, secondsToUpdate })}
    </Text>
    {renderActions({ type, handleRefetch })}
  </div>
);

Error.defaultProps = {
  shouldAutomaticallyRefetch: false,
};

Error.propTypes = {
  position: PropTypes.oneOf(['absolute', 'static']).isRequired,
  shouldAutomaticallyRefetch: PropTypes.bool,
  secondsToUpdate: PropTypes.number.isRequired,
  type: PropTypes.oneOf(ERROR_TYPES).isRequired,
  handleRefetch: PropTypes.func.isRequired,
};

export default Error;
