const getChannelFromUrl = url => {
  if (!url) {
    return 'unknown';
  }

  if (url.includes('/inquiry/')) {
    return 'inquiry';
  }

  if (url.includes('/user/')) {
    return 'user';
  }

  if (url.includes('/callcenter/') || url.includes('/call-center/')) {
    return 'call-center';
  }

  return 'unknown';
};

export default {
  getChannelFromUrl,
};
