import PropTypes from 'prop-types';

export default PropTypes.shape({
  columnNames: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      values: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  summary: PropTypes.shape({}),
});
