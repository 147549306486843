const API = {
  WS_URL_KEY_MAPPER: {
    USER: 'WS_USER_CHANNEL',
    CALL_CENTER: 'WS_CALL_CENTER_CHANNEL',
    INQUIRY: 'WS_INQUIRY_CHANNEL',
  },
};

const init = ({ restEndpoint, wsEndpoint, env }, { enableV2WS = false } = {}) => {
  API.ENV = env;

  // Login actions
  API.AUTH_LOGIN = `${restEndpoint}/carcode/v1/call-center/auth/login`;
  API.AUTH_LOGOUT = `${restEndpoint}/carcode/v1/call-center/auth/logout`;

  // Password reset
  API.AUTH_PASSWROD = `${restEndpoint}/carcode/v1/call-center/auth/password`;
  API.AUTH_RECOVERY = `${restEndpoint}/carcode/v1/call-center/auth/recovery`;
  API.AUTH_RECOVERY_USERS = `${restEndpoint}/carcode/v1/call-center/auth/recovery/users`;
  API.AUTH_CHANGE_PASSWORD = `${restEndpoint}/carcode/v1/call-center/auth/password/change`;

  // User actions
  API.USER_UPLOAD_PHOTO = `${restEndpoint}/carcode/v1/call-center/uploads/user/:userId/photo`;

  // File api
  API.UPLOAD_FILE = `${restEndpoint}/carcode/v1/call-center/uploads`;

  // Statistics
  API.DOWNLOAD_STATISTICS = `${restEndpoint}/carcode/v1/call-center/reports/agents-statistic/dealers/:dealerId`;

  // Dealer Reporting
  API.DOWNLOAD_REPORT = `${restEndpoint}/carcode/v1/call-center/reports/:dealerId`;

  // WS
  API.WS_USER_CHANNEL = `${wsEndpoint}/carcode${enableV2WS ? '/v2' : ''}/user/:userId/dealer/:dealerId`;
  API.WS_CALL_CENTER_CHANNEL = `${wsEndpoint}/carcode${enableV2WS ? '/v2' : ''}/callcenter/dealer/:dealerId`;
  API.WS_INQUIRY_CHANNEL = `${wsEndpoint}/carcode${
    enableV2WS ? '/v2' : ''
  }/inquiry/:inquiryId?userid=:userId&dealerid=:dealerId`;
};

export { init };
export default API;
