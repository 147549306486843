const isMac = () => {
  if (navigator.userAgent.indexOf('Mac OS X') !== -1) {
    return true;
  }
  return false;
};

const isAndroid = () => {
  if (window.navigator.userAgent.indexOf('Android') !== -1) {
    return true;
  }
  return false;
};

export default {
  isMac,
  isAndroid,
};
