import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { AVATAR_URLS } from 'core/constants/avatars';

import './user-profile-image.styles.scss';

const UserProfileImage = ({ className, photoLink, name }) => (
  <div
    className={classNames(
      className,
      'user-profile-image d-flex align-items-center justify-content-center rounded overflow-hide',
    )}
  >
    <div
      className="user-profile-image-img w-100 h-100"
      style={{ backgroundImage: `url(${photoLink || AVATAR_URLS[0]})` }}
      alt={name}
    />
  </div>
);

UserProfileImage.propTypes = {
  photoLink: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
};

UserProfileImage.defaultProps = {
  photoLink: '',
  name: 'No profile image',
  className: '',
};

export default UserProfileImage;
