import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'reactstrap';

import { POSITION as SIDEBAR_POSITION } from 'core/constants/sidebar';
import { Icon } from 'shared-components/icon';
import { Sidebar } from 'shared-components/sidebar';
import { Navigation } from 'components/navigation';

import './burger-menu.styles.scss';

const BurgerMenu = ({ handleToggleMenu, isOpen }) => (
  <>
    <Button color="transparent" className="px-1 py-0_25" onClick={handleToggleMenu}>
      <Icon name={isOpen ? 'cross2' : 'menu7'} size="1.25rem" className="text-white" />
    </Button>
    <Sidebar open={isOpen} position={SIDEBAR_POSITION.BURGER} handleToggle={handleToggleMenu}>
      <Navigation className="h-100 burger-menu__content" handleToggle={handleToggleMenu} isOpen={isOpen} />
    </Sidebar>
  </>
);

BurgerMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleToggleMenu: PropTypes.func.isRequired,
};

export default BurgerMenu;
