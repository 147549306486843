import React from 'react';
import PropTypes from 'prop-types';

import { SKIP_CLICKS_ID_SELECTOR, DOCUMENT_TITLE } from 'core/constants/auto-logout';

import { Modal } from 'shared-components/modal';
import { Text } from 'shared-components/text';

const AutoLogout = ({
  isConfirmingModal,
  maxInactiveTimeInMins,
  timer,
  handleModalSubmit,
  handleModalCancel,
  handleModalToggle,
}) => (
  <Modal
    isOpen={isConfirmingModal}
    backdrop="static"
    hideCancelButton
    submitText="Stay Online"
    title={DOCUMENT_TITLE}
    className="auto-logout-modal d-flex align-intems-center"
    dataTestAttributes={{
      submit: 'cc-auto-logout-confirm-button',
    }}
    handleSubmit={handleModalSubmit}
    handleCancel={handleModalCancel}
    handleToggle={handleModalToggle}
  >
    <Text id={SKIP_CLICKS_ID_SELECTOR}>
      You have been inactive for <b>{maxInactiveTimeInMins}</b> minutes. Please click &quot;Stay Online&quot; if you
      want to continue working.
      <br />
      <br />
      Otherwise you will be logged out in <b>{timer}</b> {timer === 1 ? 'second' : 'seconds'}.
    </Text>
  </Modal>
);

AutoLogout.propTypes = {
  isConfirmingModal: PropTypes.bool.isRequired,
  maxInactiveTimeInMins: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  timer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleModalSubmit: PropTypes.func.isRequired,
  handleModalToggle: PropTypes.func.isRequired,
  handleModalCancel: PropTypes.func.isRequired,
};

export default AutoLogout;
