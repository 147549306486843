import DealerWidgetSettings from './dealer-widget-settings';
import DealerWidget from './dealer-widget';
import ConfiguredWidget from './configured-widget';
import PhoneNumber from './phone-number';
import ConfiguredWidgetEditPage from './configured-widget-edit-page';
import ConfiguredWidgetCustomPopup from './configured-widget-custom-popup';
import CannedMessageType from './canned-message-type';

export default {
  DealerWidgetSettings,
  DealerWidget,
  ConfiguredWidget,
  PhoneNumber,
  ConfiguredWidgetEditPage,
  ConfiguredWidgetCustomPopup,
  CannedMessageType,
};
