import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client/cache';

import history from 'core/router/history';
import resolvers from 'core/resolvers';

import { config } from 'core/config';
import { tokenService } from 'core/token';
import { alertService } from 'core/alert';
import { requestHeadersService } from 'core/request-headers';
import { newRelicService } from 'core/new-relic';
import { inquiriesCategoryService } from 'core/inquiries-category';
import { inquiriesFilterService } from 'core/inquiries-filter';
import { draftMessagesService } from 'core/draft-messages';
import { urlUtils } from 'core/utils/url';

import { GET_BACKGROUND_INQUIRIES } from 'core/queries/inquiries-background';
import { DRAFT_MESSAGES_CLIENT_QUERY } from 'core/queries/inquiry';
import { INQUIRIES_LIST_CHECKBOXES_QUERY } from 'core/queries/inquiries-list';
import { INQUIRIES_CATEGORIES_QUERY, ACTIVE_CATEGORY_ID_QUERY } from 'core/queries/inquiries-categories';
import { INQUIRIES_FILTER_QUERY } from 'core/queries/inquiries-filter';

import { PAGES } from 'core/constants/pages';
import {
  ALERT_COLOR,
  ALERT_QUERIES_EXCEPTIONS,
  ALERT_QUERIES_TEXT,
  ALERT_TYPE,
  CONVERSATION_ALERT_QUERIES,
} from 'core/constants/alert';
import { darkLaunchService } from 'core/dark-launch';

const cache = new InMemoryCache({
  addTypename: true,
  dataIdFromObject: object => {
    // eslint-disable-next-line no-underscore-dangle
    switch (object.__typename) {
      /*
        Do not normalize InquiryUserAssignDto since it contains id: null for unassigned inquiries.
        It might cause issues like this https://edmunds.atlassian.net/browse/CC-7457 when inquiry becomes unassigned after being assigned.
        Apollo does not re-render components with correct props, because of normalization and nullish ids.

        Do not normalize DealerCannedMessageDto and DealerChatStarterDto since it contains id: null for default chat starters and default canned messages when a dealer has none in the database.

        Skipping normalization for this entity looks safe for now,
        but if we need normalization in the future, then we need to escape nullish ids in DTOs.
      */
      case 'WidgetPhoneNumberDto':
      case 'DealerCannedMessageDto':
      case 'DealerChatStarterDto':
      case 'InquiryUserAssignDto': {
        return null;
      }

      default: {
        return defaultDataIdFromObject(object); // fall back to default handling
      }
    }
  },
  typePolicies: {
    InquiryDto: {
      fields: {
        phoneNumber: {
          merge: true,
        },
        msgStats: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
    UserProfileDto: {
      fields: {
        dealers: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    ConfiguredWidgetDto: {
      fields: {
        phoneNumbers: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        configuredWidgetCustomPopups: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    ConfiguredWidgetEditPageDto: {
      fields: {
        configuredWidgetCustomPopups: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    Query: {
      fields: {
        backgroundInquiries: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

const client = () => {
  const logLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = tokenService.get();

    const xClientRequestId = requestHeadersService.xClientRequestId(cache, token);
    const xArtifactVersion = requestHeadersService.xArtifactVersion();
    const xArtifactId = requestHeadersService.xArtifactId(cache, token);
    return {
      headers: {
        ...headers,
        ...xArtifactVersion,
        ...xArtifactId,
        ...xClientRequestId,
      },
    };
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = tokenService.get();
    const xClientRequestId = requestHeadersService.xClientRequestId(cache, token);
    const xAuthToken = requestHeadersService.xAuthToken(token);

    return {
      headers: {
        ...headers,
        ...xClientRequestId,
        ...xAuthToken,
      },
    };
  });

  const customFetch = (_, options) => {
    const { operationName } = JSON.parse(options.body);
    const isExternal = urlUtils.isExternalHostname();
    return fetch(`${isExternal ? config.graphqlEndpointExt : config.graphqlEndpoint}?opname=${operationName}`, options);
  };
  const httpLink = new HttpLink({ fetch: customFetch });

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const operationName = (operation && operation.operationName) || 'unknown';
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        newRelicService.logError(message);

        // transfer errors are inline now
        if (path.indexOf('transfer') === -1 || !darkLaunchService.is('cc10433')) {
          if (!ALERT_QUERIES_EXCEPTIONS.includes(operationName)) {
            alertService.show({
              text: message,
              color: ALERT_COLOR.DANGER,
            });
          }
        }
      });
    }

    if (networkError) {
      // A ServerParseError means that Apollo was unable to parse the error response body
      if (networkError.name === 'ServerParseError') {
        // Replace parsing error message with real one, if there is one. Otherwise, replace with our own custom error message.
        // eslint-disable-next-line no-param-reassign
        networkError.message =
          networkError.bodyText ||
          `Response not successful: Received status code ${networkError.statusCode} for operation ${operationName}`;
      }

      if (networkError.statusCode !== 401) newRelicService.logError(networkError);

      if (!ALERT_QUERIES_EXCEPTIONS.includes(operationName)) {
        alertService.show(
          {
            text: ALERT_QUERIES_TEXT[operationName] || `[Network error]: ${networkError}`,
            color: ALERT_COLOR.DANGER,
          },
          CONVERSATION_ALERT_QUERIES.includes(operation.operationName) ? ALERT_TYPE.CONVERSATION : ALERT_TYPE.DEFAULT,
        );
      }

      if (networkError.statusCode >= 500) {
        history.push(PAGES.ERROR);
      }

      if (networkError.statusCode === 401) {
        history.push(PAGES.LOGIN);
      }
    }
  });

  const link = ApolloLink.from([logLink, authLink, errorLink, httpLink]);

  const apolloClient = new ApolloClient({
    cache,
    link,
    resolvers,
    connectToDevTools: true,
    assumeImmutableResults: true,
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
    },
  });

  const writeInitialData = () => {
    cache.writeQuery({
      query: GET_BACKGROUND_INQUIRIES,
      data: {
        backgroundInquiries: [],
      },
    });

    cache.writeQuery({
      query: DRAFT_MESSAGES_CLIENT_QUERY,
      data: {
        draftMessages: draftMessagesService.getMessagesFromStorage(),
      },
    });

    cache.writeQuery({
      query: INQUIRIES_LIST_CHECKBOXES_QUERY,
      data: {
        inquiriesListCheckboxes: [],
      },
    });

    cache.writeQuery({
      query: INQUIRIES_CATEGORIES_QUERY,
      data: {
        categories: inquiriesCategoryService.get(),
      },
    });

    cache.writeQuery({
      query: ACTIVE_CATEGORY_ID_QUERY,
      data: {
        activeCategoryId: inquiriesCategoryService.getDefaultId(),
      },
    });

    cache.writeQuery({
      query: INQUIRIES_FILTER_QUERY,
      data: {
        inquiriesFilter: inquiriesFilterService.getFilters(),
      },
    });
  };

  writeInitialData();

  apolloClient.onClearStore(writeInitialData);

  return apolloClient;
};

export default client;
