import _ from 'lodash';

import { localStorage } from 'core/storages/local';
import { inquiriesCategoryService } from 'core/inquiries-category';

import { INQUIRIES_FILTER_KEY } from 'core/constants/inquiries/filter';
import { ORIGIN_SOURCE_FILTER_MAPPING } from 'core/constants/inquiry/origin-source';
import { TYPENAME } from 'core/constants/typename';

const saveFilters = filters => localStorage.set(INQUIRIES_FILTER_KEY, filters);

const withTypeNames = filters => ({
  userId: filters.userId,
  dealerId: filters.dealerId,
  selectedDealers: filters.selectedDealers ? filters.selectedDealers : null,
  filterAssignedUser: filters.filterAssignedUser ? filters.filterAssignedUser : null,
  filterProcessedUser: filters.filterProcessedUser ? filters.filterProcessedUser : null,
  department: filters.department ? { ...filters.department, __typename: TYPENAME.DEPARTMENT } : null,
  channel: filters.channel ? { ...filters.channel, __typename: TYPENAME.CHANNEL } : null,
  origin: filters.origin ? { ...filters.origin, __typename: TYPENAME.ORIGIN } : null,
  customerName: filters.customerName || null,
  customerEmail: filters.customerEmail || null,
  crmStatus: filters.crmStatus ? { ...filters.crmStatus, __typename: TYPENAME.CRM_STATUS } : null,
  customerPhoneNumber: filters.customerPhoneNumber || null,
  startDate: filters.startDate || null,
  endDate: filters.endDate || null,
  __typename: TYPENAME.INQUIRIES_FILTER,
});

const getFilters = () => {
  const savedFilters = localStorage.get(INQUIRIES_FILTER_KEY);

  if (!savedFilters || !Array.isArray(savedFilters)) {
    return [];
  }

  return savedFilters.map(withTypeNames);
};

const isApplied = (filters = {}) =>
  Object.keys(_.omit(filters, ['__typename', 'userId', 'dealerId'])).some(key => !isEmptyFilterValue(filters[key]));

const isEmptyFilterValue = value => {
  if (_.isArray(value)) {
    return !value || !value.length;
  }
  return _.isObject(value) ? !value.id : !value;
};

const getFiltersCount = (filters = {}) =>
  Object.keys(_.omit(filters, ['__typename', 'userId', 'dealerId'])).filter(key => !isEmptyFilterValue(filters[key]))
    .length;

const isMatchFilter = (filter, value) => {
  if (!filter || filter.id === -1) {
    return true;
  }

  return (
    (filter.id && filter.id.toLowerCase() === value.toLowerCase()) ||
    (!filter.id && filter.toLowerCase() === value.toLowerCase())
  );
};

const isMatchOriginFilter = (filter, inquiry) => {
  const originFilter = ORIGIN_SOURCE_FILTER_MAPPING[filter];
  let isSourceMatch = false;
  let isPhoneLabelMatch = false;

  if (!filter || !originFilter) {
    return true;
  }

  isSourceMatch = inquiry.source === originFilter.source;
  isPhoneLabelMatch = !originFilter.phoneLabels || originFilter.phoneLabels.indexOf(inquiry.phoneNumber.label) !== -1;

  return isSourceMatch && isPhoneLabelMatch;
};

const matchFilters = (inquiry, filters = getFiltersParams()) => {
  if (!isApplied()) {
    return true;
  }

  return (
    isMatchFilter(filters.assignedTo, inquiry.assigned.id) &&
    isMatchFilter(filters.conversationType, inquiry.conversationType) &&
    isMatchFilter(filters.departmentType, inquiry.type) &&
    isMatchFilter(filters.customerName, inquiry.customer.name) &&
    isMatchFilter(filters.customerPhoneNumber, inquiry.customer.phoneNumber) &&
    isMatchFilter(filters.customerEmail, inquiry.customer.email) &&
    isMatchFilter(filters.crmStatus.id, inquiry.crmStatus) &&
    isMatchOriginFilter(filters.origin, inquiry) &&
    (!filters.startDate || inquiry.updatedAt >= filters.startDate) &&
    (!filters.endDate || inquiry.updatedAt <= filters.endDate)
  );
};

const getFiltersParams = (filters = getFilters()) => {
  if (!filters || !Object.keys(filters).length) {
    return {};
  }

  return {
    // 'null' is not skipped by lodash merge method and overrides existing destination value
    // (it leads to incorrect assignedTo for unassigned category when filter is empty).
    // 'undefined' is skipped and does not overrides assignedTo if filter is empty.
    assignedTo: filters.filterAssignedUser ? filters.filterAssignedUser.id : undefined,
    processedBy: filters.filterProcessedUser ? filters.filterProcessedUser.id : undefined,
    departmentType: filters.department ? filters.department.id : null,
    conversationType: filters.channel ? filters.channel.id : null,
    origin: filters.origin ? filters.origin.id : null,
    customerName: filters.customerName || null,
    customerEmail: filters.customerEmail || null,
    customerPhoneNumber: filters.customerPhoneNumber || null,
    crmStatus: filters.crmStatus ? filters.crmStatus.id : null,
    startDate: filters.startDate || null,
    endDate: filters.endDate || null,
  };
};

const getCategoryFilters = (category, userId) => {
  const categoryOptions = inquiriesCategoryService.getOptions(category.key, userId);
  const result = _.get(categoryOptions, 'list[0].query.options.variables.filters');

  return result;
};

const getDealerIds = (filters, userId, dealerId) => {
  const extractedFilters = extractFilters(filters, userId, dealerId);
  const dealerIds =
    extractedFilters && extractedFilters.selectedDealers && extractedFilters.selectedDealers.map(dealer => dealer.id);

  const dealerIdsWithCurrent = dealerIds ? _.uniq(_.concat(dealerIds, [dealerId])) : [dealerId];

  return dealerIdsWithCurrent;
};

const extractFilters = (filters = getFilters(), userId, dealerId) =>
  _.find(filters, { userId, dealerId }) || withTypeNames({});

const isEqual = (filters, otherFilters) =>
  otherFilters && filters && (otherFilters.userId !== filters.userId || otherFilters.dealerId !== filters.dealerId);

export default {
  saveFilters,
  getFilters,
  getFiltersParams,
  getFiltersCount,
  isApplied,
  matchFilters,
  withTypeNames,
  getDealerIds,
  getCategoryFilters,
  extractFilters,
  isEqual,
};
