const decorate = (obj, typename) => ({
  ...obj,
  __typename: typename,
});

const inquiry = obj => ({
  ...decorate(obj, 'InquiryDto'),
  customer: customer(obj.customer),
  assigned: assigned(obj.assigned),
  phoneNumber: phoneNumber(obj.phoneNumber),
  ...(obj.originDealer ? { originDealer: originDealer(obj.originDealer) } : {}),
  ...(obj.dealer ? { dealer: dealer(obj.dealer) } : {}),
  ...(obj.msgStats ? { msgStats: msgStats(obj.msgStats) } : {}),
  ...(obj.tradeInVehicleDto ? { tradeInVehicleDto: tradeInVehicle(obj.tradeInVehicleDto) } : {}),
  ...(obj.changelogs ? { changelogs: changelogs(obj.changelogs) } : []),
  ...(obj.inquiryArchivedHistory ? { inquiryArchivedHistory: inquiryArchivedHistory(obj.inquiryArchivedHistory) } : []),
});

const assigned = obj => decorate(obj, 'InquiryUserAssignDto');

const customer = obj => decorate(obj, 'CustomerDto');

const msgStats = obj => decorate(obj, 'MessageStatisticDto');

const phoneNumber = obj => decorate(obj, 'InquiryPhoneNumber');

const dealer = obj => decorate(obj, 'NamedDto');

const events = array => array && array.map(obj => decorate(obj, 'InquiryEventDto'));

const inquiryStats = obj => decorate(obj, 'InquiryStatusDto');

const changelogs = array => array && array.map(obj => decorate(obj, 'InquiryChangelogDto'));

const inquiryArchivedHistory = array => array && array.map(obj => decorate(obj, 'ArchivedInquiryLinkedInfoDto'));

const originDealer = obj => decorate(obj, 'DealerDtoCallCenter');

const tradeInVehicle = obj => decorate(obj, 'TradeInVehicleDto');

const dialogueItem = obj => decorate(obj, 'DialogueItemDto');

const inquiryFeedback = obj => decorate(obj, 'InquiryFeedbackDto');

const conversationSummary = obj => decorate(obj, 'ConversationSummaryDto');

const userBase = obj => decorate(obj, 'UserBaseDto');

const attachments = array => array && array.map(obj => decorate(obj, 'AttachmentDto_dev'));

const linkPreviews = array => array && array.map(obj => decorate(obj, 'LinkPreviewDto'));

const inventoryAttachmentCards = array => array && array.map(obj => decorate(obj, 'InventoryAttachmentCardDto'));

const vehicle = obj => decorate(obj, 'VehicleDto');

const workingHoursCard = obj => decorate(obj, 'WorkingHoursCardDto_dev');

const workingOperations = obj => decorate(obj, 'OperationsDto_dev');

const vehicleDealer = obj => decorate(obj, 'DealerDto');

const edmundsVehicleDealer = obj => decorate(obj, 'DealerDto');

const sales = obj => decorate(obj, 'DealerSettingsDto');

const event = obj => decorate(obj, 'InquiryEventDto');

const customerTexting = obj => decorate(obj, 'CustomerTextingDto');

const appendMessage = obj => decorate(obj, 'AgentTextingDto');

const draftMessage = obj => decorate(obj, 'DraftMessageDto');

const inventoryIdentifiers = array => array && array.map(obj => decorate(obj, 'InventoryIdentifierDto'));

const inventoryLastCheckedMessage = obj => decorate(obj, 'InventorySearchLastCheckedMessageDto');

const isInventoryImagesShow = obj => decorate(obj, 'InventoryIsImagesShowDto');

const isInventorySearchClosed = obj => decorate(obj, 'isInventorySearchClosedDto');

const activeInventory = obj => decorate(obj, 'InventoryActiveDto');

const usedVehicleTMVCard = obj => decorate(obj, 'UsedVehicleTMVCardDto');

const buttonsBarHint = obj => decorate(obj, 'ButtonsBarHint');

const dialog = obj => decorate(obj, 'DatedDialoguesDto');

const mediaCard = obj =>
  decorate(
    {
      ...obj,
      cardData: decorate(obj.cardData, 'MediaCardDataDto'),
    },
    'MediaCardDto_MediaCardDataDto',
  );

const mediaCards = array => array && array.map(obj => mediaCard(obj));

export default {
  inquiry,
  assigned,
  appendMessage,
  draftMessage,
  customer,
  customerTexting,
  msgStats,
  phoneNumber,
  dealer,
  events,
  inquiryStats,
  originDealer,
  tradeInVehicle,
  dialogueItem,
  conversationSummary,
  inquiryFeedback,
  userBase,
  attachments,
  linkPreviews,
  inventoryAttachmentCards,
  usedVehicleTMVCard,
  vehicle,
  vehicleDealer,
  edmundsVehicleDealer,
  sales,
  event,
  inventoryLastCheckedMessage,
  isInventoryImagesShow,
  isInventorySearchClosed,
  activeInventory,
  inventoryIdentifiers,
  buttonsBarHint,
  dialog,
  workingHoursCard,
  workingOperations,
  mediaCards,
};
