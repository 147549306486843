import Mousetrap from 'mousetrap';

import _ from 'lodash';

import { keyboardShortcutsService } from 'core/keyboard-shortcuts';

class KeyboardShortcutsManager {
  constructor() {
    this.keyMap = {};
    this.handlers = {};
    this.pausedEvents = [];

    this.init();
  }

  init = () => {
    const keyMap = keyboardShortcutsService.getKeyboardShortcutsMap();
    const handlers = keyboardShortcutsService.getDefaultHandlers();

    this.registerKeyMap(keyMap);
    this.registerHandlers(handlers);
  };

  destroy = () => {
    this.keyMap = {};
    this.handlers = {};
    this.pausedEvents = [];

    Mousetrap.reset();
  };

  bindShortcut = (name, callback) => {
    const keySequence = this.keyMap[name];
    Mousetrap.bind(keySequence, e => {
      if (e.preventDefault) {
        // https://edmunds.atlassian.net/browse/CC-8270
        // prevent default browser behavior
        e.preventDefault();
      }

      if (!e.repeat) {
        callback(e); // prevents repeated calls incase key is held down
      }
    });
  };

  pauseEvents = eventsToKeep => {
    const eventsToPause = Object.keys(this.handlers).filter(name => !Object.keys(eventsToKeep).includes(name));
    this.pausedEvents = eventsToPause;
    Mousetrap.reset();
  };

  resumePausedEvents = () => {
    this.pausedEvents.forEach(name => {
      const callback = this.handlers[name];
      this.bindShortcut(name, callback);
    });
  };

  registerKeyMap = map => {
    this.keyMap = {
      ...this.keyMap,
      ...map,
    };
  };

  registerHandlers = (handlers, pauseOtherEvents = false) => {
    if (_.isEmpty(handlers)) return;
    if (pauseOtherEvents) this.pauseEvents(handlers);

    Object.entries(handlers)
      .filter(([name, callback]) => Object.prototype.hasOwnProperty.call(this.keyMap, name) && this.keyMap[name])
      .forEach(([name, callback]) => {
        this.handlers[name] = callback;
        this.bindShortcut(name, callback);
      });
  };

  removeHandler = name => {
    if (Object.prototype.hasOwnProperty.call(this.keyMap, name) && this.keyMap[name]) {
      this.handlers[name] = () => {};
      Mousetrap.unbind(this.keyMap[name]);
    }
  };
}

export default new KeyboardShortcutsManager();
