import PropTypes from 'prop-types';

export default PropTypes.shape({
  avatar: PropTypes.bool,
  role: PropTypes.bool,
  contact: PropTypes.bool,
  leadType: PropTypes.bool,
  assignedInquiries: PropTypes.bool,
  autoAssign: PropTypes.bool,
  response: PropTypes.bool,
  status: PropTypes.bool,
  actions: PropTypes.bool,
  checkbox: PropTypes.bool,
  assignedTo: PropTypes.bool,
});
