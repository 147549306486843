/* eslint-disable no-useless-escape */
export const REGEXP = {
  EMAILS: /([\w-.+_]+@[\w-.]+\.\w{2,6})/gim, // matches all email in string
  PHONE: /([^\d]|\b)(\+*1*\(*([0-9]{3})\)*[ -\.]?([0-9]{3})[ -\.]?([0-9]{4}))\.*\b/,
  PHONES: /([^\d]|\b)(\+*1*\(*([0-9]{3})\)*[ -\.]?([0-9]{3})[ -\.]?([0-9]{4}))\.*\b/g, // matches all phone numbers in string
  PHONE_FORMAT: /^\+?1?(\d{1,3})(\d{0,3})(\d{0,4})$/,
  FILE_EXT: /\.([0-9a-z]{1,5})$/,
  TIME: /(0?[1-9]|1[012]):[0-5]\d?([APap][mM])/g,
  ZIP: /^\d{5}(?:[-\s]\d{4})?$/,
  ABSOLUTE_URL: /^(?:[a-z]+:)?\/\//,
  URL: /(\b(https?|ftp):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gim,
  STOCK: /(?:stoc*k\s*(?:[onumberis\s]*[\s\.]{1,}|#*)\s*)(\w+\d{1,}\w+)/gi,
  VIN: /\b([^\Wioq]{17})\.*\b/g,
  VIN_SINGLE: /\b([^\Wioq]{17})\.*\b/,
  NUMBER: /^[0-9]+$/,
  YEAR: /(?=\b)([12]\d{3})(?!;)(?=\b)/g,
  YEAR_SINGLE: /(?=\b)([12]\d{3})(?!;)(?=\b)/,
  NAME: /[a-zA-Z.,]+ [a-zA-Z.,]+/,
  DATE_FORMAT: /^(\d{0,2})(\d{0,2})(\d{0,4})$/,
  DELETED_LINK: /\[deleted_link\]/,
  VEHICLE_STATUS: /^(new|used|cpo)$/i,
  URL_FULL:
    '^(?:(?:(?:https?|ftp):)?\\/\\/)?(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
    '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])' +
    '(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
    '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
    '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' +
    '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))' +
    '(?::\\d{2,5})?(?:[/?#]\\S*)?$',
  URL_FULL_PARSE: '^(([^:/?#]+):)?(//([^/?#]*))?([^?#]*)(\\?([^#]*))?(#(.*))?', // simplified RFC 3986 UTI validation
  WIDGET_EMBED_CODE_SRC: /src=['"](.*?)['"]/g,
  HTTP_OR_HTTPS: /^(http|https):/i,
  STRIP_TAGS: /\<.*\>/gi,
  STRIP_SPECIAL_CHARS: /[^\w\s-.,]/gi,
  PASSWORD_NUMBER: /[0-9]+/,
  PASSWORD_UPPERCASE_CHARACTER: /[A-Z]+/,
  PASSWORD_SPECIAL_CHARACTERS: /[`~!@#\$%\^&\*()\-_=\+\[\]{}\|;':”,\.\/<>\?]/,
};
