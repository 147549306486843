import { graphql } from '@apollo/client/react/hoc';
import { compose, mapProps } from 'recompose';

import { DARK_LAUNCH_FLAGS_QUERY } from 'core/queries/dark-launch';

import DarkLaunchPresentation from './dark-launch.presentation';

export default compose(
  graphql(DARK_LAUNCH_FLAGS_QUERY, {
    options: () => ({
      fetchPolicy: 'cache-first',
    }),
  }),
  mapProps(({ data: { darkLaunchFlags }, ...props }) => ({
    dlKeys: darkLaunchFlags,
    ...props,
  })),
)(DarkLaunchPresentation);
