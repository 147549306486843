import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { UncontrolledDropdown } from 'reactstrap';

import { UiPropTypes } from 'core/prop-types';

import { DropdownToggle } from 'shared-components/dropdown-toggle';
import { DropdownItem } from 'shared-components/dropdown-item';
import { DropdownMenu } from 'shared-components/dropdown-menu';
import { Icon } from 'shared-components/icon';
import { RouterNavLink } from 'shared-components/router-nav-link';

const LinkDropdown = ({ items, text, className, children }) => (
  <UncontrolledDropdown tag="div" className={classNames(className, 'w-100 h-100')} nav inNavbar>
    <DropdownToggle tag="div" className="p-0 w-100 h-100 text-white" nav caret>
      {children}
    </DropdownToggle>
    <DropdownMenu className="mt-0 w-100 rounded-0">
      {items.map(({ text: itemText, to, tag, iconProps }, index) => (
        <React.Fragment key={to}>
          <DropdownItem
            className="header-dropdown-item px-1 py-0 btn justify-content-start"
            customPadding
            tag={tag || RouterNavLink}
            to={to}
            href={to}
          >
            {iconProps && iconProps.name && (
              <>
                <Icon {...iconProps} /> &nbsp;
              </>
            )}
            {itemText}
          </DropdownItem>
          {index !== items.length - 1 && <DropdownItem divider className="header-dropdown-item--divider mx-1" />}
        </React.Fragment>
      ))}
    </DropdownMenu>
  </UncontrolledDropdown>
);

LinkDropdown.defaultProps = {
  className: '',
  iconProps: {},
};

LinkDropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      tag: UiPropTypes.Component,
    }),
  ).isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconProps: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
  }),
};

export default LinkDropdown;
