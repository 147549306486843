import React from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';

import { useReactRouterLink } from 'core/hooks/useReactRouterLink';

const RouterNavLink = ({ onClick, ...restProps }) => {
  const { onLinkClick } = useReactRouterLink({ to: restProps.to, onClick });
  return <NavLink {...restProps} onClick={onLinkClick} />;
};

RouterNavLink.propTypes = {
  onClick: PropTypes.func,
};

RouterNavLink.defaultProps = {
  onClick: null,
};

export default RouterNavLink;
