import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'shared-components/text';
import { PhoneText } from 'shared-components/phone-text';

const SwitchDealerPhoneNumber = ({ label, phone, labelColor, labelDisplay }) => (
  <Text className={`m-0 small ${labelDisplay}`} tag="p">
    <Text className={`switch-dealer__phone-number-label text-${labelColor}`}>{label}:</Text>&nbsp;
    <PhoneText text={phone} />
  </Text>
);

SwitchDealerPhoneNumber.defaultProps = {
  labelColor: 'white',
  labelDisplay: 'd-block',
};

SwitchDealerPhoneNumber.propTypes = {
  label: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  labelColor: PropTypes.string,
  labelDisplay: PropTypes.string,
};

export default SwitchDealerPhoneNumber;
