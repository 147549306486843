import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { CollapseButton } from 'shared-components/collapse-button';

import './collapse-footer.styles.scss';

const CollapseFooter = ({ className, collapsed, direction, handleCollapse }) => {
  let collapseButtonDirection;

  if (direction === 'left') {
    collapseButtonDirection = collapsed ? 'right' : 'left';
  } else {
    collapseButtonDirection = collapsed ? 'left' : 'right';
  }

  return (
    <div className={classNames('collapse-footer p-0_25', className)}>
      <CollapseButton
        onClick={handleCollapse}
        color="transparent"
        className={classNames('w-100 h-100 rounded-0 text-gray-darkest', {
          'justify-content-center': collapsed,
          'justify-content-start': !collapsed,
        })}
        direction={collapseButtonDirection}
        full={!collapsed}
      />
    </div>
  );
};

CollapseFooter.defaultProps = {
  className: null,
  collapsed: false,
  direction: 'left',
};

CollapseFooter.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']),
  collapsed: PropTypes.bool,
  className: PropTypes.string,
  handleCollapse: PropTypes.func.isRequired,
};

export default CollapseFooter;
