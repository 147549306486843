import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'shared-components/modal';
import { KeyboardShortcuts } from 'shared-components/keyboard-shortcuts';
import { KeyboardShortcutsHelp } from 'shared-components/keyboard-shortcuts/keyboard-shortcuts-help';

const KeyboardShortcutsHelpModal = ({ isOpen, handleOpenModal, handleToggleModal }) => (
  <>
    <KeyboardShortcuts
      handlers={{
        SHOW_HELP: handleOpenModal,
      }}
    />
    <Modal
      isOpen={isOpen}
      hideCancelButton
      hideSubmitButton
      className="keyboard-shortcuts-help-modal__modal d-flex align-items-center"
      centered
      size="lg"
      handleToggle={handleToggleModal}
    >
      <KeyboardShortcutsHelp variant="light" />
    </Modal>
  </>
);

KeyboardShortcutsHelpModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  handleToggleModal: PropTypes.func.isRequired,
};

export default KeyboardShortcutsHelpModal;
