import React from 'react';
import ReactDOM from 'react-dom';

import { AlertList } from 'components/alert-list';

import { ALERT, ALERT_TYPE, CONVERSATION_ALERT_CONTAINER_ID } from 'core/constants/alert';

const alertRoot = document.getElementById('alert');
let alerts = [];

const remove = removeId => alerts.filter(({ id }) => id !== removeId);

const addTimeout = alert => {
  const timeoutId = setTimeout(
    () => onDismiss(alert.id),
    alert.type === ALERT_TYPE.DEFAULT ? ALERT.TIMEOUT : ALERT.CONVERSATION_TIMEOUT,
  );
  alert.timeoutId = timeoutId; // eslint-disable-line
};

const onDismiss = dismissId => {
  alerts = remove(dismissId);
  render();
};

const add = (alert, type) => {
  const newAlert = {
    ...alert,
    type,
    id: parseInt(`${Date.now()}${Math.floor(Math.random() * 1000)}`, 10),
  };

  const existingAlert = alerts.filter(({ text }) => text === newAlert.text)[0];

  // do not add if already has with the same name
  // reinit timeout
  if (existingAlert) {
    clearTimeout(existingAlert.timeoutId);
    addTimeout(existingAlert);

    return alerts;
  }

  addTimeout(newAlert);

  return [...alerts, newAlert];
};

const render = () => {
  const conversationAlertRoot = document.getElementById(CONVERSATION_ALERT_CONTAINER_ID);

  const defaultAlerts = alerts.filter(({ type }) => type === ALERT_TYPE.DEFAULT);
  const conversationAlerts = alerts.filter(({ type }) => type === ALERT_TYPE.CONVERSATION);

  ReactDOM.render(<AlertList alerts={defaultAlerts} onDismiss={onDismiss} />, alertRoot);
  if (conversationAlertRoot) {
    ReactDOM.render(<AlertList alerts={conversationAlerts} onDismiss={onDismiss} />, conversationAlertRoot);
  }
};

const show = (alert, type = ALERT_TYPE.DEFAULT) => {
  alerts = add(alert, type);
  render();
};

export default {
  show,
};
