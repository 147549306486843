import React, { useState, useEffect } from 'react';

import _ from 'lodash';

import { SessionLogContext } from 'core/context/session-log';

import { sessionLogService } from 'core/session-log';

import { SESSION_LOG_KEYS } from 'core/constants/session-log';
import { CATEGORY_LIST_KEY } from 'core/constants/inquiries/category';

const sessionLogObjectDefaultValue = sessionLogService.getDefault();

const SessionLogProvider = props => {
  const [sessionLogObject, setSessionLogObject] = useState(sessionLogObjectDefaultValue);
  const [sessionUniqueInquiriesIds, setSessionUniqueInquiriesIds] = useState([]);

  useEffect(() => {
    setSessionLogObject(sessionLogService.get());
    setSessionUniqueInquiriesIds(sessionLogService.getUniqueIdsForPickups());
  }, []);

  const reset = () => {
    const defaultObject = sessionLogService.getDefault();
    setSessionLogObject(defaultObject);
    sessionLogService.set(defaultObject);
    setSessionUniqueInquiriesIds([]);
    sessionLogService.setUniqueIdsForPickups([]);
  };

  const update = (key, value) => {
    setSessionLogObject(prevSessionLogObject => {
      const updatedObject = {
        ...prevSessionLogObject,
        [key]: prevSessionLogObject[key] + value,
      };
      sessionLogService.set(updatedObject);
      return updatedObject;
    });
  };

  const updatePickupsCount = count => {
    setSessionLogObject(prevSessionLogObject => {
      const updatedObject = {
        ...prevSessionLogObject,
        sessionPickups: count,
      };
      sessionLogService.set(updatedObject);
      return updatedObject;
    });
  };

  const updateSessionUniqueInquiriesIds = inquiries => {
    const ids = inquiries.map(inquiry => inquiry.id);
    const uniqueIds = _.uniq([...ids, ...sessionUniqueInquiriesIds]);

    if (uniqueIds.length > sessionUniqueInquiriesIds.length) {
      updatePickupsCount(uniqueIds.length);
      setSessionUniqueInquiriesIds(uniqueIds);
      sessionLogService.setUniqueIdsForPickups(uniqueIds);
    }
  };

  const shouldUpdateUniqueInquiriesIds = (key, prevState, filteredInquiries) => {
    const isInquiriesInFilteredInquiries = filteredInquiries && filteredInquiries.length > 0;
    const isInquiriesInPrevState = prevState && prevState.inquiries;
    const isAllowUpdateIds = key === CATEGORY_LIST_KEY.ALL_ASSIGNED_TO_ME || key === CATEGORY_LIST_KEY.ASSIGNED_TO_ME;

    if (!prevState.inquiries && isInquiriesInFilteredInquiries && isAllowUpdateIds) return true;

    return (
      isAllowUpdateIds &&
      isInquiriesInPrevState &&
      isInquiriesInFilteredInquiries &&
      filteredInquiries.length > prevState.inquiries.length
    );
  };

  const incrementHandoffsCount = () => {
    update(SESSION_LOG_KEYS.HANDOFFS, 1);
  };

  const incrementLeadsCount = () => {
    update(SESSION_LOG_KEYS.LEADS, 1);
  };

  return (
    <SessionLogContext.Provider
      value={{
        sessionLogObject,
        reset,
        incrementHandoffsCount,
        updateSessionUniqueInquiriesIds,
        incrementLeadsCount,
        shouldUpdateUniqueInquiriesIds,
      }}
    >
      {props.children}
    </SessionLogContext.Provider>
  );
};

export default SessionLogProvider;
