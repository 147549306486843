import { cookieService } from 'core/cookie';
import { COOKIE_KEYS } from 'core/constants/cookie';
import { urlUtils } from 'core/utils/url';

const get = () => {
  const token = urlUtils.getUrlParam('token');

  if (token) {
    remove();
    save(token);
  }

  const cookie = cookieService.get(COOKIE_KEYS.TOKEN);
  return cookie;
};
const save = (token, remember) => {
  if (remember) {
    cookieService.set(COOKIE_KEYS.TOKEN, token);
  } else {
    cookieService.setSession(COOKIE_KEYS.TOKEN, token);
  }
};
const remove = () => cookieService.remove(COOKIE_KEYS.TOKEN);
const set = ({ token, remember }) => {
  save(token, remember);
};

export default {
  get,
  set,
  remove,
};
