const filterVisibleLinks = ({ links, loggedUser }) =>
  links
    .map(link => {
      // filter visible subitems
      if (link.navItems) {
        return {
          ...link,
          navItems: link.navItems.filter(navItem => !navItem.isDisabled || !navItem.isDisabled(loggedUser)),
        };
      }

      return link;
    })
    .filter(link => !link.isDisabled || !link.isDisabled(loggedUser));

export default {
  filterVisibleLinks,
};
