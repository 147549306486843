import React from 'react';

import LoadingChunk from './loading-chunk.presentation';

class LoadingChunkContainer extends React.Component {
  state = {};

  handleRefresh = async () => {
    // force refresh with clearing sw cache
    try {
      if (window.caches) {
        const cacheKeys = (await window.caches.keys()) || [];

        await Promise.all(cacheKeys.map(cacheName => window.caches.delete(cacheName)));
      }
    } finally {
      window.location.reload(true);
    }
  };

  render() {
    return <LoadingChunk handleRefresh={this.handleRefresh} {...this.props} />;
  }
}

export default LoadingChunkContainer;
