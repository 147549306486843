import PropTypes from 'prop-types';

import QuickReply from './quick-reply.js';

export default PropTypes.shape({
  categoryId: PropTypes.number,
  colorId: PropTypes.number,
  dealerId: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  text: PropTypes.string,
  description: PropTypes.string,
  quickReplies: PropTypes.arrayOf(QuickReply),
});
