import { gql } from '@apollo/client';

export const VOI_FRAGMENT = gql`
  fragment vehicleOfInterestData on VehicleDto {
    make
    model
    year
    status
    stockNo
    vin
    msrp
  }
`;

export const VOI_QUERY = gql`
  query GetVOIByInquiryId($inquiryId: Long!) {
    getVOIByInquiryId(inquiryId: $inquiryId) {
      ...vehicleOfInterestData
    }
  }
  ${VOI_FRAGMENT}
`;
