import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.number,
  userType: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  photoLink: PropTypes.string,
  inquiryCapacity: PropTypes.number,
});
