import PropTypes from 'prop-types';

import { MEDIA_CARD_TYPES } from 'core/constants/media-card';

import PreconfiguredResponsePropTypes from 'core/prop-types/preconfigured-response';

export default PropTypes.shape({
  cardData: PropTypes.shape({
    cardType: PropTypes.oneOf(MEDIA_CARD_TYPES).isRequired,
    data: PropTypes.shape({
      quickReplies: PropTypes.arrayOf(PreconfiguredResponsePropTypes.QuickReply).isRequired,
    }).isRequired,
  }),
});
