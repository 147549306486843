import { withProps } from 'recompose';

import { googleAnalyticsService } from 'core/tracking/google-analytics';
import { edwTracker } from 'core/tracking/edw';
import { ACTIONS, LABELS, CATEGORIES } from 'core/constants/tracking';
import { CATEGORY } from 'core/constants/inquiries/category';

import Category from './category.presentation';

export default withProps(props => ({
  ...props,
  handleClick: e => {
    props.handleClick(+e.currentTarget.value);

    switch (props.id) {
      case CATEGORY.ALL.id: {
        googleAnalyticsService.event({
          action: ACTIONS.SUCCESS,
          label: LABELS.SHOW_ALL,
          category: CATEGORIES.INQUIRIES,
        });
        edwTracker.trackCtaEvent({
          action: ACTIONS.SUCCESS,
          label: LABELS.SHOW_ALL,
          category: CATEGORIES.INQUIRIES,
        });

        break;
      }
      case CATEGORY.UNASSIGNED.id: {
        googleAnalyticsService.event({
          action: ACTIONS.SUCCESS,
          label: LABELS.SHOW_UNASSIGNED,
          category: CATEGORIES.INQUIRIES,
        });

        edwTracker.trackCtaEvent({
          action: ACTIONS.SUCCESS,
          label: LABELS.SHOW_UNASSIGNED,
          category: CATEGORIES.INQUIRIES,
        });

        break;
      }
      case CATEGORY.ASSIGNED_TO_ME.id: {
        googleAnalyticsService.event({
          action: ACTIONS.SUCCESS,
          label: LABELS.SHOW_ASSIGNED_TO_ME,
          category: CATEGORIES.INQUIRIES,
        });

        edwTracker.trackCtaEvent({
          action: ACTIONS.SUCCESS,
          label: LABELS.SHOW_ASSIGNED_TO_ME,
          category: CATEGORIES.INQUIRIES,
        });

        break;
      }
      case CATEGORY.COMPLETED.id: {
        googleAnalyticsService.event({
          action: ACTIONS.SUCCESS,
          label: LABELS.SHOW_COMPLETED,
          category: CATEGORIES.INQUIRIES,
        });

        edwTracker.trackCtaEvent({
          action: ACTIONS.SUCCESS,
          label: LABELS.SHOW_COMPLETED,
          category: CATEGORIES.INQUIRIES,
        });
        break;
      }
      case CATEGORY.ASSIGNED_TO_OTHERS.id: {
        googleAnalyticsService.event({
          action: ACTIONS.SUCCESS,
          label: LABELS.SHOW_ASSIGNED,
          category: CATEGORIES.INQUIRIES,
        });

        edwTracker.trackCtaEvent({
          action: ACTIONS.SUCCESS,
          label: LABELS.SHOW_ASSIGNED,
          category: CATEGORIES.INQUIRIES,
        });
        break;
      }
      case CATEGORY.MANAGED.id: {
        googleAnalyticsService.event({
          action: ACTIONS.SUCCESS,
          label: LABELS.SHOW_MANAGED,
          category: CATEGORIES.INQUIRIES,
        });

        edwTracker.trackCtaEvent({
          action: ACTIONS.SUCCESS,
          label: LABELS.SHOW_MANAGED,
          category: CATEGORIES.INQUIRIES,
        });

        break;
      }
      case CATEGORY.OUTBOUND.id: {
        googleAnalyticsService.event({
          action: ACTIONS.SUCCESS,
          label: LABELS.SHOW_OUTBOUND,
          category: CATEGORIES.INQUIRIES,
        });

        edwTracker.trackCtaEvent({
          action: ACTIONS.SUCCESS,
          label: LABELS.SHOW_OUTBOUND,
          category: CATEGORIES.INQUIRIES,
        });

        break;
      }
      case CATEGORY.ARCHIVED.id: {
        googleAnalyticsService.event({
          action: ACTIONS.SUCCESS,
          label: LABELS.SHOW_ARCHIVED,
          category: CATEGORIES.INQUIRIES,
        });

        edwTracker.trackCtaEvent({
          action: ACTIONS.SUCCESS,
          label: LABELS.SHOW_ARCHIVED,
          category: CATEGORIES.INQUIRIES,
        });
        break;
      }
      default: {
        break;
      }
    }
  },
}))(Category);
