import merge from 'lodash/merge';
import find from 'lodash/find';
import isMatch from 'lodash/isMatch';

import { INQUIRY_DIALOGUE_QUERY, INQUIRY_CUSTOMER_FRAGMENT, APPENDED_MESSAGE_CLIENT_QUERY } from 'core/queries/inquiry';
import moment from 'moment';
import decorator from 'core/resolvers/graphql-type-decorator';

export const updateCustomer = ({ customer: newCustomer, id, cache }) => {
  const cachedCustomer = cache.readFragment({
    id,
    fragment: INQUIRY_CUSTOMER_FRAGMENT,
  });

  if (!cachedCustomer) {
    return cachedCustomer;
  }

  const nextCachedCustomer = merge({}, cachedCustomer, newCustomer);

  cache.writeFragment({
    id,
    fragment: INQUIRY_CUSTOMER_FRAGMENT,
    data: nextCachedCustomer,
  });
  return nextCachedCustomer;
};

export const addNewMessageToDialogue = ({ message: newMessage, rawMessage, inquiryId, user, cache }) => {
  const dialoguesQueryVariables = {
    inquiryId,
    dealerId: user.currentDealer.id,
  };

  const cachedDialogues = cache.readQuery({
    query: INQUIRY_DIALOGUE_QUERY,
    variables: dialoguesQueryVariables,
  });

  // find dialog in cached dialogs with similar date
  const dialogItemIndex = cachedDialogues.getDialoguesByInquiryId.dialog.findIndex(item =>
    moment(item.date).isSame(newMessage.createdAt, 'day'),
  );

  let lastDialog;

  // if dialog was found, take it
  if (dialogItemIndex > -1) {
    lastDialog = cachedDialogues.getDialoguesByInquiryId.dialog[dialogItemIndex];
    // if not, create a new one with newMessage
  } else {
    lastDialog = decorator.dialog({ date: newMessage.createdAt, dialogues: [newMessage] });
  }

  // if dialog was found and it contains this message already
  if (dialogItemIndex > -1 && find(lastDialog.dialogues, { id: newMessage.id })) {
    return;
  }

  // if dialog was found and
  // if message came via ws early then reply mutation was ended
  // we should update optimistic message with the new one
  if (dialogItemIndex > -1 && find(lastDialog.dialogues, { id: +rawMessage.appReplyId })) {
    updateMessageInDialogue({
      message: newMessage,
      inquiryId,
      user,
      updateBy: newMessage.appReplyId,
      cache,
    });

    return;
  }

  let nextCachedDialogues = { ...cachedDialogues };
  // if dialog was found, update it
  if (dialogItemIndex > -1) {
    nextCachedDialogues = {
      ...nextCachedDialogues,
      getDialoguesByInquiryId: {
        ...nextCachedDialogues.getDialoguesByInquiryId,
        dialog: [
          ...nextCachedDialogues.getDialoguesByInquiryId.dialog.slice(
            0,
            nextCachedDialogues.getDialoguesByInquiryId.dialog.length - 1,
          ),
          {
            ...lastDialog,
            dialogues: [...lastDialog.dialogues, newMessage],
          },
        ],
      },
    };
    // if not, add the new one to the dialogs array
  } else {
    nextCachedDialogues = {
      ...nextCachedDialogues,
      getDialoguesByInquiryId: {
        ...nextCachedDialogues.getDialoguesByInquiryId,
        dialog: [...nextCachedDialogues.getDialoguesByInquiryId.dialog, lastDialog],
      },
    };
  }

  cache.writeQuery({
    query: INQUIRY_DIALOGUE_QUERY,
    variables: dialoguesQueryVariables,
    data: nextCachedDialogues,
  });
};

export const updateMessageInDialogue = ({ message: newMessage, inquiryId, user, cache, updateBy }) => {
  const dialoguesQueryVariables = {
    inquiryId,
    dealerId: user.currentDealer.id,
  };

  const cachedDialogues = cache.readQuery({
    query: INQUIRY_DIALOGUE_QUERY,
    variables: dialoguesQueryVariables,
  });

  const nextCachedDialogues = {
    ...cachedDialogues,
    getDialoguesByInquiryId: {
      ...cachedDialogues.getDialoguesByInquiryId,
      dialog: cachedDialogues.getDialoguesByInquiryId.dialog.map(dialog => {
        const dialogues = dialog.dialogues.map(item => {
          if (isMatch(item, { createdAt: updateBy })) {
            return newMessage;
          }

          return item;
        });

        return {
          ...dialog,
          dialogues,
        };
      }),
    },
  };

  cache.writeQuery({
    query: INQUIRY_DIALOGUE_QUERY,
    variables: dialoguesQueryVariables,
    data: nextCachedDialogues,
  });
};

export const getCachedTextMessage = ({ inquiryId, cache }) => {
  const cachedData = cache.readQuery({
    query: APPENDED_MESSAGE_CLIENT_QUERY,
    variables: {
      inquiryId,
    },
  });

  return cachedData && cachedData.appendMessage && cachedData.appendMessage.appendedMessage;
};

export const addNewEventToDialogue = ({ event: newEvent, inquiryId, user, cache }) => {
  const dialoguesQueryVariables = {
    inquiryId,
    dealerId: user.currentDealer.id,
  };

  const cachedDialogues = cache.readQuery({
    query: INQUIRY_DIALOGUE_QUERY,
    variables: dialoguesQueryVariables,
  });

  const cachedEvents = cachedDialogues.getDialoguesByInquiryId.events || [];

  if (find(cachedEvents, { id: newEvent.id })) {
    return;
  }

  const nextCachedEvents = [...cachedEvents, newEvent];

  const nextCachedDialogues = { ...cachedDialogues };
  nextCachedDialogues.getDialoguesByInquiryId = {
    ...cachedDialogues.getDialoguesByInquiryId,
    events: nextCachedEvents,
  };

  cache.writeQuery({
    query: INQUIRY_DIALOGUE_QUERY,
    variables: dialoguesQueryVariables,
    data: nextCachedDialogues,
  });
};

export const updateDialogueConversationSummary = ({ user, inquiryId, conversationSummary, cache }) => {
  const variables = { inquiryId, dealerId: user.currentDealer.id };
  const cachedDialogues = cache.readQuery({ query: INQUIRY_DIALOGUE_QUERY, variables });

  const nextCachedDialogues = { ...cachedDialogues };
  nextCachedDialogues.getDialoguesByInquiryId = {
    ...cachedDialogues.getDialoguesByInquiryId,
    conversationSummary,
  };

  cache.writeQuery({
    query: INQUIRY_DIALOGUE_QUERY,
    variables,
    data: nextCachedDialogues,
  });
};

export const addDraftMessage = (cachedDraftMessages = [], newDraftMessage) => {
  if (!newDraftMessage) {
    return cachedDraftMessages;
  }

  const { userId, inquiryId } = newDraftMessage;
  const nextDraftMessages = cachedDraftMessages.filter(
    draft => !(draft.userId === userId && draft.inquiryId === inquiryId),
  );

  return [...nextDraftMessages, newDraftMessage];
};
