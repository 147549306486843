import { gql } from '@apollo/client';

export const IS_INVENTORY_IMAGES_SHOW_CLIENT_QUERY = gql`
  query InventoryIsImagesShow($inquiryId: Long!) {
    isInventoryImagesShow(inquiryId: $inquiryId) @client {
      isShow
    }
  }
`;

export const IS_INVENTORY_SEARCH_CLOSED_CLIENT_QUERY = gql`
  query InventorySearchIsClosed($inquiryId: Long!) {
    isInventorySearchClosed(inquiryId: $inquiryId) @client {
      isClosed
    }
  }
`;

export const INVENTORY_ACTIVE_CLIENT_QUERY = gql`
  query InventoryActive($inquiryId: Long!) {
    activeInventory(inquiryId: $inquiryId) @client {
      value
    }
  }
`;

export const INVENTORY_IDENTIFIERS_CLIENT_QUERY = gql`
  query InventoryIdentifiers($inquiryId: Long!) {
    inventoryIdentifiers(inquiryId: $inquiryId) @client {
      identifiers {
        edmundsRooftopId
        type
        value
        vehicle
        vehicleDealer
      }
    }
  }
`;

export const INVENTORY_LAST_CHECKED_MESSAGE_CLIENT_QUERY = gql`
  query InventorySearchLastCheckedMessage($inquiryId: Long!) {
    inventoryLastCheckedMessage(inquiryId: $inquiryId) @client {
      messageCreatedAt
    }
  }
`;

export const INVENTORY_SEARCH_IMAGES_QUERY = gql`
  query InventorySearch($input: InventorySearchInput!) {
    inventorySearch(input: $input) {
      vehicles {
        vehicle {
          images {
            inventory
          }
        }
      }
    }
  }
`;

export const INVENTORY_SEARCH_STOCK_IMAGES_QUERY = gql`
  query InventorySearch($input: InventorySearchInput!) {
    inventorySearch(input: $input) {
      vehicles {
        vehicle {
          stockImages {
            imageUrls
          }
        }
      }
    }
  }
`;

export const INVENTORY_SEARCH_QUERY = gql`
  query InventorySearch($input: InventorySearchInput!) {
    inventorySearch(input: $input) {
      vehicles {
        vehicle {
          buyBackProtection
          dealershipName
          defaultPhotoUrl
          displayPrice
          exteriorColor
          guaranteedPrice
          historyProvider
          interiorColor
          link
          make
          mileage
          model
          mpgCity
          mpgHighway
          noAccidents
          oneOwner
          personalUseOnly
          rooftopId
          seating {
            rows
            total
          }
          stockNumber
          styleId
          trim
          type
          vin
          year
        }
      }
    }
  }
`;

export const ADVANCED_INVENTORY_SEARCH_QUERY = gql`
  query AdvancedInventorySearch($input: InventorySearchParamInput!) {
    inventoryInfo(input: $input) {
      type
      year
      make
      model
      vin
      stockNumber
      displayPrice
      interiorColor
      exteriorColor
      defaultPhotoUrl
    }
  }
`;
